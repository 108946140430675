import { CircularProgress } from '@mui/material';
import React from 'react';

const Button = ({
  type = 'button',
  className,
  style,
  onClick,
  disabled = false,
  icon,
  children,
  isLoading = false,
}) => {
  return (
    <button
      type={type}
      className={className}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <i className={icon}></i>} {isLoading ? 'Loading...' : children}
      {isLoading && <CircularProgress style={{ marginLeft: 2, color: 'white', width: '20px', height: '20px', position: 'relative', top: '5px' }} />}
    </button>
  );
};

export default Button;
