import React, { useEffect, useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../config";
import {
  CLOSE,
  COMPANYNAME,
  COUNTRY,
  EDITEMAIL,
  EMAIL,
  EMAILTYPE,
  END_DATE_TIME,
  FREQUENCY,
  NAME,
  SAVECHANGES,
  SCHEDULE_DATE_TIME,
  STATUS,
  VERSION,
} from "../../constant/Label";
import { emailmarketing } from "../../constant/EmailMarketing";
import moment from "moment";

function Edit({
  handleChange,
  inputValue,
  TemplateList,
  isDisable,
  changeStatus,
  setInputValue,
  updateId,
  versionList,
  errMsg,
  countryList,
}) {
  const scheduleInputRef = useRef(null);
  const endInputRef = useRef(null);

  const currentDateTime = moment().format("YYYY-MM-DDTHH:mm");

  // set when frequency is "ONE_TIME"
  if (inputValue?.frequency === "ONE_TIME") {
    if (!inputValue?.scheduledDateAndTime || !inputValue?.endDate) {
      setInputValue((prevState) => ({
        ...prevState,
        scheduledDateAndTime: currentDateTime,
        endDate: currentDateTime,
      }));
    }
  }

  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {EDITEMAIL}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h4>{NAME}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={handleChange}
                        value={inputValue?.name}
                        id="nameLabel"
                        placeholder="Name"
                        aria-label="Name"
                      />{" "}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{EMAIL}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={handleChange}
                        value={inputValue?.email}
                        id="emailLabel"
                        placeholder="Email"
                        aria-label="Email"
                      />{" "}
                      {errMsg.email !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.email}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{EMAILTYPE}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.emailType}
                        options={TemplateList}
                        getOptionLabel={(option) => option}
                        value={
                          TemplateList.find(
                            (item) => item === inputValue?.emailType
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          const updatedEmailType = newValue || "";
                          setInputValue({
                            ...inputValue,
                            emailType: newValue ? newValue : "",
                          });
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" autoComplete="off" />
                        )}
                      />
                      {errMsg.emailType !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.emailType}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                  <td>
                      <h4>{VERSION}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.templateVersion}
                        options={versionList
                          ?.filter((item) => inputValue?.emailType ? item.templateName === inputValue?.emailType : true) // Show versions based on emailType
                          .map((item) => item.templateVersion)}
                        value={inputValue?.version}
                        onChange={(event, newValue) => {
                          setInputValue({
                            ...inputValue,
                            templateVersion: newValue || "",
                          });
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{COUNTRY}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.country}
                        options={countryList}
                        getOptionLabel={(option) => option.name}
                        value={
                              countryList.find(
                                          (item) => item.timeZone === inputValue.country
                                      ) || null
                                    }
                        onChange={(event, newValue) => {
                                     setInputValue({
                                         ...inputValue,
                                            country: newValue? newValue.timeZone : "",
                                       });
                                  }}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" autoComplete="off"
                            label={inputValue.country ? '' : 'select country'}
                            error={!inputValue.country}
                            helperText={!inputValue.country ? "Country is required" : ""}
                          />
                        )}
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{VERSION}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.templateVersion}
                        options={versionList
                          ?.filter((item) => inputValue?.emailType ? item.templateName === inputValue?.emailType : true) // Show versions based on emailType
                          .map((item) => item.templateVersion)}
                        value={inputValue?.templateVersion || ""}
                        onChange={(event, newValue) => {
                          setInputValue({
                            ...inputValue,
                            templateVersion: newValue || "",
                          });
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{COUNTRY}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.country}
                        options={countryList}
                        getOptionLabel={(option) => option.name} // Ensure it displays country name
                        value={countryList?.find((item) => item.timeZone === inputValue.country) || null} // Ensure valid value
                        onChange={(event, newValue) => {
                          setInputValue({
                            ...inputValue,
                            country: newValue ? newValue.timeZone : "", // Store timeZone instead of object
                          });
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                            label={inputValue.country ? "" : "Select Country"}
                            error={!inputValue.country}
                            helperText={!inputValue.country ? "Country is required" : ""}
                          />
                        )}
                      />

                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{STATUS}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.status}
                        options={Config.EMAIL_STATUS || []}
                        getOptionLabel={(option) => option.value || ""}
                        value={
                          Config.EMAIL_STATUS.find(
                            (item) => item.value === inputValue?.status
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setInputValue({
                            ...inputValue,
                            status: newValue ? newValue.value : "",
                          });
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{emailmarketing.POSITIONS}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="positions"
                        onChange={(event) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            positions: event.target.value,
                          }));
                        }}
                        value={inputValue?.positions || ""}
                        id="positionsLabel"
                        placeholder="Positions"
                        aria-label="positions"
                      />{" "}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{COMPANYNAME}</h4>{" "}
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        onChange={(event) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            companyName: event.target.value,
                          }));
                        }}
                        value={inputValue?.companyName || ""}
                        id="positionsLabel"
                        placeholder="Company Name"
                        aria-label="companyName"
                      />{" "}
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{FREQUENCY}</h4>{" "}
                      <Autocomplete
                        key={inputValue?.frequency}
                        options={Config.FREQUENCY_STATUS || []}
                        getOptionLabel={(option) => option.value || ""}
                        value={
                          Config.FREQUENCY_STATUS.find(
                            (item) => item.value === inputValue?.frequency
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          setInputValue({
                            ...inputValue,
                            frequency: newValue ? newValue.value : "",
                          });
                        }}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                          />
                        )}
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{SCHEDULE_DATE_TIME}</h4>{" "}
                      <input
                        ref={scheduleInputRef}
                        className="form-control valid cursor-pointer"
                        name="schedule"
                        id="schedule"
                        type="datetime-local"
                        placeholder=""
                        onChange={(event) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            scheduledDateAndTime: event.target.value,
                          }));
                        }}
                        value={inputValue?.scheduledDateAndTime}
                        onClick={() => {
                          if (scheduleInputRef.current) {
                            scheduleInputRef.current.showPicker();
                          }
                        }}
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{END_DATE_TIME}</h4>{" "}
                      <input
                        ref={endInputRef}
                        className="form-control valid cursor-pointer"
                        name="endtime"
                        id="endtime"
                        type="datetime-local"
                        placeholder={""}
                        onChange={(event) => {
                          setInputValue((prevState) => ({
                            ...prevState,
                            endDate: event.target.value,
                          }));
                        }}
                        value={inputValue?.endDate}
                        onClick={() =>
                          endInputRef.current &&
                          endInputRef.current.showPicker()
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {CLOSE}
            </button>
            <button
              disabled={isDisable === true || !inputValue.country}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss={inputValue?.email === "" ? "" : "modal"}
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {SAVECHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
