import React from "react";
import PropTypes from "prop-types";

function View({ selectedTemplate,templateUrl }) {
  return (
    <div
      className="modal fade"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              View Template
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {selectedTemplate ? (
              <div>
                <div className="row mb-4 flex align-items-baseline">
                  <label
                    htmlFor="template"
                    className="col-sm-4 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Template-Type :
                  </label>
                  <div className="col-sm-6">
                    <p>{selectedTemplate.templateName}</p>
                  </div>
                </div>
                <div className="row mb-4 flex align-items-baseline">
                  <label
                    htmlFor="sourceEmail"
                    className="col-sm-4 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Source Email :
                  </label>
                  <div className="col-sm-6">
                    <p>{selectedTemplate.sourceEmail || "-"}</p>
                  </div>
                </div>
                <div className="row mb-4 flex align-items-baseline">
                  <label
                    htmlFor="version"
                    className="col-sm-4 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Version :
                  </label>
                  <div className="col-sm-6">
                    <p>{selectedTemplate.templateVersion}</p>
                  </div>
                </div>
                <div className="row mb-4 align-items-baseline">
                  <label
                    htmlFor="templateSubject"
                    className="col-sm-4 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Subject :
                  </label>
                  <div className="col-sm-6 flex">
                    <p>{selectedTemplate.templateSubject || "subject is not given"}</p>
                  </div>
                </div>
                <div className="row mb-4 align-items-baseline">
                  <label
                    htmlFor="note"
                    className="col-sm-4 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Note :
                  </label>
                  <div className="col-sm-6 flex">
                    <p>{selectedTemplate.Note}</p>
                  </div>
                </div>
                <div className="row mb-4 flex align-items-baseline">
                  <label
                    htmlFor="uploads"
                    className="col-sm-4 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    File Name :
                  </label>
                  <div className="col-sm-6 ">
                  <a href={templateUrl} target="_blank">{selectedTemplate.fileName ? selectedTemplate.fileName : "No file uploaded"}</a>
                  </div>
                </div>
              </div>
            ) : (
              <p>No template selected</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {
  selectedTemplate: PropTypes.object,
  templateUrl: PropTypes.string,
};

export default View;
