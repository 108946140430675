import { createContext, useEffect, useState } from "react";
import { registerProperty, setupIdentity } from "../constant/MixpanelConstant";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import * as AxiosActions from "../../src/Actions/axiosActions";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
  useAuth0();

  const [loading, setLoading] = useState(false);
  const tk = localStorage.getItem("access_token");
  const [adminData, setadminData] = useState([]);

  const checkToken = async () => {
    const token = await getAccessTokenSilently();
    localStorage.setItem("access_token", token);
    localStorage.setItem("userEmail", user.email);
    console.log("Access token 1", token);
  };

  const fetchData = async (email) => {
    try {
      const data = await AxiosActions.Login({ email });
      const name = data.data.users.empid
        ? data.data.users.empid.employeeName
        : user
        ? user.name
        : "Anonymous";
      setadminData(data.data.users);

      setupIdentity(
        data.data.users._id,
        name,
        data.data.users.email,
        data.data.users.isAdmin
      );
      registerProperty(
        name,
        data.data.users.email,
        data.data.users.isAdmin
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const authenticate = async () => {
      setLoading(true);
      if (isAuthenticated) {
        await checkToken();
        await fetchData(user.email);
      } else {
        const storedEmail = localStorage.getItem("userEmail");
        await fetchData(storedEmail);
      }
      setLoading(false);
    };

    authenticate();
  }, [isLoading]);

  return (
    <AuthContext.Provider value={{ adminData, setadminData }}>
      {(loading || isLoading) ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
