import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Routes,
} from "react-router-dom";
import App from "./App";
import { usePermissions } from "./components/Common/Permission";
import Config from "./config";
import { AuthProvider } from "./Login/Protected";
import * as AxiosActions from "./Actions/axiosActions";
import Home from "./Home";
import ApplicantsAdd from "./Pages/Applicants/Add";
import ApplicantsList from "./Pages/Applicants/List";
import ApplicantsImport from "./Pages/Applicants/Import";
import ApplicantsOpen from "./Pages/Applicants/Open";
import Contacts from "./Pages/Contacts/Contacts";
import ProductivityAdd from "./Pages/Productivity/Add";
import ProductivityList from "./Pages/Productivity/List";
import JobsAdd from "./Pages/Jobs/Add";
import JobsList from "./Pages/Jobs/List";
import Quotes from "./Pages/Quotes/Quotes";
import EmployeeAdd from "./Pages/Employee/Add";
import EmployeeList from "./Pages/Employee/List";
import Employee from "./Pages/Summary/Employee";
import Client from "./Pages/Summary/Client";
import TrackingTools from "./Pages/Summary/Trackingtools";
import UserAdd from "./Pages/User/Add";
import UserList from "./Pages/User/List";
import Auth from "./Login/Auth";
import History from "./Pages/History/List";
import ClientAdd from "./Pages/Client/Add";
import TrackingToolsAdd from "./Pages/Trackingtools/Add";
import ClientList from "./Pages/Client/List";
import TrackingToolsList from "./Pages/Trackingtools/List";
import Add from "./Pages/Leaves/Add";
import List from "./Pages/Leaves/List";
import AttendanceAdd from "./Pages/Attendance/Add";
import AttendanceList from "./Pages/Attendance/List";
import TaskAdd from "./Pages/Tasks/Add";
import TaskList from "./Pages/Tasks/List";
import TrackingAdd from "./Pages/Tracking/Add";
import Daily from "./Pages/Tracking/Daily";
import Timesheet from "./Pages/Tracking/Timesheet";
import Attendencesheet from "./Pages/Tracking/Attendencesheet";
import SalaryAdd from "./Pages/Salary/Add";
import SalaryList from "./Pages/Salary/List";
import ExpensesAdd from "./Pages/Expenses/Add";
import ExpensesList from "./Pages/Expenses/List";
import ReminderAdd from "./Pages/Reminder/Add";
import ReminderList from "./Pages/Reminder/List";
import ReminderSheet from "./Pages/Reminder/Sheet";
import Salary from "./Pages/Summary/Salary";
import EmailCSVImport from "./Pages/EmailMarketing/CSVImport";
import TemplateAdd from "./Pages/EmailMarketing/Template/add";
import TemplateList from "./Pages/EmailMarketing/Template/list";
import EmailList from "./Pages/EmailMarketing/List";
import WhatsappCSVImport from "./Pages/WhatsappMarketing/CSVImport";
import WhatsappAdd from "./Pages/WhatsappMarketing/Add";
import WhatsappList from "./Pages/WhatsappMarketing/List";
import EmailMarketingNotesList from "./Pages/EmailMarketing/NotesList";
import PageNotFound from "./components/Common/PageNotFound";
import Callback from "./Callback";
import EventAdd from "./Pages/Event/Add";
import EventList from "./Pages/Event/List";
import HistoryList from "./Pages/Employee/HistoryList";

const actions = Config.ACTION;

export default function MakeMainRoutes() {
  const [userRole, setUserRole] = useState("");
  const { user, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    AxiosActions.Login({
      email: user.email,
    })
      .then((data) => {
        setUserRole(data.data.users.role);
      })
      .catch((err) => {
        console.log("an error occure login", err);
      });
  } else {
    const storedEmail = localStorage.getItem("userEmail");

    AxiosActions.Login({
      email: storedEmail,
    })
      .then((data) => {
        setUserRole(data.data.users.role);
      })
      .catch((err) => {
        console.log("an error occure login", err);
      });
  }

  const permissions = usePermissions()
  const hasPermission = (feature, action) => {
    return permissions[userRole]?.[feature]?.actions[action] || false;
  };

  const isElectronAPIAvailable =
    typeof window !== "undefined" && window.electronAPI !== undefined;

  return isElectronAPIAvailable ? (
    <AuthProvider>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route path="/callback" element={<Callback />} />
          {hasPermission("track", actions.add) && (
            <Route path="/track/add" element={<TrackingAdd />} />
          )}
        </Routes>
      </HashRouter>
    </AuthProvider>
  ) : (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<App />} />
          {hasPermission("dashboard", actions.view) && (
            <Route path="/dashboard" element={<Home />} />
          )}
          {hasPermission("productivity", actions.add) && (
            <Route path="/productivity/add" element={<ProductivityAdd />} />
          )}
          {hasPermission("productivity", actions.view) && (
            <Route path="/productivity/list" element={<ProductivityList />} />
          )}
          {hasPermission("summary", actions.employee) && (
            <Route path="/summary/employee" element={<Employee />} />
          )}
          {hasPermission("summary", actions.client) && (
            <Route path="/summary/client" element={<Client />} />
          )}
          {hasPermission("summary", actions.salary) && (
            <Route path="/summary/salary" element={<Salary />} />
          )}
          {hasPermission("summary", actions.trackingtools) && (
            <Route
              path="/summary/trackingtools"
              element={<TrackingTools />}
            />
          )}
          {hasPermission("leaves", actions.add) && (
            <Route path="/leaves/add" element={<Add />} />
          )}
          {hasPermission("leaves", actions.view) && (
            <Route path="/leaves/list" element={<List />} />
          )}
          {hasPermission("attendance", actions.add) && (
            <Route path="/attendance/add" element={<AttendanceAdd />} />
          )}
          {hasPermission("attendance", actions.view) && (
            <>
              <Route path="/attendance/list" element={<AttendanceList />} />
              <Route path="/AttendenceSheet" element={<Attendencesheet />} />
            </>
          )}

          {hasPermission("tasks", actions.add) && (
            <Route path="/tasks/add" element={<TaskAdd />} />
          )}
          {hasPermission("tasks", actions.view) && (
            <Route path="/tasks/list" element={<TaskList />} />
          )}
          {hasPermission("track", actions.add) && (
            <Route path="/track/add" element={<TrackingAdd />} />
          )}
          {hasPermission("track", actions.view) && (
            <>
              <Route path="/daily" element={<Daily />} />
              <Route path="/timesheet" element={<Timesheet />} />
            </>
          )}
          {hasPermission("reminder", actions.add) && (
            <Route path="/Reminder/add" element={<ReminderAdd />} />
          )}
          {hasPermission("reminder", actions.view) && (
            <>
              <Route path="/Reminder/list" element={<ReminderList />} />
              <Route path="/Reminder/sheet" element={<ReminderSheet />} />
            </>
          )}

            <Route element={<Auth allowedRoles={true} />}>
              {hasPermission("user", actions.add) && (
                <Route path="/user/add" element={<UserAdd />} />
              )}
              {hasPermission("user", actions.view) && (
                <Route path="/user/list" element={<UserList />} />
              )}
              {hasPermission("applicant", actions.add) && (
                <>
                  <Route path="/applicant/add" element={<ApplicantsAdd />} />
                  <Route
                    path="/applicant/import"
                    element={<ApplicantsImport />}
                  />
                </>
              )}
              {hasPermission("applicant", actions.view) && (
                <Route path="/applicant/list" element={<ApplicantsList />} />
              )}
              {hasPermission("contacts", actions.view) && (
                <Route path="/contacts" element={<Contacts />} />
              )}
              {hasPermission("jobs", actions.add) && (
                <Route path="/jobs/add" element={<JobsAdd />} />
              )}
              {hasPermission("jobs", actions.view) && (
                <Route path="/jobs/list" element={<JobsList />} />
              )}
              {hasPermission("quotes", actions.view) && (
                <Route path="/quotes" element={<Quotes />} />
              )}
              {hasPermission("employee", actions.add) && (
                <Route path="/employee/add" element={<EmployeeAdd />} />
              )}
              {hasPermission("employee", actions.view) && (
                <Route path="/employee/list" element={<EmployeeList />} />
              )}
              {hasPermission("employee", actions.view) && (
              <>
                <Route
                  path="/history/list"
                  element={<HistoryList />}
                />

                <Route path="/employee/list" element={<EmployeeList />} />
              </>
             )}
              {hasPermission("history", actions.view) && (
                <Route path="/history/list" element={<History />} />
              )}
              {hasPermission("client", actions.add) && (
                <Route path="/client/add" element={<ClientAdd />} />
              )}
              {hasPermission("client", actions.view) && (
                <Route path="/client/list" element={<ClientList />} />
              )}
              {hasPermission("trackingtools", actions.add) && (
                <Route
                  path="/trackingtools/add"
                  element={<TrackingToolsAdd />}
                />
              )}
              {hasPermission("trackingtools", actions.view) && (
                <Route
                  path="/trackingtools/list"
                  element={<TrackingToolsList />}
                />
              )}
              {hasPermission("salary", actions.add) && (
                <Route path="/salary/add" element={<SalaryAdd />} />
              )}
              {hasPermission("salary", actions.view) && (
                <Route path="/salary/list" element={<SalaryList />} />
              )}
              {hasPermission("expenses", actions.add) && (
                <Route path="/expenses/add" element={<ExpensesAdd />} />
              )}
              {hasPermission("expenses", actions.view) && (
                <Route path="/expenses/list" element={<ExpensesList />} />
              )}
              {hasPermission("event", actions.add) && (
                <Route path="/event/add" element={<EventAdd />} />
              )}
              {hasPermission("event", actions.view) && (
                <Route path="/event/list" element={<EventList/>} />
              )}
              {hasPermission("email", actions.view) && (
                <>
                  <Route
                    path="/notes/list"
                    element={<EmailMarketingNotesList />}
                  />

                <Route path="/email/list" element={<EmailList />} />
              </>
            )}
            {hasPermission("email", actions.csv) && (
              <Route path="/email/csv-import" element={<EmailCSVImport />} />
            )}
            {hasPermission("email", actions.add_template) && (
              <Route path="/email/add-template" element={<TemplateAdd />} />
            )}
            {hasPermission("email", actions.view_template) && (
              <Route path="/email/template-list" element={<TemplateList />} />
            )}
            {hasPermission("whatsapp", actions.add) && (
              <Route path="/whatsapp/add" element={<WhatsappAdd />} />
            )}
            {hasPermission("whatsapp", actions.view) && (
              <Route path="/whatsapp/list" element={<WhatsappList />} />
            )}
            {hasPermission("whatsapp", actions.csv) && (
              <Route
                path="/whatsapp/csv-import"
                element={<WhatsappCSVImport />}
              />
            )}
            <Route
              path="/applicant/resume/:id"
              element={<ApplicantsOpen />}
            />

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}
