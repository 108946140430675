export const FILTER_LABEL = {
  FILTER: "Filter",
  RESET: "Reset",
  YEAR: "Year",
  MONTH: "Month",
  NAME: "Name",
  DATE: "Date",
  STATUS: "Status",
  ALL: "All",
  SELECT_PROJECT: "All Project",
  SELECT_TASK: "All Task",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  CATEGORY: "Category",
  PROJECT: "Project",
  JOB_TITLE: "Job Title",
  USER_TYPE: "User Type",
  LEAVE_TYPE: "Leave Type",
  DELETE: "Delete",
  RESEND: "Resend",
  UPDATE: "Update",
  YES: "Yes",
  NO: "No",
  TASK: "Task",
  HOURS: "Hours",
  TRACKING: "Tracking Tool Name",
  EMAIL: "Email",
  PHONE: "Phone",
  TEMPLATE_TYPE: "Template Type",
  INTEREST_TYPE: "Interest Type",
};
// Header Title
export const EMAILMARKETING = "Email Marketing";
export const EMAILMARKETINGNOTESLIST = "Email Marketing Notes List";
export const WHATSAPPMARKETING = "WhatsApp Marketing";

// AlertBox message
export const DATAADDEDSUCCESSFULLY = "Data has been successfully added";
export const DATAUPDATESUCCESSFULLY = "Data has been successfully updated";
export const RESENDEMAILSUCCESSFULLY = "Resend email successfully";
export const SUCCESS = "Success";
export const CSV_SAVED = "CSV_SAVED";
export const IS_ATLEAST_FIELD = 'Please provide either a phone number or an email address';
export const IS_INVALID_EMAIL = 'Enter valid email address';
export const IS_MOBILE_DIGIT = 'Mobile number must be 10 digit';
export const RESEND_EMAIL = 'Are you want to resend';
export const UPDATE_EMAIL = 'Are you want to Update';
export const RESEND_EMAIL_FAILED = 'Resend email failed';
export const IS_COUNTRY = 'Country is required';
export const IS_VERSION = 'Version is required';
export const IS_SCHEDULE_DATE_TIME = 'date and time is required';

//Formik Validation Messages Constraints
export const IS_CSV = 'CSV file is required';
export const IS_SELECT_TEMPLATE = 'Template is required';
export const IS_FREQUENCY = "Frequency is required";
export const IS_STATUS = "Status is required"
export const IS_SELECT_CSV = 'Select CSV file';
export const IS_SUBJECT = 'Subject is required';
export const IS_FILE = 'File is required';

// Form Title
export const EMAIL_CSV_IMPORT = "Email CSV Import";
export const WHATSAPP_CSV_IMPORT = "Whatsapp CSV Import";
export const DOWNLOAD_SAMPLE_CSV = 'Download Sample CSV';
export const ADDWHATSAPP = "Add Whatsapp";
export const EDITWHATSAPP = "Edit WhatsApp";
export const ADDEMAIL = "Add Email";
export const EDITEMAIL = "Edit Email";
export const NAME = "Name";
export const EMAIL = "Email";
export const COUNTRY = 'Country';
export const VERSION = 'Template Version';
export const FREQUENCY = 'Frequency';
export const SCHEDULE_DATE_TIME = 'Schedule Date Time';
export const END_DATE_TIME = 'End Date Time';
export const EMAILTYPE = "Email Type";
export const SELECTEMAILTYPE = "Select Email Type";
export const STATUS = "Status";
export const SELECTSTATUS = "Select Status";
export const SELECT_COUNTRY = "Select Country";
export const SELECT_VERSION = "Select Version";
export const SELECT_FREQUENCY = "Select Frequency";
export const POSITIONS = "Positions";
export const COMPANYNAME = "Company Name";
export const NOTE = "Note";
export const DATE = "Date";
export const TIME = "Time";
export const NOTES = "Notes";
export const EMPLOYEE_HISTORY = "Employee History";
export const EMPLOYEE_TYPE = "Employee Type";
export const PERIOD = "Period";
export const CURRENT_SALARY = "Current Salary";
export const INCREMENT = "Increment";
export const FROM_DATE = "From Date";
export const TO_DATE = "To Date";
export const HISTORY = "History";
export const RESEND = "Resend";
export const PENDING = "Pending";
export const FAILED = "Failed";
export const SEND = "Send";
export const VIEW_DETAILS = "View Details";
export const ADD = "Add";
export const EDIT = "Edit";
export const DELETE = "Delete";

export const TEMPLATE = "Template";
export const TEMPLATETYPE = "Template Type";
export const SELECTTEMPLATE = "Select Template";
export const IMPORT = "Import";
export const FILES = "Files";
export const TEMPLATEPARAMETERS = "Template Parameters";

export const VIEWEMAILMARKETING = "View Email Marketing";
export const EMAILMARKETINGINFO = "Email Marketing Information";

export const VIEWWHATSAPPMARKETING = "View WhatsApp Marketing";
export const WHATSAPPINFO = "WhatsApp Information";
export const LABLENOTEMPTY = "Label cannot be empty";

// Button Label
export const CLOSE = "Close";
export const SAVECHANGES = "Save changes";
export const UPLOAD = "Upload";
export const SCHEDULE_LATER = 'Schedule later';
export const SEND_NOW = 'Send now';

// Table
export const PHONE = "Phone";
export const CREATEDAT = "Created At";
export const UPDATEDAT = "Updated At";
export const ACTION = "Action";
export const NORECORDFOUND = "No record found";

// user 
export const EDIT_USER = "Edit User";
export const TYPE = "Type";
export const ROLE = "Role";
export const PASSWORD = "Password";
export const EMPLOYEE_NAME = "Employee Name";
export const COMPANY_NAME = "Company Name";

// Upload 
export const SAMPLE_CSV_HEADERS = ['name', 'phoneNumber', 'countryCode']
export const SAMPLE_CSV_DATA = ['John Doe', '1234567890', '+91']
export const SAMPLE_EMAIL_CSV_HEADERS = ['name', 'email']
export const SAMPLE_EMAIL_CSV_DATA = ['John Doe', 'test@gmail.com']
export const UPLOAD_MESSAGE_CSV = `Drag 'n' drop your csv file here, or click to select file`;

//Email Validation Regex Constraints
export const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;