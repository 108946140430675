import React from "react";
import PropTypes from "prop-types";
import Progress from "../ActivityProgress";

const TrackingCard = ({ title, hours, minutes, seconds, value }) => {
  return (
    <button
      className="card card-hover-shadow border border-secondary w-100"
      style={{ marginTop: 10 }}
    >
      <div className="card-body w-100">
        <h3 className="d-flex card-subtitle">{title}</h3>
        <div className="row align-items-center gx-2 mb-1">
          <div
            className="col-6"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              <h2 className="card-title text-inherit" id="timer">
                <span> {hours > 9 ? hours : `0` + hours} </span>:<span> {minutes > 9 ? minutes : `0` + minutes} </span>:<span> {seconds > 9 ? seconds : `0` + seconds} </span>
              </h2>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Progress activityValue={value} />
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};

// **Prop Validations for SonarQube**
TrackingCard.propTypes = {
  title: PropTypes.string,
  hours: PropTypes.number,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  value: PropTypes.number,
};

export default TrackingCard;
