import React from "react";
import Config from "../../config";
import { contact } from "../../constant/Contact";

function Edit({
  changeStatusValue,
  changeInputStatus,
  isDisable,
  changeStatus,
  updateId,
  inputStatus,
  status
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {contact.STATUS}
            </h5>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <label className="row-heading">{contact.STATUS} :</label>
                    </td>
                    <td>
                      {" "}
                      <select
                        className="form-select form-select-sm "
                        style={{ width: "70%" }}
                        onChange={changeStatusValue}
                        value={status}
                      >
                        {Config.EMAIL_STATUS.map((item) => (
                          <option value={item.value}>{item.name}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      <label className="row-heading">{contact.DESCRIPTION}</label>
                    </td>
                    <td>
                      <textarea
                        className="form-control is-valid"
                        placeholder="Textarea field"
                        id="validationValidTextarea1"
                        rows="4"
                        name="description"
                        value={inputStatus}
                        onChange={(e) => changeInputStatus(e)}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {contact.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {contact.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
