export const getInitials = (name) => {
  const nameParts = name?.split(" ");
  if (nameParts) {
    const firstNameInitial = nameParts[0].charAt(0).toUpperCase();
    const lastNameInitial = nameParts[1]
      ? nameParts[1].charAt(0).toUpperCase()
      : "";
    return firstNameInitial + lastNameInitial;
  }
};

export const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#333399",
        borderRadius: "50%",
        right: "10px",
        zIndex: 1000,
      }}
      onClick={onClick}
    />
  );
};

export const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#333399",
        borderRadius: "50%",
        left: "10px",
        zIndex: 1000,
      }}
      onClick={onClick}
    />
  );
};

// Function to convert milliseconds to time
export const CONVERT_MILISECONDS_MINUTES = (milliseconds) => {
  const totalMinutes = Math.floor(milliseconds / (1000 * 60));
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  if (hours > 0 && minutes > 0) {
    return `${hours} Hours ${minutes} Minutes`;
  } else if (hours > 0) {
    return `${hours} Hours`;
  } else if (minutes > 0) {
    return `${minutes} Minutes`;
  } else {
    return "-";
  }
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function STRING_AVATAR(email) {
  const initials = email?.split('@')[0]?.slice(0, 1).toUpperCase(); // Get first 1 characters of the part before '@'
  
  return {
    sx: {
      bgcolor: stringToColor(email),
    },
    children: initials || email?.charAt(0).toUpperCase(), // If initials are not available, fall back to the first character
  };
}

export const addDaily = {
  TITLE: "Add Daily",
  EMPLOYEE: "Employee",
  PROJECT: "Project",
  TASK: "Task",
  FROM_DATE: "From Date",
  TO_DATE: "To Date",
}
