import React, { useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Login() {
  const { user, isAuthenticated, loginWithPopup, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const isElectronAPIAvailable =
  typeof window !== "undefined" && window.electronAPI !== undefined;

  const checkAuth = useCallback(async  ()=>{
    const token = await getAccessTokenSilently();
     console.log('Token',token )
  }, [getAccessTokenSilently]);
  
  useEffect( ()=> {
    console.log("auth0 loaded user", user, "isAuthenticated", isAuthenticated)
    if(isAuthenticated){
      checkAuth()
    }
  },[checkAuth, isAuthenticated, user])

  if (isAuthenticated && isElectronAPIAvailable) {
    window.location.hash = "#/track/add";
    window.location.reload();
  }

  const handleLogin = () => {
    localStorage.removeItem("access_token");
    if(isElectronAPIAvailable) {
      loginWithPopup();
    } else {
      loginWithRedirect();
    }
  };

  return (
    <main id="content" role="main" className="main">
      <div
        className="position-fixed top-0 end-0 start-0 bg-img-start"
        style={{
          height: "32rem",
          backgroundImage: "url(https://admin.3elixir.com/assets/svg/components/card-6.svg)",
        }}
      >
        <div className="shape shape-bottom zi-1">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
          </svg>
        </div>
      </div>
      <div className="container py-5 py-sm-7">
        <a className="d-flex justify-content-center mb-5">
          <img
            className="zi-2"
            src="https://admin.3elixir.com/assets/logo/elitist-logo.jpg"
            alt="elitist-logo"
            style={{ width: "80px", height: "80px" }}
          />
        </a>
        <div className="mx-auto" style={{ maxWidth: "30rem" }}>
          <div className="card card-lg mb-5">
            <div className="card-body">
              <div className="text-center">
                <div className="mb-5">
                  <h1 className="display-4">LOGIN</h1>
                </div>
              </div>
              
              <div className="form-check mb-2"></div>
              <div className="d-grid mb-3">
                <button
                  type="submit"
                  className="btn btn-warning btn-lg"
                  onClick={()=> handleLogin()}
                >
                  Sign in
                </button>
              </div>
              <div className="mb-3">
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
