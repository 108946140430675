import React from "react";
import {
  COMPANYNAME,
  COUNTRY,
  EMAIL,
  EMAILTYPE,
  END_DATE_TIME,
  FREQUENCY,
  NAME,
  POSITIONS,
  SCHEDULE_DATE_TIME,
  STATUS,
  VIEWEMAILMARKETING,
} from "../../constant/Label";
import moment from "moment";


function View({ fullReport }) {
  return (
    <div
      className="modal fade bd-example-modal-xl"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {VIEWEMAILMARKETING}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item, index) => {
              return (
                <div key={index}>
                  {/* <h3 style={{ color: "red" }}>{EMAILMARKETINGINFO}</h3> */}
                  <table className="table table-align-middle">
                    <tbody>
                      <tr>
                        <td>
                          <h4>{NAME}</h4>
                          {item?.name || "-"}
                        </td>
                        <td>
                          <h4>{POSITIONS}</h4>
                          {item?.positions || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{EMAIL}</h4>
                          {item?.email || "-"}
                        </td>
                        <td>
                          <h4>{EMAILTYPE}</h4>
                          {item?.emailType || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{COMPANYNAME}</h4>
                          {item?.companyName || "-"}
                        </td>
                        <td>
                          <h4>{COUNTRY}</h4>
                          {item?.country || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{STATUS}</h4>
                          {item?.status || "-"}
                        </td>
                        <td>
                          <h4>{FREQUENCY}</h4>
                          {item?.frequency || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{SCHEDULE_DATE_TIME}</h4>
                          {moment(item?.scheduledDateAndTime).format(
                            "DD MMM YYYY [at] HH:mm A"
                          ) || "-"}
                        </td>
                        <td>
                          <h4>{END_DATE_TIME}</h4>
                          {moment(item?.endDate).format(
                            "DD MMM YYYY [at] HH:mm A"
                          ) || "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default View;
