import React, { useState, useEffect, useCallback } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import { HeaderTitle, Pagination , Filter} from "../../components/Common";
import Table from "./Table";
import { contact } from "../../constant/Contact";
import { toast } from "react-toastify";
import { DATAUPDATESUCCESSFULLY, SUCCESS } from "../../constant/Label";
import AlertBox from "../../Alert";


function Contacts() {
  const { adminData } = Useuser();
  const [loading, setLoading] = useState(false);
  const [inputStatus, setInputStatus] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [status, setStatus] = useState("Applied");
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [applicantsNotes, setApplicantsNotes] = useState([]);
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("All");
  const [filterjobopening, setFilterJobOpening] = useState("All");
  const [count, setCount] = useState(0);
  const [multipleInputStatus, setMultipleInputStatus] = useState("");
  const [anchorEl6, setAnchorEl6] = useState(false);
  const open6 = Boolean(anchorEl6);
  const [multipleStatus, setMultipleStatus] = useState("Applied");
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  
  const [openAlert, setOpenAlert] = useState(false);
  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [isDisable, setIsDisable] = useState(false);
  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  //   render data
  const renderdata = async () => {
    try {
      //Testing Pending
      setLoading(true);
      await AxiosActions.ContactList({
        pageNo: controller.page,
        size: controller.rowsPerPage,
        token: token,
        status: filterStatus,
        interest: filterjobopening,
      })
        .then((data) => {
          console.log(data);
          setRenderState(data.data.data.rows, data.data.data.noRecords);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
    if (applicant.length === 0) {
      console.log("no applicant");
      setAnchorEl6(true);
    }
  };

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = useCallback(async () => {
    trackEvent(EVENT_NAME.ATTENDANCE_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 1,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  }, [isFiltered, renderdata]);

  // Update status

  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.CONTACTS_CHANGE_STATUS, { data: statusid });
    console.log(statusid);
    const token = localStorage.getItem("access_token");
    AxiosActions.ContactStatus({
      applied_id: statusid,
      id: statusid,
      status: status,
      note: inputStatus,
      token: token,
      adminId: adminData._id,
    })
      .then((data) => {
        setIsDisable(false);
        console.log("Apply Job Table", data);
        setOpenAlert(true)
        renderdata();
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please update again");
      });
    setStatus("Applied");
    setInputStatus("");

    setSelectedInfo({ selectedID: [] });
  };

  const handleClick = (event, id) => {
    setUpdateId(id);
    event.preventDefault();
  };

  const handleClose = () => {
    setStatus("Applied");
    setInputStatus("");
  };


  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.CONTACTS_LIST_RESET, { data: "" });
    setFilterStatus("All");
    setFilterJobOpening("All");
    setController({
      ...controller,
      page: 1,
      rowsPerPage: 20,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
  };

  //Status Change

  const changeStatusValue = (event) => {
    setStatus(event.target.value);
  };

  const changeInputStatus = (event) => {
    setInputStatus(event.target.value);
  };

  //View Status

  const handleViewClick = (event, id) => {
    trackEvent(EVENT_NAME.CONTACTS_STATUS_VIEW, { data: id });
    AxiosActions.ContactNotesList({
      applied_id: id,
    })
      .then((data) => {
        setApplicantsNotes(data.data.data);
      })
      .catch((err) => {
        alert("Please Check Again.");
      });
    event.preventDefault();
  };
  const handleDeleteClick = (event, id) => {
    setDeleteId(id);

    event.preventDefault();
  };

  //Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.CONTACTS_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteContact({
      _id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Record Deleted Successfully");
      })
      .catch((err) => {
        alert("Please Delete Record Again.");
        setIsDisable(false);
        toast.error(err.message);
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.CONTACTS_VIEW, { data: "" });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        setStatus(item.status)
        setInputStatus(item.note);
        setUpdateId(id);
        return true;
      }
    });
    setFullReport(filterRecord);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.CONTACTS_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteContact({
      _id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Records Deleted Successfully");
        console.log("api/v1/contact/deletemany", data);
      })
      .catch((err) => {
        setIsDisable(false);
        alert("Please Delete Record Again");
        toast.error(err.message);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  //Multiple Status

  const handleStatusMultipleClick = (event) => {
    event.preventDefault();
  };

  const changeMultipleStatus = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.CONTACTS_CHANGE_STATUS_ALL, { data: "" });
    const token = localStorage.getItem("access_token");
    AxiosActions.MultipleContactStatus({
      id: selectedinfo.selectedID,
      status: multipleStatus,
      token: token,
      data: selectedinfo.selectedID,
      note: multipleInputStatus,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        console.log("Change Many Status", data);
      })
      .catch((err) => {
        setIsDisable(false);
        alert("Please update again");
      });

    setMultipleStatus("Applied");
    setMultipleInputStatus("");
    setSelectedInfo({ selectedID: [] });
  };

  //Multiple Status Change

  const changeMultipleInputStatus = (event) => {
    setMultipleInputStatus(event.target.value);
  };

  const changeMultipleStatusValue = (event) => {
    setMultipleStatus(event.target.value);
  };
  const handleNoRecordClose = () => {
    setAnchorEl6(false);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <>
    <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title={SUCCESS}
        ok={false}
      />
      {token === null ? (
        (window.location.href = "/")
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
          <HeaderTitle title="Contacts" />
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <Filter
                    status={true}
                    statusValue={filterStatus}
                    statusUpdate={setFilterStatus}
                    statusList={Config?.EMAIL_STATUS}
                    contact={true}
                    contactValue={filterjobopening}
                    contactUpdate={setFilterJobOpening}
                    contactList={Config?.CONTACT_LIST}
                    multipleSelect={selectedinfo?.selectedID?.length}
                    multipleDelete={true}
                    isDisable={isDisable}
                    handleDelete={handleDeleteMultipleClick}
                    deleteAllRecord={deleteAllRecord}
                    multipleStatus={true}
                    handleStatus={handleStatusMultipleClick}
                    changeMultipleStatusValue={changeMultipleStatusValue}
                    changeMultipleInputStatus={changeMultipleInputStatus}
                    multipleStatusList={Config?.EMAIL_STATUS}
                    changeMultipleStatus={changeMultipleStatus}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                </div>
                <div className="table-responsive datatable-custom">
                  <Table 
                    handleCheckBoxChange={handleCheckBoxChange}
                    isAllSelected={isAllSelected}
                    loading={loading}
                    applicants={applicants}
                    selectedinfo={selectedinfo}
                    handleClick={handleClick}
                    changeStatusValue={changeStatusValue}
                    Config={Config}
                    changeInputStatus={changeInputStatus}
                    isDisable={isDisable}
                    changeStatus={changeStatus}
                    updateId={updateId}
                    handleViewClick={handleViewClick}
                    applicantsNotes={applicantsNotes}
                    handleDeleteClick={handleDeleteClick}
                    deleteRecord={deleteRecord}
                    deleteId={deleteId}
                    handleViewReportClick={handleViewReportClick}
                    fullReport={fullReport}
                    inputStatus={inputStatus}
                    status={status}
                  />
                </div>
                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
          </div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(Contacts);
