import React from "react";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
function AlertBox({ open, message, onClose, title, ok, handleConfirmDelete }) {
  return (
    <Dialog
      open={open}
      scroll="body"
      fullWidth
      className="dialog"
      sx={{ height: 500, marginTop: 2, alignItems: "center" }}
      keepMounted
    >
      {/* Row heading with title and close icon */}
      <div
        className="row-heading"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <Typography
          variant="body"
          sx={{
            fontSize: 22,
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <CloseIcon
          className="close-icon"
          fontSize="small"
          onClick={onClose}
          sx={{
            cursor: "pointer",
          }}
        />
      </div>

      {/* Message content */}
      <div>
        <Alert
          sx={{
            height: 100,
            fontSize: 18,
            alignItems: "center",
            backgroundColor: "white",
            color: "black",
          }}
        >
          {message}
        </Alert>
      </div>

      {/* Footer buttons */}
      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px 24px",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        {ok && (
          <Button
            className="filter-button"
            variant="contained"
            sx={{ minWidth: 100, marginRight: 1 }}
            size="small"
            onClick={handleConfirmDelete ?? onClose}
          >
            OK
          </Button>
        )}
        <Button
          className="filter-button"
          variant="contained"
          sx={{ minWidth: 100 }}
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
}

export default AlertBox;
