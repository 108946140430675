import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Callback() {
  const { handleRedirectCallback } = useAuth0();

  useEffect(() => {
    const processCallback = async () => {
      try {
        console.log("Processing custom protocol callback...");
        await handleRedirectCallback();
        window.location.href = "#/track/add"; // Redirect to track/add
      } catch (error) {
        console.error("Error handling redirect callback:", error);
      }
    };

    processCallback();
  }, [handleRedirectCallback]);

  return <div>Loading...</div>;
}
