import React from "react";
import { ROUTES } from "../../constant/Route";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  const navigate = useNavigate();

  const isElectronAPIAvailable =
    typeof window !== "undefined" && window.electronAPI !== undefined;
  // Handle the click event to navigate programmatically
  const handleNavigation = (e, route) => {
    e.preventDefault(); // Prevent default anchor tag behavior
    if(isElectronAPIAvailable){
      window.location.hash = `#${route}`;
    } else {
      navigate(route);
      window.location.href = route;
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img src="https://admin.3elixir.com/assets/img/error/pageNotFound.png" alt="page not found" />
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <button
          type="button"
          className="btn btn-outline-primary"
          onClick={(e) => handleNavigation(e, ROUTES.DASHBOARD)}
        >
          Back To Home
        </button>
      </div>
    </>
  );
}

export default PageNotFound;
