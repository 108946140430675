export const event = {
    EVENT_ADD: "Add Event",
    EVENT_VIEW: "View Event Details",
    EVENT_EDIT: "Edit Event Details",
    EVENT_NAME: "Event Name",
    EVENT_SCHEDULE_DATE: "Schedule Date",
    EVENT_END_DATE: "End Date",
    EVENT_DESCRIPTION: "Description",
    EVENT_TYPE: "Event Type",
    EVENT_START_END_DATE: "Start Date/Time - End Date/Time",
    EVENT_INFO: "Event Info",
    EVENTS: "Events",
}