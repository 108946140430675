import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import AlertBox from "../../Alert";
import * as AxiosActions from "../../Actions/axiosActions";
import Pagination from "../../components/Common/Pagination";
import { Filter } from "../../components/Common";
import { employee } from "../../constant/Employee";
import Delete from "../../components/Common/AlertBox/Delete";
import EmployeeTable from "./Table";
import EditEmployeeModal from "./Edit";
import ViewEmployeeModal from "./View";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";
import History from "./History";

function EmployeeList() {
  const formik = useFormik({
    initialValues: {
      email: [],
      empId: "",
      dob: "",
      mobile1: [],
      mobile2: [],
      emergencyName: [],
      emergencyMobile: [],
      address: [],
      bankName: [],
      bankAccountNumber: [],
      bankIFSCCode: [],
      DocumentsName: [],
      DocumentsPath: [],
      qualification: [],
      job: [],
      joining: "",
      exit: "",
      OfferCTC: [],
      ContractPeriod: [],
      duration: [],
      workingHours: [],
      shift: null,
      empType: "",
      status: "",
      selectedDocumentPath: [],
    },
    onSubmit: (values) => { },
  });

  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const { adminData } = Useuser();
  const [loading, setLoading] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterShift, setFilterShift] = useState("");
  const [filterEmpType, setFilterEmpType] = useState("");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [count, setCount] = useState(0);
  const [inputList, setInputList] = useState("");
  const [documents, setDocuments] = useState([]);
  const [fullHistoryReport, setFullHistoryReport] = useState([]);
  const [inputHistoryValue, setInputHistoryValue] = useState({
    empId: "",
    empType: "",
    fromDate: "",
    toDate: "",
    role: "",
    isDeleted: "",
    currentSalary: "",
    increment: "",
    period: "",
  });

  const [errMsg, setErrMsg] = useState({
    role: "",
    empType: "",
    currentSalary: "",
    increment: "",
  });

  const handleHistoryChange = (event, newValue) => {
    if (event?.target) {
      const { name, value } = event.target;
      setInputHistoryValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (newValue) {
      setInputHistoryValue((prev) => ({
        ...prev,
        empType: newValue.value,
      }));
    }
  };


  const [selectedinfo, setSelectedinfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    setDocuments(Config.DOCUMENT);
    formik.setFieldValue("selectedDocumentPath", formik.values.DocumentsPath);
  }, [formik.values.DocumentsPath]);


  // FOR SELECT ALL

  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = useCallback(async () => {
    try {
      setLoading(true);
      await AxiosActions.EmployeeAll({
        page: controller.page,
        limit: controller.rowsPerPage,
        employeeName: inputList,
        status: filterStatus,
        shift: filterShift,
        empType: filterEmpType,
        year: filterYear,
        month: filterMonth,
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render data", data);
            setRenderState(data.data.data, data.data.totalRecords);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  }, [
    controller.page,
    controller.rowsPerPage,
    filterMonth,
    filterStatus,
    filterYear,
    inputList,
    filterEmpType,
    filterShift
  ]);

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  // Filter record
  const filterRecord = useCallback(async () => {
    trackEvent(EVENT_NAME.EMPLOYEE_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedinfo({ selectedID: [] });
  }, [isFiltered, renderdata]);

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, filterRecord, isFiltered, renderdata]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedinfo({ selectedID: [] });
  };

  // Update status
  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMPLOYEE_EDIT, { data: statusid });
    AxiosActions.EditEmployee({
      id: statusid,
      status: formik.values.status,
      shift: formik.values.shift ? "Full Time" : "Part Time",
      employeeType: formik.values.empType,
      employeeEmail: formik.values.email,
      empid: formik.values.empId,
      primaryContact: formik.values.mobile1,
      secondaryContact: formik.values.mobile2,
      workingHours: formik.values.workingHours,
      dateOfBirth:
        formik.values.dob === "Invalid date" ? null : formik.values.dob,
      address: formik.values.address,
      qualification: formik.values.qualification,
      bankName: formik.values.bankName,
      bankAccountNumber: formik.values.bankAccountNumber,
      bankIFSCCode: formik.values.bankIFSCCode,
      documentsName: formik.values.DocumentsName || [],
      documentsPath: formik.values.DocumentsPath || [],
      employeeRole: formik.values.job,
      dateOfJoin:
        formik.values.joining === "Invalid date" ? null : formik.values.joining,
      dateOfExit:
        formik.values.exit === "Invalid date" ? null : formik.values.exit,
      offerCTC: formik.values.OfferCTC,
      contractPeriod: formik.values.ContractPeriod,
      adminId: adminData._id,
      emergencyName: formik.values.emergencyName,
      emergencyMobile: formik.values.emergencyMobile,
    })
      .then((data) => {
        setIsDisable(false);
        console.log(data);
        setOpenAlert(true);
        renderdata();
      })
      .catch((err) => {
        setIsDisable(false);
        console.log(err);
        alert("Please Delete Record Again.");
      });

    setSelectedinfo({ selectedID: [] });
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.EMPLOYEE_RESET, { data: "" });
    setApplicants([]);
    setInputList("");
    setFilterStatus("all");
    setFilterYear("all");
    setFilterMonth("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    console.log(id);
    setDeleteId(id);

    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMPLOYEE_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteEmployee({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Record deleted successfully")
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        toast.error("some error occurred");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.EMPLOYEE_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        return true;
      }
      return false;
    });

    filterRecord.forEach((item) => {
      const now = moment();
      const DateofJoin = moment(item.DateOfJoin);
      const DateofExit = moment(item.DateOfExit);
      if (item.DateOfExit == null) {
        const duration = moment.duration(now.diff(DateofJoin));
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        console.log({ years }, { months }, { days });
        formik.setFieldValue(
          "duration",
          days +
          " " +
          "Days" +
          " " +
          months +
          " " +
          "Months" +
          " " +
          years +
          " " +
          "Years"
        );
      } else {
        const duration = moment.duration(DateofExit.diff(DateofJoin));
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        console.log({ years }, { months }, { days });
        formik.setFieldValue(
          "duration",
          days +
          " " +
          "Days" +
          " " +
          months +
          " " +
          "Months" +
          " " +
          years +
          " " +
          "Years"
        );
      }
      formik.setFieldValue("status", item.status);
      formik.setFieldValue("shift", item.isFullDay);
      formik.setFieldValue("empType", item.empType || null);
      formik.setFieldValue("email", item.employeeEmail);
      formik.setFieldValue("empId", item.empid);
      formik.setFieldValue(
        "dob",
        moment(item.DateOfBirth).format("YYYY-MM-DD")
      );
      formik.setFieldValue("mobile1", item.PrimaryContact);
      formik.setFieldValue("mobile2", item.SecondaryContact);
      formik.setFieldValue("workingHours", item.workingHours);
      formik.setFieldValue("address", item.address);
      formik.setFieldValue("bankName", item.bankName);
      formik.setFieldValue("bankAccountNumber", item.bankAccountNumber);
      formik.setFieldValue("bankIFSCCode", item.bankIFSCCode);
      formik.setFieldValue("DocumentsName", item.DocumentsName || []);
      formik.setFieldValue("DocumentsPath", item.DocumentsPath || []);
      formik.setFieldValue("job", item.employeeRole);
      formik.setFieldValue(
        "joining",
        moment(item.DateOfJoin).format("YYYY-MM-DD")
      );
      formik.setFieldValue(
        "exit",
        moment(item.DateOfExit).format("YYYY-MM-DD")
      );
      formik.setFieldValue("OfferCTC", item.OfferCTC);
      formik.setFieldValue("qualification", item.qualification);
      formik.setFieldValue("ContractPeriod", item.ContractPeriod);
      formik.setFieldValue("emergencyMobile", item.emergencyMobile);
      formik.setFieldValue("emergencyName", item.emergencyName);
    });
    setFullReport(filterRecord);
    setUpdateId(id);
  };

  const handleHistoryViewClick = (event, id) => {
    trackEvent(EVENT_NAME.EMPLOYEE_HISTORY_VIEW, { data: id });
    const filterRecord = applicants?.filter((item) => {
      if (id === item._id) {
        const data = {
          empType: item?.emailType || "",
          fromDate: item?.fromDate || "",
          toDate: item?.toDate || "",
          role: item?.role || "",
          isDeleted: item?.isDeleted || "",
          currentSalary: item?.currentSalary || "",
          increment: item?.increment || "",
          period: item?.period || "",
        };

        setInputHistoryValue(data);
        return true;
      }
    })
    setFullHistoryReport(filterRecord);
    setUpdateId(id);
  };

  // // file input
  const handleFileInput = (e) => {
    const allowedExtensions = ["jpg", "png", "jpeg", "pdf"];
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => {
      const extension = file.name.split(".").pop().toLowerCase();
      return allowedExtensions.includes(extension);
    });

    if (validFiles.length !== files.length) {
      toast.error("Invalid File. Only jpg, png, jpeg, and pdf are allowed.");
      return;
    }

    validFiles.forEach((file) => {
      const timestamp = new Date().getTime();
      const fileName = `${timestamp}_${file.name}`;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", fileName);

      axios
        .post(`${Config.APIURL}user/file/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.code === 200) {
            formik.setFieldValue("DocumentsPath", [
              ...formik.values.DocumentsPath,
              `${response.data.data}${fileName}`,
            ]);
          }
        })
        .catch((error) => {
          console.log("file upload getting response error", error);
        });
    });
    formik.setFieldValue("selectedDocumentPath", [
      ...formik.values.selectedDocumentPath,
      ...files,
    ]);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.forEach((item) => {
          return list.push(item._id);
        });
        setSelectedinfo({
          selectedID: list,
        });
      } else {
        setSelectedinfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
    } else if (checked) {
      setSelectedinfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedinfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMPLOYEE_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteEmployee({
      id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Record Deleted Successfully");
      })
      .catch((err) => {
        setIsDisable(false);
        toast.error("some error occurred");
      });

    setSelectedinfo({ selectedID: [] });
    event.preventDefault();
  };

  /**
   * Calculates the difference between today and target date.
   * It is used to calculate how many days a user has join
   *
   * @param {joinDate,leaveDate}
   * @return { string } calculate  days
   */
  function calculateDay(joinDate, leaveDate) {
    if (joinDate) {
      const now = moment();
      const dateOfJoin = moment(joinDate);

      if (leaveDate == null) {
        const duration = moment.duration(now.diff(dateOfJoin));
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const output =
          (years > 0 ? `${years}y ` : "") +
          (months > 0 ? `${months}m ` : "") +
          (days > 0 ? `${days}d` : "");
        return output;
      } else {
        const dateOfExit = moment(leaveDate);
        const duration = moment.duration(dateOfExit.diff(dateOfJoin));
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const output =
          (years > 0 ? `${years}y ` : "") +
          (months > 0 ? `${months}m ` : "") +
          (days > 0 ? `${days}d` : "");
        return output;
      }
    } else {
      return "-";
    }
  }

  const addHistory = (event) => {
    trackEvent(EVENT_NAME.EMPLOYEE_HISTORY_ADD, {});

    if (
      !inputHistoryValue?.role ||
      !inputHistoryValue?.increment ||
      !inputHistoryValue?.currentSalary ||
      !inputHistoryValue?.empType
    ) {
      setErrMsg({
        role: inputHistoryValue?.role ? "" : "Enter Employee Role",
        empType: inputHistoryValue?.empType ? "" : "Select Employee Type",
        increment: inputHistoryValue?.increment ? "" : "Enter Increment",
        currentSalary: inputHistoryValue?.currentSalary ? "" : "Enter Current Salary",
      });
      return; // Stop execution if validation fails
    }

    setErrMsg({ role: "", empType: "", increment: "", currentSalary: "" });

    setIsDisable(true);
    AxiosActions.EmployeeHistoryAdd({
      empid: fullHistoryReport[0]._id,
      role: inputHistoryValue?.role,
      empType: inputHistoryValue?.empType,
      fromDate: inputHistoryValue?.fromDate,
      toDate: inputHistoryValue?.toDate,
      // isDeleted: false, 
      currentSalary: Number(inputHistoryValue?.currentSalary),
      increment: Number(inputHistoryValue?.increment),
      period: inputHistoryValue?.period,
    })
      .then((data) => {
        if (data.status === 200) {
          setIsDisable(false);
          renderdata()
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
      });

    event.preventDefault();
  };


  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div
                  className="col align-items-center"
                  style={{ alignItems: "center" }}
                >
                  <h1
                    className="page-header-title align-items-center"
                    style={{ alignSelf: "center" }}
                  >
                    {employee.EMPLOYEES}
                  </h1>
                </div>
              </div>
            </div>
            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  status={true}
                  shift={true}
                  empType={true}
                  statusValue={filterStatus}
                  statusUpdate={setFilterStatus}
                  statusList={Config?.EMPLOYEE_STATUS}
                  shiftValue={filterShift}
                  shiftUpdate={setFilterShift}
                  shiftList={Config?.EMPLOYEE_SHIFT_TIME}
                  empTypeValue={filterEmpType}
                  empTypeUpdate={setFilterEmpType}
                  empTypeList={Config?.EMPLOYEE_TYPE_LIST}
                  searchName={true}
                  searchNameValue={inputList}
                  searchNameUpdate={setInputList}
                  multipleSelect={selectedinfo?.selectedID?.length}
                  isDisable={isDisable}
                  multipleDelete={true}
                  handleDelete={handleDeleteMultipleClick}
                  deleteAllRecord={deleteAllRecord}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />

                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-md">
                    <div className="d-flex justify-content-between align-items-center"></div>
                  </div>
                </div>
              </div>
              <EmployeeTable
                applicants={applicants}
                loading={loading}
                setLoading={setLoading}
                selectedinfo={selectedinfo}
                handleCheckBoxChange={handleCheckBoxChange}
                isAllSelected={isAllSelected}
                handleViewReportClick={handleViewReportClick}
                handleHistoryViewClick={handleHistoryViewClick}
                handleDeleteClick={handleDeleteClick}
                calculateDay={calculateDay}
                adminData={adminData}
              />
              <EditEmployeeModal
                fullReport={fullReport}
                formik={formik}
                documents={documents}
                handleFileInput={handleFileInput}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
              />
              <ViewEmployeeModal fullReport={fullReport} formik={formik} />
              <History addHistory={addHistory} fullReport={fullHistoryReport}
                handleHistoryChange={handleHistoryChange}
                inputHistoryValue={inputHistoryValue}
                setInputHistoryValue={setInputHistoryValue}
                isDisable={isDisable}
                errMsg={errMsg}
                applicants={applicants}
                selectedinfo={selectedinfo}
              />
              <Delete
                deleteId={deleteId}
                isDisable={isDisable}
                deleteRecord={deleteRecord}
              />
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}


export default HeaderNavbarHOC(EmployeeList);