import React from 'react';
import { employee } from '../../constant/Employee';
import Config from '../../config';
import PropTypes from 'prop-types';
import { CLOSE, CURRENT_SALARY, EMPLOYEE_TYPE, FROM_DATE, INCREMENT, PERIOD, ROLE, SAVECHANGES, TO_DATE } from '../../constant/Label';

const EditEmpHistoryModal = ({
    fullReport = [],
    formik,
    isDisable,
    changeStatus,
    updateId,
}) => {
    return (
        <div
            className="modal fade"
            id="historyEdit"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content w-auto">
                    <div className="modal-header">
                        <h2 className="modal-title" id="exampleModalLongTitle">
                            {employee.EDIT_EMPLOYEE_HISTORY}
                        </h2>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="modal-content">
                            <table className="table-borderless table-sm">
                                <tbody>
                                    {fullReport?.map((item, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>
                                                        <h4>{PERIOD}</h4>{" "}
                                                        <input
                                                            type="number"
                                                            className="form-control is-valid"
                                                            placeholder="Enter period"
                                                            id="validationValidInput1"
                                                            name="period"
                                                            onChange={formik?.handleChange}
                                                            value={formik?.values?.period || ""}
                                                        />
                                                    </td>
                                                    <td>
                                                        <h4>{CURRENT_SALARY}</h4>
                                                        <input
                                                            type="number"
                                                            className="form-control is-valid"
                                                            placeholder="Enter current salary"
                                                            id="validationValidInput1"
                                                            rows="4"
                                                            name="currentSalary"
                                                            onChange={formik?.handleChange}
                                                            value={formik?.values?.currentSalary || ""}
                                                        />
                                                    </td>
                                                </tr>
                                                <br></br>
                                                <tr>
                                                    <td>
                                                        <h4>{INCREMENT}</h4>{" "}
                                                        <input
                                                            type="number"
                                                            className="form-control is-valid"
                                                            placeholder="Enter increment amount"
                                                            name="increment"
                                                            onChange={formik?.handleChange}
                                                            value={formik?.values?.increment || ""}
                                                        />
                                                    </td>
                                                    <td>
                                                        <h4>{FROM_DATE}</h4>{" "}
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="fromDate"
                                                            value={formik?.values?.fromDate || ""}
                                                            onChange={formik?.handleChange}
                                                        />
                                                    </td>
                                                </tr>
                                                <br></br>
                                                <tr>
                                                    <td>
                                                        <h4>{TO_DATE}</h4>{" "}
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="toDate"
                                                            value={formik?.values?.toDate || ""}
                                                            onChange={formik?.handleChange}
                                                        />
                                                    </td>
                                                    <td>
                                                        <h4>{ROLE}</h4>{" "}
                                                        <input
                                                            type="text"
                                                            className="form-control is-valid"
                                                            placeholder="Enter role of employee"
                                                            name="role"
                                                            onChange={formik?.handleChange}
                                                            value={formik?.values?.role || ""}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h4>{EMPLOYEE_TYPE}</h4>{" "}
                                                        <select
                                                            className="form-control"
                                                            name="empType"
                                                            value={formik.values?.empType || ""}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option value="">Select Employee Type</option>
                                                            {Config.EMPLOYEE_TIME.map((option) => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>

                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-white"
                            data-bs-dismiss="modal"
                        >
                            {CLOSE}
                        </button>
                        <button
                            disabled={isDisable === true}
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                changeStatus(updateId);
                            }}
                        >
                            {SAVECHANGES}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEmpHistoryModal;

EditEmpHistoryModal.propTypes = {
    updateId: PropTypes.number,
    changeStatus: PropTypes.func,
    isDisable: PropTypes.bool,
    formik: PropTypes.object,
    documents: PropTypes.array,
    fullReport: PropTypes.array,
    handleHistoryUpdate: PropTypes.func,
};