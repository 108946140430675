import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../Login/Protected";
import { useAuth0 } from "@auth0/auth0-react";
import Avatar from '@mui/material/Avatar';
import { STRING_AVATAR } from "../constant/Daily";

function Header({ isSidebarVisible, setIsSidebarVisible, isElectronAPIAvailable }) {
  const adminData = useContext(AuthContext);
  const { logout } = useAuth0();
  // !Deprecated since 17th
  // Checking authentication using jwt access token
  const { user, isAuthenticated } = useAuth0();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("userEmail");
    logout({ logoutParams: { returnTo: isElectronAPIAvailable ? 'file://callback' : window.location.origin } })  
  };

  return (
    <header
      id="header"
      className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white"
    >
      <div className="navbar-nav-wrap">
        <div className="navbar-nav-wrap-content-start">
          <div className={`p-0 pe-xl-0 pe-5 d-block d-xl-none ${ isElectronAPIAvailable ? 'd-none': ''}`}>
            <IconButton
              className="d-flex"
              edge="start"
              color="black"
              aria-label="menu"
              onClick={()=> setIsSidebarVisible(!isSidebarVisible)}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <h3 className="mb-0" style={{marginLeft:-10}}>
          Welcome {adminData.adminData.empid &&
              adminData.adminData.empid.employeeName} !
          </h3>
        </div>
        <div className="navbar-nav-wrap-content-end">
          {/* Account */}
          <div className="dropdown">
            <button
              className="navbar-dropdown-account-wrapper border-0"
              id="accountNavbarDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              data-bs-dropdown-animation=""
            >
              <div className="avatar avatar-sm avatar-circle">
                {isAuthenticated && (
                  <div>
                    <Avatar {...STRING_AVATAR(adminData?.adminData?.empid?.employeeEmail || user.name)} />
                    
                  </div>
                )}

                <span className="avatar-status avatar-sm-status avatar-status-success" />
              </div>
            </button>
            <div
              className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account"
              aria-labelledby="accountNavbarDropdown"
              style={{ width: "16rem" }}
            >
              <div className="dropdown-item-text">
                <div className="d-flex align-items-center">
                  <div className="avatar avatar-sm avatar-circle">
                    {isAuthenticated && (
                      <div>
                        <Avatar {...STRING_AVATAR(adminData?.adminData?.empid?.employeeEmail || user.name)} />
                      </div>
                    )}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-0">
                      {adminData.adminData.empid &&
                        adminData.adminData.empid.employeeName}
                    </h5>
                    <p className="card-text text-body">
                      {adminData.adminData.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="dropdown-divider" />

              <button
                className="btn btn-danger btn-sm border-0 text-bg-danger"
                href="#"
                onClick={() =>
                  handleLogout({
                    logoutParams: { returnTo: window.location.origin },
                  })
                }
                style={{ color: "white" }}
              >
                Logout
              </button>
            </div>
          </div>
          {/* End Account */}
        </div>
      </div>
    </header>
  );
}

export default Header;
