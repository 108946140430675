import React from "react";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import Config from "../../config";
import { useHasPermission } from "../../components/Common/Permission";
import { leaves } from "../../constant/Leaves";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  adminData,
  handleViewReportClick,
  handleDeleteClick,
}) {
  const canDelete = useHasPermission(
    adminData.adminData.role,
    "leaves",
    Config.ACTION.delete
  );
  const canEdit = useHasPermission(
    adminData.adminData.role,
    "leaves",
    Config.ACTION.edit
  );
  const canView = useHasPermission(
    adminData.adminData.role,
    "leaves",
    Config.ACTION.detail
  );
  const hasPermission = canDelete || canEdit || canView;

  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>

          <th>{leaves.NAME}</th>
          <th>{leaves.FROM_DATE}</th>
          <th>{leaves.TO_DATE}</th>
          <th>{leaves.TYPE}</th>
          <th>{leaves.REASON}</th>
          <th>{leaves.STATUS}</th>
          {hasPermission && <th>{leaves.ACTION}</th>}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="spinner-border text-dark m-5" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {applicants.length === 0 && (
              <tr>
                <td colSpan={8} style={{ textAlign: "center" }}>
                  <h3>{leaves.NO_RECORD_FOUND}</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={index}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>

                  <td className="table-column-ps-1">
                    <h5 className="text-inherit mb-0">
                      {app.empid.employeeName}
                    </h5>
                  </td>

                  <td>{moment(app.from_date).format("DD-MM-YYYY")}</td>
                  <td>{moment(app.to_date).format("DD-MM-YYYY")}</td>
                  <td>{app.leave_type}</td>
                  <td>
                    <div className="truncate" title={app.leave_reason}>
                      {app.leave_reason}
                    </div>
                  </td>
                  <td>{app.status}</td>
                  {adminData.adminData.isAdmin && hasPermission ? (
                    <td>
                      {" "}
                      {canEdit && (
                        <button
                          title="Edit leave"
                          type="button"
                          className="icon icon-danger icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalLong"
                          onClick={(event) =>
                            handleViewReportClick(event, app._id)
                          }
                        >
                          <i className="bi-pen"></i>
                        </button>
                      )}
                      &nbsp;
                      {canDelete && (
                        <button
                          title="Delete leave"
                          type="button"
                          className="icon icon-info icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(event) => handleDeleteClick(event, app._id)}
                        >
                          <i className="bi-trash"></i>
                        </button>
                      )}
                      &nbsp;
                      {canView && (
                        <button
                          title="View leave detail"
                          type="button"
                          className="icon icon-dark icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#viewpage"
                          onClick={(event) =>
                            handleViewReportClick(event, app._id)
                          }
                        >
                          <i className="bi-eye"></i>
                        </button>
                      )}
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;
