import React, { useState, useEffect } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Useuser from "../../Contexct";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { getClientStatus } from "../../services/common";
import * as AxiosActions from "../../Actions/axiosActions";
import { AlertDelete, HeaderTitle, Pagination,Filter } from "../../components/Common";
import View from "./View";
import Edit from "./Edit";
import Table from "./Table";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function TrackingToolsList() {
  const { adminData } = Useuser();
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [inputList, setInputList] = useState("");
  const [filterArr, setFilterArr] = useState([]);
  const [status, setStatus] = useState();
  const [trackingtoolName, setTrackingtoolName] = useState("");
  const [isDisable, setIsDisable] = useState(false);

  const [selectedinfo, setSelectedinfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  // FOR SELECT ALL

  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = async () => {
    try {
      setLoading(true);
      AxiosActions.TrackingToolList({
        page: controller.page,
        limit: controller.rowsPerPage,
        trackingToolName: inputList,
        status:
          filterStatus === "all"
            ? "all"
            : "" || filterStatus === "true"
            ? true
            : "" || filterStatus === "false"
            ? false
            : "",
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render data", data.data);
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  useEffect(() => {
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedinfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderdata();
    setSelectedinfo({ selectedID: [] });
  };

  // Update status
  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_EDIT, { data: statusid });
    AxiosActions.EditTrackingTool({
      id: statusid,
      status: status,
      trackingToolName: trackingtoolName,
    })
      .then((data) => {
        console.log(data);
        setOpenAlert(true);
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Please Delete Record Again.");
        setIsDisable(false);
      });

    setSelectedinfo({ selectedID: [] });
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_RESET, { data: "" });
    setApplicants(filterArr);
    setInputList("");
    setFilterStatus("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedinfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    console.log(id);
    setDeleteId(id);

    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteTrackingTool({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        setTrackingtoolName(item.trackingToolName);

        setStatus(item.status);
        return true;
      }
      return false;
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.forEach((item) => {
          list.push(item._id);
        });
        setSelectedinfo({
          selectedID: list,
        });
      } else {
        setSelectedinfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
    } else if (checked) {
      setSelectedinfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedinfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteTrackingTool({
      id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedinfo({ selectedID: [] });
    event.preventDefault();
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Tracking tools" />
        
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <Filter
                    searchName={true}
                    searchNameValue={inputList}
                    searchNameUpdate={setInputList}
                    status={true}
                    statusValue={filterStatus}
                    statusUpdate={setFilterStatus}
                    statusList={Config?.TRACKING_TOOLS_STATUS}
                    multipleSelect={selectedinfo?.selectedID?.length}
                    multipleDelete={true}
                    isDisable={isDisable}
                    handleDelete={handleDeleteMultipleClick}
                    deleteAllRecord={deleteAllRecord}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                </div>
                <div className="table-responsive datatable-custom">
                  <Table 
                    handleCheckBoxChange={handleCheckBoxChange}
                    isAllSelected={isAllSelected}
                    loading={loading}
                    applicants={applicants}
                    selectedinfo={selectedinfo}
                    getClientStatus={getClientStatus}
                    handleViewReportClick={handleViewReportClick}
                    handleDeleteClick={handleDeleteClick}
                    adminData={adminData}
                  />
                </div>
                <Edit 
                  fullReport={fullReport}
                  settrackingtoolName={setTrackingtoolName}
                  trackingtoolName={trackingtoolName}
                  setstatus={setStatus}
                  status={status}
                  isDisable={isDisable}
                  changeStatus={changeStatus}
                  updateId={updateId}
                />
                <AlertDelete
                  isDisable={isDisable}
                  deleteRecord={deleteRecord}
                  deleteId={deleteId}
                />
                <View fullReport={fullReport} getClientStatus={getClientStatus} />
                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(TrackingToolsList);
