import React, { useState, useEffect, useMemo } from "react";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import {
  BarChart,
  Loader,
  Filter,
  Pagination,
  HeaderTitle,
  AlertDelete,
} from "../../components/Common";
import Useuser from "../../Contexct";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { getClientStatus } from "../../services/common";
import * as AxiosActions from "../../Actions/axiosActions";
import moment from "moment";
import { EXPENSES, MIS_EXPENSES } from "../../constant/Chart";
import Table from "./Table";
import Edit from "./Edit";
import View from "./View";
import { DATAUPDATESUCCESSFULLY } from "../../constant/Label";

function ExpensesList() {
  const { adminData } = Useuser();
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterDate, setFilterDate] = useState("all");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filterCategory, setFilterCategory] = useState("all");
  const [filterStatus, setFilterStatus] = useState("all");
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [chartData, setChartData] = useState({});
  const [miscChartData, setMiscChartData] = useState({});

  const [filterYearChart, setFilterYearChart] = useState(moment().year());
  const [monthChart, setMonthChart] = useState("all");

  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });
  const [Category, setCategory] = useState("");
  const [date, setdate] = useState();
  const [year, setyear] = useState("");
  const [month, setmonth] = useState("");
  const [Amount, setAmount] = useState("");
  const [Item, setItem] = useState("");
  const [Description, setDescription] = useState("");

  // FOR SELECT ALL
  const isAllSelected =
    applicants.length > 0 &&
    selectedinfo.selectedID.length === applicants.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA
  const renderData = async () => {
    try {
      setLoading(true);
      AxiosActions.ExpensesList({
        page: controller.page,
        limit: controller.rowsPerPage,
        empid: "",
        category: filterCategory,
        type: filterDate,
        month: filterMonth,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        status:
          filterStatus === "all"
            ? "all"
            : "" || filterStatus === "true"
            ? true
            : "" || filterStatus === "false"
            ? false
            : "",
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render data", data.data);
            setRenderState(data.data.results, data.data.count);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };
  //Render Chart Data
  const renderChartData = async (filterYearChart, monthChart) => {
    try {
      setLoading(true);
      AxiosActions.ExpensesListChart({
        page: 0,
        limit: 10,
        empid: "",
        category: "all",
        type: "all",
        month: monthChart,
        year: parseInt(filterYearChart),
        status: "all",
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render chart data", data.data);
            if (data.data?.count > 0) {
              setChartData(data.data?.totalExpense);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Error ", error);
        });
    } catch (err) {
      console.log("Catch err in render chart data", err);
    }
  };
  //Render Chart Data
  const renderChartMiscData = async (filterYearChart, monthChart) => {
    try {
      setLoading(true);
      AxiosActions.ExpensesMiscListChart({
        page: 0,
        limit: 10,
        empid: "",
        category: "all",
        type: "all",
        month: monthChart,
        year: parseInt(filterYearChart),
        status: "all",
      })
        .then((data) => {
          if (data.status === 200) {
            console.log("render misc chart data", data.data);
            if (data.data?.count > 0) {
              setMiscChartData(data.data?.totalExpense);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch misc Error ", error);
        });
    } catch (err) {
      console.log("Catch err in render misc chart data", err);
    }
  };
  const setRenderState = (applicant, noOfRecords) => {
    setApplicants(applicant);
    setCount(noOfRecords);
  };

  useEffect(() => {
    renderChartData(filterYearChart, monthChart);
    renderChartMiscData(filterYearChart, monthChart);
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderData();
    }
  }, [controller]);

  // New Pagination
  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_LIST_FILTER, { data: "" });
    if (!isFiltered) {
      setIsFiltered(true);
      setController((prevController) => {
        return {
          ...prevController,
          page: 0,
          preventRefresh: true,
        };
      });
    }
    renderData();
    setSelectedInfo({ selectedID: [] });
  };

  // Update status
  const changeStatus = (statusid, title) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_EDIT, { data: statusid });
    AxiosActions.EditExpenses({
      id: statusid,
      month: month,
      status: status,
      item: Item,
      description: Description,
      category: Category,
      netAmount: Amount,
      year: year,
      date: date,
    })
      .then((data) => {
        console.log(data);
        setOpenAlert(true);
        renderData();
        renderChartData(filterYearChart, monthChart);
        renderChartMiscData(filterYearChart, monthChart);
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Please Delete Record Again.");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_RESET, { data: "" });
    setApplicants([]);
    setFilterStatus("all");
    setFilterYear("all");
    setFilterMonth("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
  };

  const handleDeleteClick = (event, id) => {
    console.log(id);
    setDeleteId(id);

    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteExpenses({
      id: id,
      adminId: adminData._id,
    })
      .then((data) => {
        renderData();
        renderChartData(filterYearChart, monthChart);
        renderChartMiscData(filterYearChart, monthChart);
        setIsDisable(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });

    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.TRACKING_TOOLS_VIEW, { data: id });
    const filterRecord = applicants.filter((item) => {
      if (id === item._id) {
        setdate(moment(item.date).format("YYYY-MM-DD"));
        setmonth(item.month || '');
        setStatus(item.status);
        setCategory(item.category || '');
        setAmount(item.netAmount || '');
        setItem(item.item || '');
        setyear(item.year || '');
        setDescription(item.description || '');
        return true;
      }
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TRACKING_TOOLS_DELETE_ALL, { data: "" });
    console.log("Delete All Records");
    AxiosActions.MultipleDeleteExpenses({
      id: selectedinfo.selectedID,
      adminId: adminData._id,
    })
      .then((data) => {
        renderData();
        renderChartData();
        renderChartData(filterYearChart, monthChart);
        renderChartMiscData(filterYearChart, monthChart);
        setIsDisable(false);
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  const seriesChartData = useMemo(() => {
    if (Object.keys(chartData).length === 0) {
      return [];
    } else {
      return Config.SALARY_MONTH.map((month, index) => ({
        name: month,
        data: Config.SALARY_MONTH.map((month, i) => {
          if (i === index) {
            return chartData[month];
          } else {
            return null;
          }
        }),
      }));
    }
  }, [chartData]);

  const seriesMiscChartData = useMemo(() => {
    if (Object.keys(miscChartData).length === 0) {
      return [];
    } else {
      return Config.SALARY_MONTH.map((month, index) => ({
        name: month,
        data: Config.SALARY_MONTH.map((month, i) => {
          if (i === index) {
            return miscChartData[month];
          } else {
            return null;
          }
        }),
      }));
    }
  }, [miscChartData]);

  const filterChartRecord = async () => {
    renderChartData(filterYearChart, monthChart);
    renderChartMiscData(filterYearChart, monthChart);
  };

  const resetChartRecord = async () => {
    setFilterYearChart(moment().year());
    setMonthChart("all");
    renderChartData(moment().year(), "all");
    renderChartMiscData(moment().year(), "all");
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <HeaderTitle title="Expenses" />
            <Filter
              year={true}
              yearValue={filterYearChart}
              yearUpdate={setFilterYearChart}
              month={true}
              monthValue={monthChart}
              monthUpdate={setMonthChart}
              filterRecord={filterChartRecord}
              resetRecord={resetChartRecord}
            />
            <div className="card card-bordered h-100">
              <div className="card-body">
                {seriesChartData.length > 0 ? (
                  <BarChart
                    categories={Config.SALARY_MONTH}
                    series={seriesChartData}
                    title={EXPENSES.CHART_TITLE}
                    yAxisTitle={EXPENSES.Y_TITLE}
                    xAxisTitle={EXPENSES.X_TITLE}
                    barWidth={60}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <br></br>
            <div className="card card-bordered h-100">
              <div className="card-body">
                {seriesMiscChartData.length > 0 ? (
                  <BarChart
                    categories={Config.SALARY_MONTH}
                    series={seriesMiscChartData}
                    title={MIS_EXPENSES.CHART_TITLE}
                    yAxisTitle={MIS_EXPENSES.Y_TITLE}
                    xAxisTitle={MIS_EXPENSES.X_TITLE}
                    barWidth={60}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <br></br>
            <>
              <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                  <Filter
                    year={true}
                    yearValue={filterYear}
                    yearUpdate={setFilterYear}
                    month={true}
                    monthValue={filterMonth}
                    monthUpdate={setFilterMonth}
                    status={true}
                    statusValue={filterStatus}
                    statusUpdate={setFilterStatus}
                    statusList={Config?.EXPENSES_STATUS}
                    date={true}
                    dateValue={filterDate}
                    dateUpdate={setFilterDate}
                    startDateValue={startDate}
                    startDateUpdate={setStartDate}
                    endDateValue={endDate}
                    endDateUpdate={setEndDate}
                    category={true}
                    categoryValue={filterCategory}
                    categoryUpdate={setFilterCategory}
                    multipleSelect={selectedinfo?.selectedID?.length}
                    multipleDelete={true}
                    isDisable={isDisable}
                    handleDelete={handleDeleteMultipleClick}
                    deleteAllRecord={deleteAllRecord}
                    filterRecord={filterRecord}
                    resetRecord={reset}
                  />
                </div>
                <div className="table-responsive datatable-custom">
                  <Table
                    handleCheckBoxChange={handleCheckBoxChange}
                    isAllSelected={isAllSelected}
                    loading={loading}
                    Loader={Loader}
                    applicants={applicants}
                    selectedinfo={selectedinfo}
                    getClientStatus={getClientStatus}
                    handleViewReportClick={handleViewReportClick}
                    handleDeleteClick={handleDeleteClick}
                    adminData={adminData}
                  />
                </div>
                <Edit
                  fullReport={fullReport}
                  setmonth={setmonth}
                  month={month}
                  setyear={setyear}
                  year={year}
                  setItem={setItem}
                  Item={Item}
                  setDescription={setDescription}
                  Description={Description}
                  setAmount={setAmount}
                  Amount={Amount}
                  setdate={setdate}
                  date={date}
                  setCategory={setCategory}
                  Category={Category}
                  setStatus={setStatus}
                  status={status}
                  isDisable={isDisable}
                  changeStatus={changeStatus}
                  updateId={updateId}
                />
                <AlertDelete
                  isDisable={isDisable}
                  deleteRecord={deleteRecord}
                  deleteId={deleteId}
                />
                <View
                  fullReport={fullReport}
                  getClientStatus={getClientStatus}
                />
                <div className="card-footer">
                  <Pagination
                    handlePageChange={handlePageChange}
                    controller={controller}
                    count={count}
                    handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                  />
                </div>
              </div>
            </>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(ExpensesList);
