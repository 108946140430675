import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import {
  ACTION,
  COMPANYNAME,
  COUNTRY,
  CREATEDAT,
  DELETE,
  EDIT,
  EMAIL,
  EMAILTYPE,
  END_DATE_TIME,
  FREQUENCY,
  NAME,
  NORECORDFOUND,
  NOTES,
  PHONE,
  RESEND,
  SCHEDULE_DATE_TIME,
  STATUS,
  UPDATEDAT,
  VIEW_DETAILS,
} from "../../constant/Label";
import Config from "../../config";
import { useHasPermission } from "../../components/Common/Permission";
import { useNavigate } from "react-router-dom";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  handleViewReportClick,
  handleDeleteClick,
  handleResendEmail,
  countryList,
  adminData,
}) {
  const navigate = useNavigate();
  const getEmailTypeName = (emailTypeValue) => {
    const emailType = Config?.EMAIL_TYPE?.find(
      (type) => type?.value === emailTypeValue
    );
    return emailType ? emailType?.name : emailTypeValue;
  };
  const getStatusName = (statusValue) => {
    const statusType = Config?.EMAIL_STATUS?.find(
      (type) => type?.value === statusValue
    );
    return statusType ? statusType?.name : statusValue;
  };

  const getFrequencyName = (frequencyValue) => {
    const frequencyList = Config?.FREQUENCY_STATUS?.find(
      (type) => type?.value === frequencyValue
    );
    return frequencyList ? frequencyList?.name : frequencyValue;
  };

  const btnNotesList = (app) => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_NOTE_LIST, { data: app });
    navigate(`/notes/list`, { state: app });
  };

  const cursorPointer = {
    cursor: "pointer",
  };

  const countryName = (timeZone) => {
    const data = countryList && countryList?.find((country) => country?.timeZone === timeZone);
    return data?.name;
  }

  const canDelete = useHasPermission(
    adminData.role,
    "email",
    Config.ACTION.delete
  );
  const canEdit = useHasPermission(
    adminData.role,
    "email",
    Config.ACTION.edit
  );
  const canView = useHasPermission(
    adminData.role,
    "email",
    Config.ACTION.detail
  );
  const canResend = useHasPermission(
    adminData.role,
    "email",
    Config.ACTION.resend
  );
  const canAddNotes = useHasPermission(
    adminData.role,
    "email",
    Config.ACTION.add_notes
  );
  const hasPermission = canDelete || canEdit || canView || canResend || canAddNotes;
  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>

          <th>{NAME}</th>
          <th>{EMAIL}</th>
          <th>{EMAILTYPE}</th>
          <th>{PHONE}</th>
          <th>{STATUS}</th>
          <th>{COMPANYNAME}</th>
          <th>{COUNTRY}</th>
          <th>{FREQUENCY}</th>
          <th>{SCHEDULE_DATE_TIME}</th>
          <th>{END_DATE_TIME}</th>
          <th>{CREATEDAT}</th>
          <th>{UPDATEDAT}</th>
          {hasPermission && <th>{ACTION}</th>}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="spinner-border text-dark m-5" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {" "}
            {applicants?.length === 0 && (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>
                  {" "}
                  <h3>{NORECORDFOUND}</h3>
                </td>
              </tr>
            )}
            {applicants?.map((app, index) => {
              return (
                <tr key={index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={index}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>
                  <td>
                    <span
                      className="text-decoration-underline text-primary"
                      onClick={() => btnNotesList(app)}
                      style={cursorPointer}
                    >
                      {app.name}
                    </span>
                  </td>{" "}
                  <td>
                    <span
                      className="text-decoration-underline text-primary"
                      onClick={() => btnNotesList(app)}
                      style={cursorPointer}
                    >
                      {app.email}
                    </span>
                  </td>{" "}
                  <td>{getEmailTypeName(app?.emailType)}</td>{" "}
                  <td>{app?.phone || "-"}</td>
                  <td>{getStatusName(app?.status)}</td>{" "}
                  <td>{app.companyName || "-"}</td>{" "}
                  <td>{countryName(app.country) || "-"}</td>{" "}
                  <td>{getFrequencyName(app.frequency) || "-"}</td>{" "}
                  <td>
                    {app.scheduledDateAndTime
                      ? moment(app.scheduledDateAndTime).format(
                        "DD MMM YYYY [at] HH:mm A"
                      )
                      : "-"}
                  </td>{" "}
                  <td>
                    {app.endDate
                      ? moment(app.endDate).format(
                        "DD MMM YYYY [at] HH:mm A"
                      )
                      : "-"}
                  </td>{" "}
                  <td>{moment(app.createdAt).format("DD-MM-YYYY")}</td>{" "}
                  <td>{moment(app.updatedAt).format("DD-MM-YYYY")}</td>{" "}
                  {hasPermission &&
                    <td>
                      {canResend &&
                        <button
                          type="button"
                          className="icon icon-success icon-circle"
                          title={RESEND}
                          data-bs-toggle="modal"
                          data-bs-target="#resendEmail"
                          onClick={(event) => handleResendEmail(event, app)}
                        >
                          <i className="bi bi-envelope"></i>
                        </button>}
                      &nbsp;
                      {canAddNotes &&
                        <button
                          type="button"
                          className="icon icon-secondary icon-circle"
                          title={NOTES}
                          data-bs-toggle="modal"
                          data-bs-target="#openNotes"
                          onClick={(event) => handleViewReportClick(event, app._id)}
                        >
                          <i className="bi bi-file-earmark-plus text-white"></i>
                        </button>}
                      &nbsp;
                      {canView &&
                        <button
                          type="button"
                          className="icon icon-dark icon-circle"
                          title={VIEW_DETAILS}
                          data-bs-toggle="modal"
                          data-bs-target="#viewpage"
                          onClick={(event) => handleViewReportClick(event, app._id)}
                        >
                          <i className="bi-eye"></i>
                        </button>}
                      &nbsp;
                      {canEdit &&
                        <button
                          type="button"
                          className="icon icon-danger icon-circle ms-1"
                          title={EDIT}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalLong"
                          onClick={(event) => handleViewReportClick(event, app._id)}
                        >
                          <i className="bi-pen"></i>
                        </button>}
                      &nbsp;
                      {canDelete &&
                        <button
                          type="button"
                          className="icon icon-info icon-circle"
                          title={DELETE}
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(event) => handleDeleteClick(event, app._id)}
                        >
                          <i className="bi-trash"></i>
                        </button>}
                      &nbsp;
                    </td>}
                  <></>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}
Table.propTypes = {
  handleCheckBoxChange: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  selectedinfo: PropTypes.object.isRequired,
  handleViewReportClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleResendEmail: PropTypes.func.isRequired,
  countryList: PropTypes.array.isRequired,
  adminData: PropTypes.object.isRequired,
};

export default Table;
