import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Config from "../../config";
import { AuthContext } from "../../Login/Protected";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import ResponsiveDatePickers from "../../Datepicker";
import { productivity } from "../../constant/Productivity";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";
import { userRoles } from "../../constant/userPermission";

function ProAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const [date, setDate] = useState();

  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setEmpId("Select employee name");
    setClientName("Select project");
    setTrackingTool("Select tracking tool");
    setStatus("Select status");
    setHoursType("Select hours type");
    setFormvalue(initialvalue);
  };
  const [empId, setEmpId] = useState("Select employee name");
  const [clientName, setClientName] = useState("Select project");
  const [trackingTool, setTrackingTool] = useState("Select tracking tool");
  const [status, setStatus] = useState("Select status");
  const [hoursType, setHoursType] = useState("Select hours type");
  const [getData, setGetData] = useState([]);
  const [trackingTools, setTrackingTools] = useState([]);
  const [getClient, setGetClient] = useState([]);
  const [errMsg, setErrMsg] = useState({
    date: "",
    name: "",
    clientName: "",
    trackingTool: "",
    hours: "",
    status: "",
    hoursType: "",
  });
  const [isDisable, setIsDisable] = useState(false);
  const [getTask, setGetTask] = useState([]);
  const [taskId, setTaskId] = useState("");
  // get data form employee table
  function changeclient(event) {
    setClientName(event);
    getTaskList(event);
  }
  const getEmployeeList = () => {
    AxiosActions.EmployeeList({})
      .then((response) => {
        if (response.status === 200) {
          setGetData(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get employee list", error);
      });
  };

  const getTrackingTools = () => {
    AxiosActions.TrackingList({})
      .then((response) => {
        if (response.status === 200) {
          setTrackingTools(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in getTrackingTools", error);
      });
  };

  const getClientList = () => {
    AxiosActions.ProdutivityClientList({})
      .then((response) => {
        if (response.status === 200) {
          setGetClient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  const getTaskList = (event) => {
    Axios.post(Config.LOCALURL + "tasks/list", { clientId: event })
      .then((response) => {
        if (response.status === 200) {
          setGetTask(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };
  useEffect(() => {
    getEmployeeList();
    getTrackingTools();
    getClientList();
  }, []);

  const getLoginEmployee = useCallback(() => {
    if (adminData.adminData.empid._id) {
      setEmpId(adminData.adminData.empid._id);
    }
  }, [adminData.adminData]);

  useEffect(() => {
    getLoginEmployee();
  }, [adminData, getLoginEmployee]);

  // Update value and state

  const initialvalue = {
    estimatedhours: "",
    actualhours: "",
    notes: "",
  };
  const [formvalue, setFormvalue] = useState(initialvalue);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value });
  };

  // Hahdle submit
  function handleSubmit(e) {
    trackEvent(EVENT_NAME.PRODUCTIVITY_ADD, { data: empId });
    e.preventDefault();
    let errObject = {
      date: "",
      name: "",
      clientName: "",
      trackingTool: "",
      hours: "",
      status: "",
      hoursType: "",
    };
    let err = 0;
    const hours = /^\d+(\.\d{1,2})?$/;
    if (empId === "Select employee name") {
      err++;
      errObject.name = "Please select name";
    }
    if (clientName === "Select project") {
      err++;
      errObject.clientName = "Please select project";
    }
    if (hoursType === "Select hours type") {
      err++;
      errObject.hoursType = "Please select hours type";
    }
    if (trackingTool === "Select tracking tool") {
      err++;
      errObject.trackingTool = "Select tracking tool";
    }
    if (status === "Select status") {
      err++;
      errObject.status = "Select status";
    }
    if (!hours.test(formvalue.estimatedhours)) {
      err++;
      errObject.estimatedhours = "Please enter valid hours";
    }
    if (!hours.test(formvalue.actualhours)) {
      err++;
      errObject.actualhours = "Please enter valid hours";
    }

    if (err !== 0) {
      console.log("has error", err, errObject);
      setErrMsg(errObject);
    } else {
      setIsDisable(true);
      AxiosActions.ProductivityAdd({
        date: adminData.adminData.isAdmin
          ? date
          : moment().format("YYYY-MM-DD"),
        Hours: parseFloat(formvalue.hours),
        status: status,
        typeOfHours: hoursType,
        empid: adminData.adminData.isAdmin
          ? empId
          : adminData.adminData.empid._id,
        clientId: clientName,
        trackingToolId: trackingTool,
        note: formvalue.notes,
        adminId: adminData._id,
        TaskId: taskId,
        estimatedHours: formvalue.estimatedhours,
        actualHours: formvalue.actualhours,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success === true) {
              setIsDisable(false);
              setOpenAlert(true);
              setTaskId("");
            }
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("Employee Add Error", error);
        });
    }
  }


  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage />
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">
                {productivity.PRODUCTIVITY_INFO}
              </h1>
            </div>

            <div className="card-body">
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.EMPLOYEE_NAME}
                </label>
                {userRoles.includes(adminData?.adminData?.role) && adminData?.adminData?.isAdmin ? (
                  <div className="col-sm-9">
                    <Autocomplete
                      options={getData}
                      getOptionLabel={(option) => option.employeeName}
                      value={
                        getData.find((option) => option._id === empId) || {
                          employeeName: "Select employee",
                        }
                      }
                      size="small"
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(event, newValue) => {
                        setEmpId(newValue ? newValue._id : "Select employee name");
                      }}
                    />
                    {errMsg.name ? (
                      <label
                        className="form-label"
                        style={{ fontSize: 15, color: "red" }}
                      >
                        {errMsg.name}
                      </label>
                    ) : null}
                  </div>
                ) : (
                  <div className="col-sm-9">
                    <label
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17, width: 200 }}
                    >
                      {adminData?.adminData?.empid?.employeeName}
                    </label>
                  </div>
                )}
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.DATE}
                </label>
                {adminData.adminData.isAdmin ? (
                  <div className="col-sm-9">
                    <div className="input-group input-group-sm-vertical">
                      <ResponsiveDatePickers
                        onChange={(newDate) => {
                          setDate(newDate);
                        }}
                        value={moment(date).format("YYYY-MM-DD")}
                      />
                    </div>{" "}
                  </div>
                ) : (
                  <label
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    {moment().format("YYYY-MM-DD")}
                  </label>
                )}
              </div>

              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.CLIENT_NAME}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={getClient || []}
                    getOptionLabel={(option) => option.clientName || ""}
                    value={getClient.find((item) => item._id === clientName) || null}
                    onChange={(event, newValue) => {
                      changeclient(newValue ? newValue._id : "");
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select client"
                        variant="outlined"
                        autoComplete="off"
                      />
                    )}
                  />

                  {errMsg.clientName !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.clientName}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.TASK}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={getTask || []}
                    getOptionLabel={(option) => option.summary || ""}
                    value={getTask.find((item) => item._id === taskId) || null}
                    onChange={(event, newValue) => {
                      setTaskId(newValue ? newValue._id : "");
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Task"
                        variant="outlined"
                        autoComplete="off"
                      />
                    )}
                  />
                  {errMsg.Task !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.Task}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.TRAKING_TOOLS}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={trackingTools || []}
                    getOptionLabel={(option) => option.trackingToolName || ""}
                    value={
                      trackingTools.find((item) => item._id === trackingTool) ||
                      { trackingToolName: "Select tracking tool" }
                    }
                    onChange={(event, newValue) => {
                      setTrackingTool(newValue ? newValue._id : "");
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                      />
                    )}
                  />
                  {errMsg.trackingTool !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.trackingTool}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.HOURS_TYPE}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={Config.PRODDUCTIVITY_HOURS_TYPE}
                    getOptionLabel={(option) => option.name}
                    value={Config.PRODDUCTIVITY_HOURS_TYPE.find((item) => item.value === hoursType) || { name: "Select hours type" }}
                    onChange={(event, newValue) => {
                      setHoursType(newValue ? newValue.value : "");
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" autoComplete="off" />
                    )}
                  />
                  {errMsg.hoursType !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.hoursType}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.ESTIMATED_HOURS}
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="estimatedhours"
                    onChange={handleChange}
                    min="0"
                    id="emailLabel"
                    placeholder="Hours"
                    aria-label="Email"
                    value={formvalue.estimatedhours}
                  />{" "}
                  {errMsg.estimatedhours !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.estimatedhours}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.ACTUAL_HOURS}
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="actualhours"
                    onChange={handleChange}
                    id="emailLabel"
                    placeholder="Hours"
                    aria-label="Email"
                    value={formvalue.actualhours}
                    min="0"
                  />{" "}
                  {errMsg.actualhours !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.actualhours}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 16 }}
                >
                  {productivity.STATUS}
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    options={Config.PRODDUCTIVITY_STATUS}
                    getOptionLabel={(option) => option}
                    value={
                      Config.PRODDUCTIVITY_STATUS.find(
                        (item) => item === status
                      ) || "Select status"
                    }
                    onChange={
                      (event, newValue) => {
                        setStatus(newValue);
                      }
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" autoComplete="off" />
                    )}
                  />
                  {errMsg.status !== "" ? (
                    <label
                      className=" form-label"
                      style={{ fontSize: 15, color: "red" }}
                    >
                      {errMsg.status}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="emailLabel"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  {productivity.NOTES}
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    placeholder="Notes"
                    id="validationValidTextarea1"
                    rows="2"
                    name="notes"
                    onChange={handleChange}
                    value={formvalue.notes}
                  ></textarea>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit}
                >
                  {productivity.SAVE_CHANGES}
                </button>
              </div>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main >
    </>
  );
}

export default HeaderNavbarHOC(ProAdd);
