import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useHasPermission } from '../../components/Common/Permission';
import Config from "../../config";
import { jobs } from "../../constant/Jobs";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  getClientStatus,
  handleViewReportClick,
  handleDeleteClick,
  adminData,
}) {

  const canDelete = useHasPermission(
    adminData.role,
    "jobs",
    Config.ACTION.delete
  );
  const canEdit = useHasPermission(
    adminData.role,
    "jobs",
    Config.ACTION.edit
  );
  const canView = useHasPermission(
    adminData.role,
    "jobs",
    Config.ACTION.detail
  );
  const hasPermission = canDelete || canEdit || canView;

  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered  table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>
          <th>{jobs.JOB_TITLE}</th>
          <th>{jobs.JOB_TYPE}</th>
          <th>{jobs.EXPERIENCE}</th>
          <th>{jobs.IMAGE}</th>
          <th>{jobs.LOCATION}</th>
          <th>{jobs.TIME}</th>
          <th>{jobs.STATUS}</th>
          {hasPermission && <th>{jobs.ACTION}</th>}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <>
            <tr>
              <td colSpan={8} style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="spinner-border text-dark m-5" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            {" "}
            {applicants.length === 0 && (
              <>
                <tr>
                  <td colSpan={9} style={{ textAlign: "center" }}>
                    {" "}
                    <h3>No record found</h3>
                  </td>
                </tr>
              </>
            )}
            {applicants.map((app, index) => {
              return (
                <tr className="text-nowrap" key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={index}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>
                  <td>{app.job_title}</td>{" "}
                  <td>{app.job_type}</td>
                  <td>{app.experience}</td>
                  <td>
                    <img src={app.image} height={50} width={50} alt={app.job_title}
                      className="cursor-pointer"
                      onClick={() => window.open(app.image, "_blank")}
                    />
                  </td>
                  <td>{app.location}</td>
                  <td>{app.time}</td>
                  <td>
                    {getClientStatus(app.status, "jobs")}
                  </td>
                  {hasPermission &&
                    <td className="text-nowrap">
                    {canEdit && 
                    <button
                      title="Edit job"
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>}
                    &nbsp;
                    {canDelete &&
                    <button
                      title="Delete job"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) => handleDeleteClick(event, app._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>}
                    &nbsp;
                    {canView &&
                    <button
                      title="View job details"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>}
                    &nbsp;
                  </td>}
                  <></>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;
