import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useStopwatch } from "react-timer-hook";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../config";
import AlertBox from "../../Alert";
import { AuthContext } from "../../Login/Protected";
import * as AxiosActions from "../../../src/Actions/axiosActions";
import { CaptureTime } from "../../../src/components/Common/Tracking";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import Button from "../../components/Common/Button";
import { TRACKING, TRACKING_ACTION } from "../../constant/Tracking";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY } from "../../constant/Label";
import TrackingCard from "../../components/Common/TrackingCard";

const TrackingToolsAdd = () => {
  const Navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const adminData = useContext(AuthContext);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const { seconds, minutes, hours, start, reset } = useStopwatch({
    autoStart: false,
  });
  const [startTimer, setStartTimer] = useState(false);
  const [getClient, setGetClient] = useState([]);
  const [getTask, setGetTask] = useState([]);
  const [clientId, setClientId] = useState("");
  const [taskId, setTaskId] = useState("");
  const [trackingId, setTrackingId] = useState("");
  const [todaySeconds, setTodaySeconds] = useState(0);
  const [todayMinutes, setTodayMinutes] = useState(0);
  const [todayHours, setTodayHours] = useState(0);
  const [weekSeconds, setWeekSeconds] = useState(0);
  const [weekMinutes, setWeekMinutes] = useState(0);
  const [weekHours, setWeekHours] = useState(0);
  const [taskSummary, setTaskSummary] = useState([]);
  const [isTaskSummary, setIsTaskSummary] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [isLeftSideCollapsed, setIsLeftSideCollapsed] = useState(false);
  const [selectedTask, setSelectedTask] = useState("todo");
  const [isDisable, setIsDisable] = useState(false);
  const [timerRunning, setTimerRunning] = useState(false);
  const [activityProgress, setActivityProgress] = useState({
    today: 0,
    weekly: 0,
  });
  
  const [lastUpdateTime, setLastUpdateTime] = useState(moment().format('hh:mm A')); 

  const isElectronAPIAvailable =
    typeof window !== "undefined" && window.electronAPI !== undefined;

  const getClientList = () => {
    AxiosActions.ProdutivityClientList({})
      .then((response) => {
        if (response.status === 200) {
          setGetClient(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  };

  useEffect(() => {
    getClientList();
  }, []);

  // Set Client ID for getting task
  const handleClient = (event) => {
    setClientId(event);
    getTaskList(event);
  };

  // Start Timer
  const handleStartTimer = async () => {
    setIsDisable(true);
    try {
      if (clientId === "") {
        setIsDisable(false);
        toast.dismiss();
        toast.error(TRACKING_ACTION.SELECT_CLIENT);
        return;
      }
  
      if (taskId === "") {
        setIsDisable(false);
        toast.dismiss();
        toast.error(TRACKING_ACTION.SELECT_TASK);
        return;
      }
  
      if (clientId !== "" && taskId !== "") {
        const startTimerResult = await window.electronAPI.onStartTimer();
        console.log('startTimerResult 102: ', startTimerResult)
        if (window && window.electronAPI.captureScreen) {
          const { thumbnailDataUrl, screenshotDataUrl, idleTime } = await window.electronAPI.captureScreen();
          console.log('handlestart idleTime 1: ', idleTime)
          setTimerRunning(true);
  
          if (thumbnailDataUrl || screenshotDataUrl) {
            const response = await AxiosActions.TrackingDetailsAdd({
              empid: adminData.adminData.empid._id,
              project: clientId,
              task: taskId,
              screenshot: null,
              captureTime: moment(),
              status: 'start',
            });
  
            if (response.data.code === 200) {
              setIsDisable(false);
              start();
              setStartTimer(true);
              setTrackingId(response.data.data._id);
              setIntervalId(setInterval(handleImageCapture, CaptureTime));
            } else {
              setIsDisable(false);
            }
          } else {
            setIsDisable(false);
            toast.dismiss();
            toast.error(TRACKING_ACTION.NO_SCREENSHOT_CAPTURED);
          }
        } else {
          setIsDisable(false);
          toast.dismiss();
          toast.error(TRACKING_ACTION.ELECTRON_API_UNAVAILABLE);
          return;
        }
      } else {
        setIsDisable(false);
        toast.dismiss();
        toast.error(TRACKING_ACTION.CLIENT_AND_TASK_REQUIRED);
      }
    } catch (error) {
      setIsDisable(false);
      console.log("Tracking error", error);
  
      // Handle the error based on the error type
      if (error.response && error.response.data) {
        toast.dismiss();
        toast.error(error.response.data.message || TRACKING_ACTION.TRACKING_ERROR);
      } else {
        toast.dismiss();
        toast.error(TRACKING_ACTION.UNEXPECTED_ERROR);
      }
    }
  };

  const handleSync = useCallback(async () => {
    try {
      const response = await AxiosActions.syncTimeTracking({
        empid: adminData.adminData.empid._id,
      });
      
      if (response.data.code === 200) {
        setLastUpdateTime(moment(response?.data?.data?.lastSyncTime).format('hh:mm A'));
        let miliseconds = response?.data?.data?.todaysTotalDifference;
        let weekMiliseconds = response?.data?.data?.weeklyTotalDifference;
        setTodaySeconds(Math.floor((miliseconds / 1000) % 60));
        setTodayMinutes(Math.floor((miliseconds / (1000 * 60)) % 60));
        setTodayHours(Math.floor((miliseconds / (1000 * 60 * 60)) % 24));

        setWeekSeconds(Math.floor((weekMiliseconds / 1000) % 60));
        setWeekMinutes(Math.floor((weekMiliseconds / (1000 * 60)) % 60));
        setWeekHours(Math.floor((weekMiliseconds / (1000 * 60 * 60)) % 24));
        
        setActivityProgress({
          today: response?.data?.data?.todayPercentage,
          weekly: response?.data?.data?.weeklyPercentage
        })

        return response;
      } else {
        toast.dismiss();
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.log("Error occure when sync time", error);
    }
  }, [adminData.adminData.empid._id]);

  // Task Summary
  const handleTaskSummary = useCallback((status) => {
    if (status === "todo") {
      AxiosActions.TaskSummary({
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          if (response.data.code === 200 && response.data.data.length !== 0) {
            const combinedArray = Object.entries(response.data.data.count).map(
              ([id, countData]) => {
                const dataObject = response.data.data.data.find(
                  (obj) => obj.task._id === id
                );
                return {
                  ...dataObject,
                  seconds: Math.floor((countData.sum / 1000) % 60),
                  minutes: Math.floor((countData.sum / (1000 * 60)) % 60),
                  hours: Math.floor((countData.sum / (1000 * 60 * 60)) % 24),
                };
              }
            );
            setTaskSummary(combinedArray);
            setIsTaskSummary(true);
          } else {
            setTaskSummary([]);
            setIsTaskSummary(false);
          }
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    } else if (status === "completed") {
      AxiosActions.CompletedTaskSummary({
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          if (response.data.data.length !== 0) {
            const combinedArray = Object.entries(response.data.data.count).map(
              ([id, countData]) => {
                const dataObject = response.data.data.data.find(
                  (obj) => obj.task._id === id
                );
                return {
                  ...dataObject,
                  seconds: Math.floor((countData.sum / 1000) % 60),
                  minutes: Math.floor((countData.sum / (1000 * 60)) % 60),
                  hours: Math.floor((countData.sum / (1000 * 60 * 60)) % 24),
                };
              }
            );
            setTaskSummary(combinedArray);
            setIsTaskSummary(true);
          } else {
            setTaskSummary([]);
            setIsTaskSummary(false);
          }
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    }
  }, [adminData.adminData.empid._id]);
  
  // Stop Timer
  const handleStopTimer = useCallback(async () => {
    setIsDisable(true);

    try {
      if (clientId !== "" && taskId !== "") {
        if (window.electronAPI.captureScreen) {
          const { thumbnailDataUrl, screenshotDataUrl, idleTime } = await window.electronAPI.captureScreen();
          
          if (thumbnailDataUrl || screenshotDataUrl) {
            const response = await AxiosActions.TrackingDetailsAdd({
              _id: trackingId,
              empid: adminData.adminData.empid._id,
              project: clientId,
              task: taskId,
              screenshot: null,
              captureTime: moment(),
              status: 'end'
            });
  
            if (response.data.code === 200) {
              setIsDisable(false);
              reset(new Date(), false);
              setStartTimer(false);
              setIntervalId(clearInterval(intervalId));
              handleSync();
              handleTaskSummary("todo");
            } else {
              setIsDisable(false);
            }
          } else {
            setIsDisable(false);
            toast.dismiss();
            toast.error(TRACKING_ACTION.NO_SCREENSHOT_CAPTURED);
          }
        } else {
          setIsDisable(false);
          toast.dismiss();
          toast.error(TRACKING_ACTION.ELECTRON_API_UNAVAILABLE);
        }
      } else {
        setIsDisable(false);
        toast.dismiss();
        toast.error(TRACKING_ACTION.CLIENT_AND_TASK_REQUIRED);
      }
    } catch (error) {
      setIsDisable(false);
      console.log("Tracking error", error);
  
      // Handle the error based on the error type
      if (error.response && error.response.data) {
        toast.dismiss();
        toast.error(error.response.data.message || TRACKING_ACTION.TRACKING_ERROR);
      } else {
        toast.dismiss();
        toast.error(TRACKING_ACTION.UNEXPECTED_ERROR);
      }
    }
  }, [adminData.adminData.empid._id, clientId, handleSync, handleTaskSummary, intervalId, reset, taskId, trackingId]);

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      // Prevent the default behavior
      event.preventDefault();
      if(window && window.electronAPI.onStopTimerBeforeQuit) {
        // Call handleStopTimer when the app closes
        const stopResult = await window.electronAPI.onStopTimerBeforeQuit();
        if(stopResult && startTimer) {
          handleStopTimer();
        }
        // In some browsers, you might need to explicitly return a string
        event.returnValue = 'Are you sure you want to leave?';
      }
    };

    // Attach the event listener to the beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup function to remove the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [timerRunning, handleStopTimer, startTimer]);

  useEffect(() => {
    // Listen for the idle time event
    if(window.electronAPI && window.electronAPI.onIdleTimeReached) {
      window.electronAPI.onIdleTimeReached(() => {
        handleStopTimer();
      });
    }

    return () => {
      // Clean up listener when the component unmounts
      if(window.electronAPI && window.electronAPI.removeIdleTimeReachedListener){
        window.electronAPI.removeIdleTimeReachedListener();
      }
    };
  }, [handleStopTimer]);  

  // Image Capture
  const handleImageCapture = async () => {
    if (clientId !== "" && taskId !== "") {
      if (window.electronAPI.captureScreen) {
        const { thumbnailDataUrl, screenshotDataUrl, idleTime } = await window.electronAPI.captureScreen();
        if (screenshotDataUrl || thumbnailDataUrl) {
          const timestamp = new Date().getTime();
          const uid = adminData.adminData.empid._id;
          const fileName = `${uid.slice(-4)}_${timestamp}_screenshot.png`;
          const imgURL = await handleUploadScreenshot(screenshotDataUrl, fileName);
          AxiosActions.TrackingDetailsAdd({
            empid: adminData.adminData.empid._id,
            project: clientId,
            task: taskId,
            screenshot: imgURL,
            captureTime: moment(),
            status: 'inprogress',
            idleTime: idleTime
          })
            .then((response) => {})
            .catch((error) => {
              console.log("Tracking error", error);
            });
        } else {
          toast.dismiss();
          toast.error('Failed to capture screen');
        }
      } else {
        toast.dismiss();
        toast.error(TRACKING_ACTION.ELECTRON_API_UNAVAILABLE);
      }
    } else {
      toast.dismiss();
      toast.error(TRACKING_ACTION.CLIENT_AND_TASK_REQUIRED);
    }
  };

  // Function to upload the screenshot
  const handleUploadScreenshot = async (screenshot, fileName) => {
    const formData = new FormData();
    
    // Convert base64 screenshot to a Blob (this is optional, depending on how your server expects the data)
    const byteString = atob(screenshot.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uintArray], { type: 'image/png' });

    formData.append('file', blob, fileName);
    formData.append('name', fileName);

    try {
      const response = await axios.post(`${Config.APIURL}time/attendance/upload/screenshot`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file upload
        },
      });
  
      if (response.data.code === 200) {
        return response.data.data;
      } else {
        toast.dismiss();
        toast.error('Failed to upload screenshot');
      }
    } catch (error) {
      console.error('Error uploading screenshot:', error);
    }
  };

  // Update Sattus
  const handleStatusUpdate = (taskid, taskstatus) => {
    if (taskstatus === "IN PROGRESS" || taskstatus === "TO DO") {
      AxiosActions.UpdateTaskStatus({
        _id: taskid,
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          setIsTaskSummary(false);
          handleTaskSummary("todo");
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    } else if (taskstatus === "DONE") {
      AxiosActions.UpdateTaskToDefault({
        _id: taskid,
        empid: adminData.adminData.empid._id,
      })
        .then((response) => {
          setIsTaskSummary(false);
          handleTaskSummary("todo");
        })
        .catch((error) => {
          console.log("Tracking error", error);
        });
    }
  };

  // Tap on back button
  const navigateHome = () => {
    Navigate("/dashboard");
  };

  const handleToggleLeftSide = () => {
    setIsLeftSideCollapsed(!isLeftSideCollapsed);
  };

  const getTaskList = useCallback((event) => {
    AxiosActions.EmployeeTask({
      clientId: event,
      empid: adminData.adminData.empid._id,
    })
      .then((response) => {
        if (response.status === 200) {
          setGetTask(response.data.data);
          setTimeout(() => {
            if (adminData) {
              handleSync();
              handleTaskSummary("todo");
            }
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("Error in get Client", error);
      });
  }, [adminData, handleTaskSummary, handleSync]);

  useEffect(() => {
    handleSync();
    handleTaskSummary(selectedTask);
  }, [handleSync, handleTaskSummary, selectedTask, lastUpdateTime]);

  let content;

  if (isTaskSummary === false && taskSummary.length > 0) {
    content = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  } else if (taskSummary.length === 0) {
    content = (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <span className="card-subtitle">{TRACKING.NO_RECORD_FOUND}</span>
      </Box>
    );
  } else {
    content = taskSummary.map((item) => {
      return (
        <div
          className="w-100 card card-hover-shadow border"
          style={{ marginTop: 10 }}
          key={item.task._id}
        >
          <div className="card-body w-100 p-3">
            <tr className="d-flex align-items-center">
              <td className="form-check-inline">
                  <input
                    type="checkbox"
                    id="formInlineCheck1"
                    title={selectedTask === 'todo' ? 'Complete task' : ''}
                    onClick={() =>
                      handleStatusUpdate(item.task._id, item.task.status)
                    }
                    style={{ cursor: startTimer ? 'auto' : "pointer", height: 15, width: 15 }}  
                    disabled={startTimer}
                  />
              </td>
              <td>
                {" "}
                <h2 className="card-title text-inherit d-flex" style={{ fontSize: 16 }}>
                  {item.task.summary}
                </h2>
                <div className="row align-items-center">
                  <div className="col">
                    <tr>
                      <td>
                        {" "}
                        <h3 className="card-subtitle">
                          {item.project.clientName}
                        </h3>
                      </td>{" "}
                      <td>
                        <h4 style={{ marginLeft: 100 }}>
                          <span>
                            {" "}
                            {item.hours > 9
                              ? item.hours
                              : `0` + item.hours}{" "}
                          </span>{" "}
                          :
                          <span>
                            {" "}
                            {item.minutes > 9
                              ? item.minutes
                              : `0` + item.minutes}{" "}
                          </span>
                          :
                          <span>
                            {" "}
                            {item.seconds > 9
                              ? item.seconds
                              : `0` + item.seconds}{" "}
                          </span>
                        </h4>
                      </td>
                    </tr>
                  </div>
                </div>
              </td>
            </tr>
          </div>
        </div>
      );
    });
  }

  return (
    <>
      {" "}
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage />
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div
            className="card-group"
            style={{
              display: "flex",
              flexDirection: "row",
              height: 550,
              backgroundColor: "#e6e6e6",
              width: isLeftSideCollapsed ? 450 : '100%',
              justifyItems: "center",
            }}
          >
            {!isLeftSideCollapsed && (
              <div
                id="mySidenav"
                className="card card-center"
                style={{
                  backgroundColor: "#e6e6e6",
                }}
              >
                <div className="card-body card-body-height">
                  { isElectronAPIAvailable ? '' : 
                    <h1>
                      {" "}
                      <ArrowBackIcon onClick={navigateHome} /> {TRACKING.MY_TASK}
                    </h1>
                  }
                  <fieldset
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                    style={{
                      backgroundColor: "white",
                      height: 40,
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio1"
                      autoComplete="off"
                      checked={selectedTask === "todo"}
                    />
                    <Button
                      className="btn btn-outline-danger"
                      style={{ height: 40, alignItems: "center", border: '1px solid #333399', background: selectedTask === 'todo' ? '#333399' : '', color: selectedTask === 'todo' ? 'white' : '#333399' }}
                      onClick={() => { setSelectedTask('todo'); handleTaskSummary("todo"); }}
                    >
                      {TRACKING.TO_DO}
                    </Button>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio2"
                      autoComplete="off"
                      checked={selectedTask === "completed"}
                    />
                    <Button
                      className="btn btn-outline-danger"
                      style={{ height: 40, alignItems: "baseline", border: '1px solid #333399', background: selectedTask === 'completed' ? '#333399' : '', color: selectedTask === 'completed' ? 'white' : '#333399' }}
                      onClick={() => { setSelectedTask('completed'); handleTaskSummary("completed"); }}
                    >
                      {TRACKING.COMPLETED}
                    </Button>
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio3"
                      autoComplete="off"
                    />
                  </fieldset>

                  {content}
                </div>
              </div>
            )}
            
            {/* Right Side */}
            <div className="card">
              <div className="card-body">
                <div
                  className="card card-hover-shadow text-danger "
                  style={{border: '1px solid #333399' , backgroundColor : 'rgb(101 101 203 / 14%)'}}
                >
                  <div className="card-body">
                    <h2 className="card-subtitle "
                      style={{color: 'rgb(84, 84, 88)'}}
                    >Current Session</h2>
                    <div className="row align-items-center ">
                      <div className="col-6">
                        <h2 className="card-title text-inherit">
                          <span> {hours > 9 ? hours : `0` + hours} </span> :
                          <span>
                            {" "}
                            {minutes > 9 ? minutes : `0` + minutes}{" "}
                          </span>
                          :
                          <span>
                            {" "}
                            {seconds > 9 ? seconds : `0` + seconds}{" "}
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <select
                  style={{ marginTop: 20 }}
                  className="form-select"
                  autoComplete="off"
                  name="Name"
                  onChange={(event) => handleClient(event.target.value)}
                  value={clientId}
                  disabled={startTimer === true}
                >
                  <option value="" selected>
                    {TRACKING.SELECT_CLIENT}
                  </option>

                  {getClient.map((item, index) => {
                    return (
                      <option disabled={startTimer === true} value={item._id} key={item._id}>
                        {item.clientName}
                      </option>
                    );
                  })}
                </select>
                <select
                  className="form-select"
                  autoComplete="off"
                  name="Name"
                  onChange={(event) => setTaskId(event.target.value)}
                  value={taskId}
                  disabled={startTimer === true}
                  style={{ marginTop: 20 }}
                >
                  <option value="" selected>
                    {TRACKING.SELECT_TASK}
                  </option>

                  {getTask.map((item, index) => {
                    return (
                      <option disabled={startTimer} value={item._id} key={item._id}>
                        {item.summary}
                      </option>
                    );
                  })}
                </select>
                <Button
                  className="btn btn-danger"
                  style={{ marginTop: 10, width: '100%', fontSize: 18, background: '#333399', border: 'none' }}
                  onClick={startTimer ? handleStopTimer : handleStartTimer}
                  disabled={isDisable}
                  icon={startTimer ? "bi bi-pause-fill" : "bi bi-play-fill"}
                >
                  {startTimer ? TRACKING.STOP_TRACKING : TRACKING.START_TRACKING}
                  {isDisable && (<CircularProgress style={{ marginLeft: 2, color: 'white', width: '20px', height: '20px', position: 'relative', top: '5px' }} />) }
                </Button>

                <TrackingCard title={TRACKING.TODAY_TOTAL} hours={todayHours} minutes={todayMinutes} seconds={todaySeconds} value={activityProgress?.today ?? 0} />
                <TrackingCard title={TRACKING.WEEKLY_TOTAL} hours={weekHours} minutes={weekMinutes} seconds={weekSeconds} value={activityProgress?.weekly ?? 0} />
                
                <div
                  className="d-flex align-items-center"
                  style={{
                    flexDirection: "row",
                    marginTop: 15,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    className="btn btn-icon btn-sm"
                    style={{ height: '25px', width: 25, background: '#333399', color: 'white' }}
                    onClick={handleToggleLeftSide}
                  >
                    {isLeftSideCollapsed ? <i className="bi bi-chevron-double-right"></i> : <i className="bi bi-chevron-double-left"></i>}
                  </Button>

                  <div className="d-flex align-items-center">
                    <label
                      className="form-label justify-right m-0"
                      style={{ fontSize: 15, marginLeft: 0 }}
                    >
                      {TRACKING.LAST_UPDATE}: {lastUpdateTime}
                      <Button
                        className="link link-primary border-0 bg-transparent p-0"
                        onClick={() => {
                          handleSync()
                        .then((response) => {
                          if(response.data.code === 200) {
                            toast.dismiss();
                            toast.success(response?.data?.message);
                          } else {
                            toast.dismiss();
                            toast.error(TRACKING_ACTION.SYNC_ERROR);
                          }
                        })
                        .catch((error) => {
                            toast.error(TRACKING_ACTION.SYNC_ERROR);
                        });
                        }}
                      >
                        <i className="bi bi-arrow-repeat ms-2 me-1"></i>{TRACKING.SYNC}
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
      </main>
    </>
  );
};

export default HeaderNavbarHOC(TrackingToolsAdd);
