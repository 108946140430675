import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import Config from "../config";

const AuthWrapper = ({ children }) => {

  return (
    <Auth0Provider
      domain={Config.DOMAIN_ID}
      clientId={Config.CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + '/dashboard'
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthWrapper;
