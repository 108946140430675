import { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../../config";

export const usePermissions = () => {

  const [permissions, setPermissions] = useState({});

  const fetchPermissions = () => {
    axios
      .get(Config.LOCALURL + "api/v1/permission/list")
      .then((response) => {
        if (response.status === 200) {
          const fetchedPermissions = response.data.data.reduce((acc, roleData) => {
            acc[roleData.role] = roleData.permissions.reduce((roleAcc, perm) => {
              roleAcc[perm.menu] = { actions: perm.actions[0] };
              return roleAcc;
            }, {});
            return acc;
          }, {});
          setPermissions(fetchedPermissions);
        }
      })
      .catch((error) => {
        console.error("Error in fetching permissions", error);
      });
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  return permissions;
};

export const useHasPermission = (userRole, feature, action) => {
  const permissions = usePermissions()
  return permissions[userRole]?.[feature]?.actions[action] || false;
};