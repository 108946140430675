import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Config from "../../config";
import * as AxiosActions from "../../Actions/axiosActions";
import { EVENT_NAME, trackEvent } from "../../constant/MixpanelConstant";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import { useFormik } from "formik";
import { AlertDelete, Filter, Pagination } from "../../components/Common";
import {
  ACTION,
  CURRENT_SALARY,
  DATAUPDATESUCCESSFULLY,
  DELETE,
  EDIT,
  EMPLOYEE_TYPE,
  FROM_DATE,
  INCREMENT,
  NORECORDFOUND,
  PERIOD,
  ROLE,
  TO_DATE,
  TYPE,
} from "../../constant/Label";
import { useHasPermission } from "../../components/Common/Permission";
import Useuser from "../../Contexct";
import { toast } from "react-toastify";
import AlertBox from "../../Alert";
import EditEmpHistoryModal from "./HistoryEdit";

function HistoryList() {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filterDate, setFilterDate] = useState("all");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [count, setCount] = useState(0);
  const { state } = useLocation();
  const [fullReport, setFullReport] = useState([]);
  const [updateId, setUpdateId] = useState([]);
  const [inputValue, setInputValue] = useState({
    empType: "",
    fromDate: "",
    toDate: "",
    role: "",
    isDeleted: "",
    currentSalary: "",
    increment: "",
    period: "",
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const formik = useFormik({
    initialValues: {
      initialValues: {
        empId: "",
        fromDate: "",
        toDate: "",
        empType: "",
        role: "",
        currentSalary: 0,
        increment: 0,
        period: 0
      }
    },
  });
  const token = localStorage.getItem("access_token");
  const { adminData } = Useuser();
  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const renderdata = async () => {
    try {
      setLoading(true);

      console.log("Fetching data for empid:", state?._id); // Debugging

      const response = await AxiosActions.EmployeeHistoryList({
        empid: state?._id,
        page: controller.page,
        limit: controller.rowsPerPage,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        type: filterDate,
        month: filterMonth,
        timestamp: new Date().getTime(),
      });

      console.log("API Response:", response?.data);

      if (response?.status === 200) {
        const resultData = Array.isArray(response?.data?.data) ? response?.data?.data : [];
        console.log("Updated applicants list:", resultData);

        if (resultData.length === 0) {
          console.warn("No data returned. Check if filters are correct.");
        }

        setApplicants([...resultData]);
        setCount(response?.data?.count || 0);
      } else {
        console.error("Unexpected API response:", response);
        setApplicants([]);
      }
    } catch (error) {
      console.error("Error fetching employee history:", error);
      setApplicants([]);
    } finally {
      setLoading(false);
    }
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.EMPLOYEE_HISTORY_VIEW, { data: "" });
    setIsFiltered(true);
    if (!isFiltered) {
      let data = {
        page: 0,
        rowsPerPage: 10,
        preventRefresh: false,
      };
      setController(data);
    }
    renderdata();
  };


  useEffect(() => {
    if (isFiltered) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, isFiltered, filterYear, filterMonth, filterDate]);

  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });
  };

  const reset = () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_RESET_NOTE_LIST, { data: "" });
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setIsFiltered(false);
    setFilterDate("all");
  };

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);
    event.preventDefault();
  };

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMPLOYEE_HISTORY_DELETE, { data: id });
    console.log(id);
    AxiosActions.DeleteEmployeeHistory({
      id: id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Record deleted successfully")
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        toast.error("some error occurred");
      });

    event.preventDefault();
  };


  const handleHistoryViewClick = (event, id) => {
    trackEvent(EVENT_NAME.EMPLOYEE_HISTORY_VIEW, { data: id });
    const filterRecord = applicants?.filter((item) => {
      if (id === item._id) {
        const data = {
          empType: item?.empType,
          fromDate: item?.fromDate,
          toDate: item?.toDate,
          role: item?.role,
          currentSalary: item?.currentSalary,
          increment: item?.increment,
          period: item?.period,
        };
        setInputValue(data);
        return true;
      }
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };

  const changeStatus = async (statusid) => {
    try {
      setIsDisable(true);
      trackEvent(EVENT_NAME.EMPLOYEE_HISTORY_DELETE, { data: statusid });

      const response = await AxiosActions.EditEmployeeHistory({
        id: statusid,
        empid: state?._id,
        empType: formik.values.empType || "",
        fromDate: formik.values?.fromDate || "",
        toDate: formik.values?.toDate || "",
        role: formik.values?.role || "",
        currentSalary: Number(formik.values?.currentSalary) || 0,
        increment: Number(formik.values?.increment) || 0,
        period: formik.values?.period || "",
      });

      setIsDisable(false);
      console.log("Updated data:", response?.data?.data);

      setOpenAlert(true);

      setTimeout(async () => {
        console.log("Fetching latest data...");
        await renderdata();
      }, 500);
    } catch (err) {
      setIsDisable(false);
      console.error("Update Error:", err);
      alert("Please Update Record Again.");
    }
  };

  console.log("applicants>>", applicants)


  const canDelete = useHasPermission(
    adminData.role,
    "employee",
    Config.ACTION.delete
  );
  const canEdit = useHasPermission(
    adminData.role,
    "employee",
    Config.ACTION.edit
  );

  const hasPermission = canDelete || canEdit;

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="container">
                <div className="row">
                  <div className="col-sm">
                    <h2 className="mb-0">{state?.employeeName}</h2>
                    <h4 className="mb-0 mt-2">{state?.employeeEmail}</h4>
                  </div>
                  <div className="col-sm">
                    <h4 className="mb-0">
                      {state?.empType && `${TYPE} : ${state?.empType}`}
                    </h4>
                    <h4 className="mb-0 mt-2">
                      {state?.role && `${ROLE} : ${state?.role}`}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  date={true}
                  dateValue={filterDate}
                  dateUpdate={setFilterDate}
                  startDateValue={startDate}
                  startDateUpdate={setStartDate}
                  endDateValue={endDate}
                  endDateUpdate={setEndDate}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />
              </div>
              <div className="card mb-3 mb-lg-5">
                <table
                  id="datatable"
                  className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
                >
                  <thead className="thead-light">
                    <tr>
                      <th>{ROLE}</th>
                      <th>{EMPLOYEE_TYPE}</th>
                      <th>{PERIOD}</th>
                      <th>{CURRENT_SALARY}</th>
                      <th>{INCREMENT}</th>
                      <th>{FROM_DATE}</th>
                      <th>{TO_DATE}</th>
                      {hasPermission && <th>{ACTION}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} style={{ textAlign: "center" }}>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className="spinner-border text-dark m-5" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {applicants?.length === 0 && ( // Fix Empty Check
                          <tr>
                            <td colSpan={9} style={{ textAlign: "center" }}>
                              <h3>{NORECORDFOUND}</h3>
                            </td>
                          </tr>
                        )}
                        {applicants?.map((app, index) => (
                          <tr key={app._id || index}>
                            <td>{app.role || "-"}</td>
                            <td>{app?.empType || "N/A"}</td>
                            <td>{app.period || "-"}</td>
                            <td>{app.currentSalary}</td>
                            <td>{app.increment || "-"}</td>
                            <td>{moment(app.fromDate).format("D MMM YYYY")}</td>
                            <td>{moment(app.toDate).format("D MMM YYYY")}</td>
                            {hasPermission && (
                              <td>
                                {canEdit && (
                                  <button
                                    type="button"
                                    className="icon icon-danger icon-circle ms-1"
                                    title={EDIT}
                                    data-bs-toggle="modal"
                                    data-bs-target="#historyEdit"
                                    onClick={(event) => handleHistoryViewClick(event, app._id)}
                                  >
                                    <i className="bi-pen"></i>
                                  </button>
                                )}
                                &nbsp;
                                {canDelete && (
                                  <button
                                    type="button"
                                    className="icon icon-info icon-circle"
                                    title={DELETE}
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete"
                                    onClick={(event) => handleDeleteClick(event, app._id)}
                                  >
                                    <i className="bi-trash"></i>
                                  </button>
                                )}
                              </td>
                            )}
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>

                </table>
                <EditEmpHistoryModal formik={formik} fullReport={fullReport} changeStatus={changeStatus} inputValue={inputValue} isDisable={isDisable} updateId={updateId} />
                <AlertDelete
                  isDisable={isDisable}
                  deleteRecord={deleteRecord}
                  deleteId={deleteId}
                />
              </div>
              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(HistoryList);
