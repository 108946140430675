import React, { useState, useMemo } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { useDropzone } from "react-dropzone";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import {
  WHATSAPP_CSV_IMPORT,
  DOWNLOAD_SAMPLE_CSV,
  FILES,
  IMPORT,
  IS_CSV,
  IS_SELECT_CSV,
  SAMPLE_CSV_DATA,
  SAMPLE_CSV_HEADERS,
  TEMPLATE,
  UPLOAD,
  UPLOAD_MESSAGE_CSV,
  IS_SELECT_TEMPLATE,
  CSV_SAVED,
  SELECTTEMPLATE,
} from "../../constant/Label";
import { FormikError } from "../../components/Common";
import {
  acceptStyle,
  baseStyle,
  customStyle,
  focusedStyle,
  rejectStyle,
} from "../../components/Common/Style";
import {
  FileDownloadIcons,
  shapeBottomIcons,
} from "../../components/Common/Icons";
import Config from "../../config";
import BackgroundImage from "../../components/Common/CardImage";

/**
 * @desc Functional component representing the CSV Import screen.
 *
 * @component
 * @param {Object} props - Component props
 */

function Add() {
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);

  let labelText = [
    { type: "text", text: "" },
    { type: "text", text: "" },
    { type: "text", text: "" },
    { type: "text", text: "" },
    { type: "text", text: "" },
  ];

  const [inputFields, setInputFields] = useState({
    label: labelText,
  });

  /**
   * Updates the value of a specific input field.
   * @param index - The index of the input field to update.
   * @param event - The change event containing the new value.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const index = parseInt(name.slice(-1), 10) - 1;
    setInputFields((prevFormValue) => ({
      ...prevFormValue,
      label: prevFormValue.label.map((item, i) =>
        i === index ? { ...item, text: value } : item
      ),
    }));
    // Remove error message if any input field has text
    if (value.trim() !== "") {
      formik.setFieldError("label", "");
    }
  };

  /**
   * @desc Function to handle the change in the template status dropdown.
   * @param {Object} event - The change event object.
   */
  const changeTempStatusValue = (event) => {
    formik.setFieldValue("temp_status", event.target.value);
  };

  /**
   * @desc Function to generate CSV content for download.
   * @returns {string} Encoded URI of CSV content.
   */
  const generateCSVContent = () => {
    const headers = SAMPLE_CSV_HEADERS;
    const sampleData = [SAMPLE_CSV_DATA];

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\n";
    sampleData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    return encodeURI(csvContent);
  };

  /**
   * @desc Function to download the sample CSV file.
   */
  const downloadCSV = () => {
    const csvContent = generateCSVContent();
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      multiple: false,
      accept: { "text/csv": [".csv"] },
      onDrop: (acceptedFiles, fileRejections) => {
        if (
          acceptedFiles &&
          acceptedFiles[0] &&
          acceptedFiles[0].type === "text/csv"
        ) {
          const file = acceptedFiles[0];
          if (file) {
            Papa.parse(file, {
              complete: (result) => {
                if (result.data) {
                  const trimmedData = result.data.map((row) =>
                    row.map((cell) => cell.trim())
                  );

                  setCsvData(trimmedData);
                }
              },
              header: false,
            });
            formik.setFieldValue("csvFile", file); // Set the selected CSV file in formik values
          }
        } else {
          setCsvData([]);
          formik.setFieldValue("csvFile", null);
          formik.setFieldError("csvFile", IS_SELECT_CSV);
        }
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      flexDirection: undefined,
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const formik = useFormik({
    initialValues: {
      csvFile: null,
      temp_status: "",
      label: labelText,
    },
    validate: (values) => {
      const errors = {};
      if (formik.submitCount >= 0 && values.temp_status === "") {
        errors.temp_status = IS_SELECT_TEMPLATE;
      }
      // Check if at least one input field is filled
      const isAnyInputFieldFilled = inputFields?.label.some(
        (field) => field.text.trim() !== ""
      );
      if (!isAnyInputFieldFilled) {
        errors.label = "At least one input field must be filled.";
      }
      if (formik.submitCount >= 0 && values.csvFile === null) {
        errors.csvFile = IS_CSV;
      }
      return errors;
    },
    /**
     * @desc Function to handle form submission.
     * @param {Object} values - Form values.
     */
    onSubmit: async (values) => {
      const parameter = inputFields?.label?.map((field) => ({
        type: "text",
        text: field.text,
      }));
      const filteredParameter = parameter.filter(
        (item) => item.text.trim() !== ""
      );

      formik.resetForm();
      setIsLoading(true);
      const formData = new FormData();
      if (values.csvFile) {
        formData.append("csvFile", values.csvFile);
      }
      formData.append("parameters", JSON.stringify(filteredParameter));

      try {
        if (values.csvFile) {

          const response = await axios.post(
            `${Config.APIURL}whatsapp/marketing/readcsv/${values.temp_status}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.data.code === 200) {
            const addedCount = response?.data?.data?.addedUsersCount || 0;
            const skippedCount = response?.data?.data?.skippedRowsCount || 0;

            toast.dismiss();
            toast.success(
              `${CSV_SAVED}: \nAdded Users: ${addedCount} \nSkipped Users: ${skippedCount}`
            );

            setCsvData([]);
            setInputFields({
              label: labelText,
            });
          }
        } else {
          console.log("No file selected");
        }
      } catch (error) {
        console.log("Upload failed: ", error);
        if (error?.name === "AxiosError") {
          toast.dismiss();
          toast.error("Something went wrong");
        } else {
          toast.dismiss();
          toast.error("Recipient phone number not in allowed list");
        }
      } finally {
        setIsLoading(false);
        setInputFields({
          label: labelText,
        });
        setCsvData([]);
      }
    },
  });

  return (
    <>
      <main id="content" role="main" className="main">
        <BackgroundImage/>
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ padding: "1.3rem 1.3rem" }}
            >
              <div>
                <h1 className="card-title h1">{WHATSAPP_CSV_IMPORT}</h1>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="d-flex align-items-center justify-content-between flex-wrap download-csv"
                  onClick={() => downloadCSV()}
                >
                  {FileDownloadIcons}{" "}
                  <span className="ms-2">{DOWNLOAD_SAMPLE_CSV}</span>
                </button>
              </div>
            </div>

            <div className="card-body pt-0">
              <form
                className="shadow-md rounded px-8 pt-6 pb-8 mb-5"
                onSubmit={formik.handleSubmit}
                style={customStyle?.addUpdateCard}
              >
                <div className="d-flex justify-content-end">
                  <button
                    disabled={isLoading}
                    className="btn btn-warning"
                    type="submit"
                  >
                    {UPLOAD}
                  </button>
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-4">
                    <label className="col-form-label" htmlFor="temp_status">
                      {TEMPLATE}
                    </label>
                    <select
                      className="form-select form-control"
                      name="temp_status"
                      id="temp_status"
                      onChange={changeTempStatusValue}
                      value={formik.values.temp_status}
                    >
                      <option value="">{SELECTTEMPLATE}</option>
                      {Config.TEMPLATE_TYPE.map((item, index) => (
                        <option value={item.value} key={index}>
                          {" "}
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.temp_status &&
                      formik.errors.temp_status && (
                        <FormikError label={formik.errors.temp_status} />
                      )}
                  </div>
                </div>
                {inputFields?.label?.map((labelItem, index) => (
                  <div className="d-flex flex-wrap mb-4" key={index}>
                    <label
                      className="col-sm-1 col-form-label form-label pt-1"
                      style={{ fontSize: 17 }}
                    >
                      {`{{${index + 1}}}`}
                    </label>
                    <div className="col-sm-11">
                      <input
                        type="text"
                        className="form-control"
                        name={`label${index + 1}`}
                        onChange={handleInputChange}
                        id={`label${index + 1}`}
                        aria-label={labelItem?.type}
                        value={inputFields?.label[index]?.text}
                      />
                      {formik.touched.label &&
                        formik.errors.label &&
                        index === 0 && (
                          <FormikError label={formik.errors.label} />
                        )}
                    </div>
                  </div>
                ))}

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label className="col-form-label">{IMPORT}</label>
                    <div className="form-border border p-4">
                      <label className="col-form-label pt-0" htmlFor="link">
                        {FILES}
                      </label>

                      <section>
                        <div
                          {...getRootProps({ style })}
                          className="cursor-pointer"
                        >
                          <input {...getInputProps()} />
                          <p className="m-0">{UPLOAD_MESSAGE_CSV}</p>
                        </div>
                        {formik.touched.csvFile && formik.errors.csvFile && (
                          <FormikError label={formik.errors.csvFile} />
                        )}
                      </section>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  {csvData?.length > 0 && (
                    <div className="">
                      <h2 className="text-2xl font-bold">CSV Import Table</h2>
                      <table className="table-auto w-full mt-4">
                        <thead>
                          <tr>
                            {csvData[0]?.map((header, index) => (
                              <th key={index} className="px-4 py-2">
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {csvData?.slice(1).map(
                            (row, rowIndex) =>
                              row?.some((cell) => cell?.trim() !== "") && (
                                <tr key={rowIndex}>
                                  {row?.map((cell, cellIndex) => (
                                    <td
                                      key={cellIndex}
                                      className="border px-4 py-2"
                                    >
                                      {cell}
                                    </td>
                                  ))}
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(Add);
