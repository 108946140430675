import React from 'react';
import moment from 'moment';
import { employee } from '../../constant/Employee';
import { Autocomplete, TextField } from '@mui/material';
import Datepicker from '../../Datepicker';
import Config from '../../config';
import PropTypes from 'prop-types';

const EditEmployeeModal = ({
  fullReport,
  formik,
  documents,
  handleFileInput,
  isDisable,
  changeStatus,
  updateId,
}) => {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content w-auto">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {employee.EDIT_EMPLOYEE_DETAILS}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table className="table-borderless table-sm">
                <tbody>
                  {fullReport.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{employee.NAME}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="employeeName"
                              id="employeeName"
                              placeholder="Employee Name"
                              value={item?.employeeName}
                            />
                          </td>
                          <td>
                            <h4>{employee.WORKING_HOURS}</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="workingHours"
                              id="workingHours"
                              placeholder="Working Hours"
                              onChange={formik.handleChange}
                              value={formik.values.workingHours}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>Employee ID</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="empid"
                              id="firstNameLabel"
                              placeholder="empid"
                              onChange={formik.handleChange}
                              value={formik.values.empId || ""}
                            />
                          </td>
                          <td>
                            <h4>{employee.STATUS}</h4>
                            <Autocomplete
                              options={Config.EMPLOYEE_STATUS || []}
                              getOptionLabel={(option) => option.value || ""}
                              value={
                                Config.EMPLOYEE_STATUS.find(
                                  (option) =>
                                    option.value === formik.values.status ||
                                    item.value
                                ) || ""
                              }
                              onChange={(newValue) => {
                                formik.setFieldValue(
                                  "status",
                                  newValue?.target.textContent || ""
                                );
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{employee.EMAIL}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="firstNameLabel"
                              placeholder="email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                          </td>
                          <td>
                            <h4>{employee.DATE_OF_BIRTH}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                formik.setFieldValue("dob", newDate);
                              }}
                              value={moment(formik.values.dob).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{employee.CONTACT_NUMBER}</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="mobile1"
                              id="firstNameLabel"
                              placeholder="Contact Number"
                              inputProps={{ maxLength: 10, }}
                              onChange={formik.handleChange}
                              value={formik.values.mobile1}
                            />
                          </td>
                          <td>
                            <h4>{employee.CONTACT_NUMBER}</h4>{" "}
                            <input
                              type="number"
                              className="form-control"
                              name="mobile2"
                              id="firstNameLabel"
                              placeholder="Contact Number"
                              onChange={formik.handleChange}
                              inputProps={{ maxLength: 10, }}
                              value={formik.values.mobile2}
                            />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <h4>{employee.SHIFT_TIME}</h4>
                            <Autocomplete
                              options={Config.EMPLOYEE_SHIFT_TIME || []}
                              getOptionLabel={(option) => option.name}
                              value={
                                Config.EMPLOYEE_SHIFT_TIME.find(
                                  (option) =>
                                    option.value === formik.values.shift
                                ) || null
                              }
                              onChange={(_, newValue) => {
                                formik.setFieldValue("shift", newValue?.value);
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={formik.values.shift === undefined ? "select shift" : ""}/>
                              )}
                            />
                          </td>
                          <td>
                            <h4>{employee.EMPLOYEE_TYPE}</h4>
                            <Autocomplete
                              options={Config.EMPLOYEE_TYPE_LIST || []}
                              getOptionLabel={(option) => option.value || ""}
                              value={
                                Config.EMPLOYEE_TYPE_LIST.find(
                                  (option) =>
                                    option.value === formik.values.empType ||
                                    item.value
                                ) || ""
                              }
                              onChange={(newValue) => {
                                formik.setFieldValue(
                                  "empType",
                                  newValue?.target.textContent || ""
                                );
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={formik.values.empType === null ? "select empType" : ""}/>
                              )}
                            />
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td>
                            <h4>{employee.EMERGENCY_CONTACT_NAME}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="emergencyName"
                              id="firstNameLabel"
                              placeholder="Contact Name"
                              onChange={formik.handleChange}
                              value={formik.values.emergencyName}
                            />
                          </td>
                          <td>
                            <h4>{employee.EMERGENCY_CONTACT_NUMBER}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="emergencyMobile"
                              id="firstNameLabel"
                              placeholder="Contact Number"
                              inputProps={{ maxLength: 10, }}
                              onChange={formik.handleChange}
                              value={formik.values.emergencyMobile}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>Address</h4>{" "}
                            <textarea
                              className="form-control"
                              placeholder="Address"
                              id="validationValidTextarea1"
                              rows="3"
                              name="address"
                              onChange={formik.handleChange}
                              value={formik.values.address}
                            ></textarea>
                          </td>
                        </tr>{" "}
                        <br></br>
                        <tr>
                          <td>
                            <h4>{employee.BANK_NAME}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="bankName"
                              id="firstNameLabel"
                              placeholder="bankName"
                              onChange={formik.handleChange}
                              value={formik.values.bankName}
                            />
                          </td>
                          <td>
                            <h4>{employee.BANK_ACCOUNT_NUMBER}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="bankAccountNumber"
                              id="firstNameLabel"
                              placeholder="bankAccountNumber"
                              onChange={formik.handleChange}
                              value={formik.values.bankAccountNumber}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{employee.BANK_IFSC_CODE}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="bankIFSCCode"
                              id="firstNameLabel"
                              placeholder="bankIFSCCode"
                              onChange={formik.handleChange}
                              value={formik.values.bankIFSCCode}
                            />
                          </td>
                          <td>
                            <h4>{employee.QUALIFICATION}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="qualification"
                              id="firstNameLabel"
                              placeholder="qualification"
                              onChange={formik.handleChange}
                              value={formik.values.qualification}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{employee.DOCUMENT_NAME}</h4>{" "}
                            <Autocomplete
                              multiple
                              key={formik.values.empId}
                              id="tags-outlined"
                              options={documents}
                              defaultValue={
                                formik.values.DocumentsName.length > 0
                                  ? formik.values.DocumentsName
                                  : []
                              }
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                formik.setFieldValue("DocumentsName", newValue);
                              }}
                              size="small"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    formik.values.DocumentsName.length > 0 ||
                                      formik.values.DocumentsName == []
                                      ? ""
                                      : "Select document"
                                  }
                                />
                              )}
                            />
                          </td>
                        </tr>{" "}
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{employee.DOCUMENT_PATH}</h4>
                            <input
                              multiple
                              id="file"
                              type="file"
                              className="form-control"
                              name="DocumentsPath"
                              onChange={handleFileInput}
                            />
                            <div className="col-sm-9 d-flex align-items-center">
                              {Array.isArray(formik.values.selectedDocumentPath) &&
                                (formik.values.selectedDocumentPath.length > 0 ||
                                  formik.values.selectedDocumentPath != false ? (
                                  <ul className="me-5">
                                    {formik.values.selectedDocumentPath.map(
                                      (path) => (
                                        <li
                                          key={path.id}
                                          className="d-flex justify-content-between align-items-center"
                                        >
                                          {path.name || path}
                                          <button
                                            className="btn"
                                            type="button"
                                          >
                                            x
                                          </button>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  <div className="col-sm-9 d-flex align-items-center justify-content-center mt-3">
                                    <p>NO DOCUMENT UPLOADED</p>
                                  </div>
                                ))}
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td colSpan={2}>
                            <h4>{employee.EMPLOYEE_ROLE}</h4>
                            <input
                              type="text"
                              className="form-control"
                              name="job"
                              id="firstNameLabel"
                              placeholder="job"
                              onChange={formik.handleChange}
                              value={formik.values.job}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{employee.DATE_OF_JOINING}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                formik.setFieldValue("joining", newDate);
                              }}
                              value={moment(formik.values.joining).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </td>
                          <td>
                            <h4>{employee.DATE_OF_EXIT}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                formik.setFieldValue("exit", newDate);
                              }}
                              value={moment(formik.values.exit).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{employee.OFFERED_CTC}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="OfferCTC"
                              id="firstNameLabel"
                              placeholder="OfferCTC"
                              onChange={formik.handleChange}
                              value={formik.values.OfferCTC}
                            />
                          </td>
                          <td>
                            <h4>{employee.CONTRACT_PERIOD}</h4>{" "}
                            <input
                              type="text"
                              className="form-control"
                              name="ContractPeriod"
                              id="firstNameLabel"
                              placeholder="ContractPeriod"
                              onChange={formik.handleChange}
                              value={formik.values.ContractPeriod}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {employee.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {employee.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEmployeeModal;

EditEmployeeModal.propTypes = {
  updateId: PropTypes.number,
  changeStatus: PropTypes.func,
  isDisable: PropTypes.bool,
  formik: PropTypes.object,
  documents: PropTypes.array,
  handleFileInput: PropTypes.func,
  fullReport: PropTypes.object,
};