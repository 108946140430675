export const contact={
    FOOTER_TXT : '© Front.',
    FOOTER_YEAR : '2022 Htmlstream.',
    FAQ : 'FAQ',
    LICENSE : 'License',

    STATUS : 'Status',
    DESCRIPTION : 'Description :',
    NO_RECORD_FOUND : 'No Record Found',
    DATE_AND_TIME : 'Date and Time',
    NAME : 'Name',
    PHONE : 'Phone',
    LOCATION : 'Location',
    JOB_TITLE : 'Job Title',
    ACTION : 'Action',
    REPORT : 'Report',
    SOURCE : 'Source',
    EMAIL : 'Email',
    CURRENT_LOACTION : 'Current Loaction',
    CITY : 'City',
    INTEREST: 'Interest',
    GENDER : 'Gender',
    JOB_INFO : 'Job Info',
    QUALIFICTION : 'Qualification',
    EXPERIENCE : 'Experience',
    JOB : 'Job',
    CURRENT_CTC : 'CurrentCTC',
    EXPECTED_CTC : 'Expected CTC',
    OFFERED_CTC : 'Offered CTC',
    RESUME : 'Resume',
    REMARK : 'Remarks',
    UPDATED_BY : 'Updated By - ',
    BASIC_INFO : 'Basic Info',

    //ACTION
    CLOSE : 'Close',
    SAVE_CHANGES : 'Save Changes',
}