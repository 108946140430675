import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import * as AxiosActions from "../../../Actions/axiosActions";
import AlertBox from "../../../Alert";
import { trackEvent, EVENT_NAME } from "../../../constant/MixpanelConstant";
import { DATAADDEDSUCCESSFULLY, IS_ATLEAST_FIELD, IS_FILE, IS_INVALID_EMAIL, IS_SELECT_TEMPLATE, IS_SUBJECT, IS_VERSION, validEmailRegex } from "../../../constant/Label";
import HeaderNavbarHOC from "../../../HOC/HeaderNavbar";
import BackgroundImage from "../../../components/Common/CardImage";
import { toast } from "react-toastify";
import { FormikError } from "../../../components/Common";

function TemplateAdd() {
  const [isDisable, setIsDisable] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [getdata, setGetdata] = useState([]);

  const formik = useFormik({
    initialValues: {
      template: "",
      subject: "",
      version: "",
      source: "",
      note: "",
      file: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.template) {
        errors.template = IS_SELECT_TEMPLATE;
      }
      if (!values.source) {
        errors.source = IS_INVALID_EMAIL;
      } else if (!validEmailRegex.test(values.source)) {
        errors.source = IS_INVALID_EMAIL;
      }
      if (!values.subject) {
        errors.subject = IS_SUBJECT;
      }
      if (!values.version) {
        errors.version = IS_VERSION;
      }
      if (!values.file) {
        errors.file = IS_FILE;
      }
      return errors;
    },
    onSubmit: async (values) => {
      setIsDisable(true);
      trackEvent(EVENT_NAME.TEMPLATE_ADD, { data: "" });
      const formData = new FormData();
      formData.append("templateName", values.template);
      formData.append("templateVersion", values.version);
      formData.append("templateSubject", values.subject);
      formData.append("Note", values.note);
      formData.append("file", values.file);
      formData.append("sourceEmail", values.source);
      trackEvent(EVENT_NAME.TEMPLATE_ADD, { data: values.template });
      AxiosActions.TemplateAdd(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setOpenAlert(true);
            setIsDisable(false);
            formik.resetForm();
            formik.setFieldValue("file", null);
            document.getElementById("file").value = "";
          }
        })
        .catch((error) => {
          console.log("Error while adding template", error);
          toast.dismiss();
          toast.error(error.message);
          formik.resetForm();
          formik.setFieldValue("file", null);
          setIsDisable(false);
        });
    },
  });

  useEffect(() => {
    const getTemplateList = async () => {
      try {
        const response = await AxiosActions.TemplateList({});

        if (response.status === 200 && Array.isArray(response.data.data)) {
          setGetdata(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching template list:", error);
      }
    };

    getTemplateList();
  }, []);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    formik.resetForm();
    formik.setFieldValue("file", null);
  };

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage />
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">Add Template</h1>
            </div>

            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row mb-4">
                  <label
                    htmlFor="template"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Template-Type
                  </label>
                  <div className="col-sm-9">
                    <Autocomplete
                      options={getdata}
                      getOptionLabel={(option) => option}
                      value={formik.values.template}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            formik.values.template === "" ||
                            formik.values.template === null
                              ? "select template"
                              : ""
                          }
                        />
                      )}
                      onChange={(event, newValue) => {
                        formik.setFieldValue("template", newValue);
                      }}
                    />
                    {formik.touched.template && formik.errors.template && (
                      <FormikError label={formik.errors.template} />
                    )}
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="subject"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Source Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Source Email"
                      id="source"
                      name="source"
                      onChange={formik.handleChange}
                      value={formik.values.source}
                    />
                    {formik.touched.source && formik.errors.source && (
                      <FormikError label={formik.errors.source} />
                    )}
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="subject"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Subject
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      id="subject"
                      name="subject"
                      onChange={formik.handleChange}
                      value={formik.values.subject}
                    />
                    {formik.touched.subject && formik.errors.subject && (
                      <FormikError label={formik.errors.subject} />
                    )}
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="version"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Version
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Version"
                      id="version"
                      name="version"
                      onChange={formik.handleChange}
                      value={formik.values.version}
                    />
                    {formik.touched.version && formik.errors.version && (
                      <FormikError label={formik.errors.version} />
                    )}
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="note"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Note
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      className="form-control"
                      placeholder="Note"
                      id="note"
                      rows="2"
                      name="note"
                      onChange={formik.handleChange}
                      value={formik.values.note}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="uploads"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Uploads
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="file"
                      className="form-control"
                      id="file"
                      name="file"
                      accept=".html,.ejs,.txt"
                      value={formik.values.file?.name.value}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        const allowedExtensions = /(\.html|\.ejs|\.txt)$/i;
                        if (!allowedExtensions.exec(file.name)) {
                          toast.error("Only .html, .ejs, and .txt files are allowed");
                          event.target.value = "";
                          return;
                        }
                        formik.setFieldValue("file", file);
                      }}
                    />
                    {formik.touched.file && formik.errors.file && (
                      <FormikError label={formik.errors.file} />
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    disabled={isDisable === true}
                    type="submit"
                    className="btn btn-warning"
                  >
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(TemplateAdd);
