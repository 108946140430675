import mixpanel from "mixpanel-browser";

export const EVENT_NAME = {
  SIGN_IN: "Sign In",

  ADD_USER: "Add User Button Click",
  LIST_USER: "List User Button Click",

  APPLICANT_USER_ADD: "Add New Applicant",
  APPLICANT_LIST_FILTER: "Filter Applicant List",
  APPLICANT_CHANGE_STATUS: "Change Applicant Status",
  APPLICANT_DOWNLOAD: "Download Applicant Data",
  APPLICANT_LIST_RESET: "Reset Applicant List",
  APPLICANT_STATUS_VIEW: "View Applicant Status",
  APPLICANT_DELETE: "Delete Applicant",
  APPLICANT_VIEW: "View applicant data",
  APPLICANT_DELETE_ALL: "Delete All Applicants Entries",
  APPLICANT_CHANGE_STATUS_ALL: "Change All Applicants' Status",


  TRACKING_TOOLS_SUMMARY_FILTER: "Filter Tracking Tools List in Summary",
  TRACKING_TOOLS_SUMMARY_RESET: "Reset Tracking Tools List in Summary",

  SALARY_SUMMARY_FILTER: "Filter Salary List in Summary",
  SALARY_SUMMARY_RESET: "Reset Salary List in Summary",

  CLIENT_SUMMARY_FILTER: "Filter Client List in Summary",
  CLIENT_SUMMARY_RESET: "Reset Client List in Summary",

  EMPLOYEE_SUMMARY_FILTER: "Filter Employee List in Summary",
  EMPLOYEE_SUMMARY_RESET: "Reset Employee List in Summary",

  PRODUCTIVITY_ADD: "Add New Productivity",
  PRODUCTIVITY_LIST_FILTER: "Filter Productivity List",
  PRODUCTIVITY_EDIT: "Edit Productivity Entry",
  PRODUCTIVITY_RESET: "Reset Productivity List",
  PRODUCTIVITY_DELETE: "Delete Productivity Entry",
  PRODUCTIVITY_VIEW: "View Productivity Data",
  PRODUCTIVITY_DELETE_ALL: "Delete All Productivity Entries",

  JOB_ADD: "Add New Job",
  JOB_LIST_FILTER: "Filter Job List",
  JOB_EDIT: "Edit Job Entry",
  JOB_RESET: "Reset Job List",
  JOB_DELETE: "Delete Job Entry",
  JOB_VIEW: "View Job Data",
  JOB_DELETE_ALL: "Delete All Jobs Entries",

  EMPLOYEE_ADD: "Add New Employee",
  EMPLOYEE_LIST_FILTER: "Filter Employee List",
  EMPLOYEE_EDIT: "Edit Employee Entry",
  EMPLOYEE_RESET: "Reset Employee List",
  EMPLOYEE_DELETE: "Delete Employee Entry",
  EMPLOYEE_VIEW: "View Employee Data",
  EMPLOYEE_DELETE_ALL: "Delete All Employees Entries",
  EMPLOYEE_HISTORY_ADD: "Add History of Employee",
  EMPLOYEE_HISTORY_DELETE: "Delete History of Employee",

  CONTACTS_USER_ADD: "Add New Contact",
  CONTACTS_LIST_FILTER: "Filter Contacts List",
  CONTACTS_CHANGE_STATUS: "Change Contact Status",
  CONTACTS_DOWNLOAD: "Download Contacts Data",
  CONTACTS_LIST_RESET: "Reset Contacts List",
  CONTACTS_STATUS_VIEW: "View Contact Status",
  CONTACTS_DELETE: "Delete Contact Entry",
  CONTACTS_VIEW: "View Contact Data",
  CONTACTS_DELETE_ALL: "Delete All Contacts Entries",
  CONTACTS_CHANGE_STATUS_ALL: "Change All Contacts Status",

  QUOTES_LIST_FILTER: "Filter Quotes List",
  QUOTES_CHANGE_STATUS: "Change Quote Status",
  QUOTES_DOWNLOAD: "Download Quotes Data",
  QUOTES_LIST_RESET: "Reset Quotes List",
  QUOTES_STATUS_VIEW: "View Quote Status",
  QUOTES_DELETE: "Delete Quote",
  QUOTES_VIEW: "View Quote Data",
  QUOTES_DELETE_ALL: "Delete All Quotes Entries",
  QUOTES_CHANGE_STATUS_ALL: "Change All Quotes Status",

  USER_ADD: "Add New User",
  USER_LIST_FILTER: "Filter User List",
  USER_EDIT: "Edit User Entry",
  USER_RESET: "Reset User List",
  USER_DELETE: "Delete User Entry",
  USER_VIEW: "View User Data",
  CHANGE_PASSWORD: "Change User Password",

  CLIENT_ADD: "Add New Client",
  CLIENT_LIST_FILTER: "Filter Client List",
  CLIENT_EDIT: "Edit Client Entry",
  CLIENT_RESET: "Reset Client List",
  CLIENT_DELETE: "Delete Client Entry",
  CLIENT_VIEW: "View Client Data",
  CLIENT_DELETE_ALL: "Delete All Clients Entries",

  TRACKING_TOOLS_ADD: "Add New Tracking Tool",
  TRACKING_TOOLS_LIST_FILTER: "Filter Tracking Tools List",
  TRACKING_TOOLS_EDIT: "Edit Tracking Tool Entry",
  TRACKING_TOOLS_RESET: "Reset Tracking Tools List",
  TRACKING_TOOLS_DELETE: "Delete Tracking Tool Entry",
  TRACKING_TOOLS_VIEW: "View Tracking Tool Data",
  TRACKING_TOOLS_DELETE_ALL: "Delete All Tracking Tools Entries",

  LEAVES_ADD: "Add New Leaves",
  LEAVES_LIST_FILTER: "Filter Leaves List",
  LEAVES_EDIT: "Edit Leaves Entry",
  LEAVES_RESET: "Reset Leaves List",
  LEAVES_DELETE: "Delete Leaves Entry",
  LEAVES_VIEW: "View Leaves Data",
  LEAVES_DELETE_ALL: "Delete All Leaves Entries",

  ATTENDANCE_ADD: "Add New Attendance",
  ATTENDANCE_FILTER: "Filter Attendance List",
  ATTENDANCE_RESET: "Reset Attendance List",
  ATTENDANCE_DELETE: "Delete Attendance Entry",
  ATTENDANCE_DELETE_ALL: "Delete All Attendance Entries",

  TASK_ADD: "Add New Task",
  TASK_LIST_FILTER: "Filter Task List",
  TASK_EDIT: "Edit Task Entry",
  TASK_RESET: "Reset Task List",
  TASK_DELETE: "Delete Task Entry",
  TASK_VIEW: "View Task Data",
  TASK_DELETE_ALL: "Delete All Tasks Entries",

  EMAIL_ADD: "Add New Email",
  EMAIL_MARKETING_LIST_FILTER: "Filter Email Marketing List",
  EMAIL_MARKETING_CHART_FILTER: "Filter Email Marketing Chart",
  EMAIL_MARKETING_RESET: "Reset Email Marketing List",
  EMAIL_MARKETING_VIEW: "View Email Marketing Entry",
  EMAIL_MARKETING_DELETE: "Delete Email Marketing Entry",
  EMAIL_MARKETING_RESEND_EMAIL: "Resend Email",
  EMAIL_MARKETING_DELETE_ALL: "Delete All Email Marketing Entries",
  EMAIL_MARKETING_EDIT: "Edit Email Marketing Entry",
  EMAIL_MARKETING_NOTE: "Add Notes to Email Marketing",
  EMAIL_MARKETING_NOTE_LIST: "List of Notes for Email Marketing",
  EMAIL_MARKETING_FILTER_NOTE_LIST: "Filter Notes List for Email Marketing",
  EMAIL_MARKETING_RESET_NOTE_LIST: "Reset Notes List for Email Marketing",

  EMPLOYEE_HISTORY_VIEW: "View Employee History",

  WHATSAPP_ADD: "Add New WhatsApp",
  WHATSAPP_MARKETING_LIST_FILTER: "Filter WhatsApp Marketing List",
  WHATSAPP_MARKETING_EDIT: "Edit WhatsApp Marketing Entry",
  WHATSAPP_MARKETING_RESET: "Reset WhatsApp Marketing List",
  WHATSAPP_MARKETING_DELETE: "Delete WhatsApp Marketing Entry",
  WHATSAPP_MARKETING_VIEW: "View WhatsApp Marketing Data Entry",
  WHATSAPP_MARKETING_DELETE_ALL: "Delete All WhatsApp Marketing Entries",
  TEMPLATE_ADD: "Add New Template",
  TEMPLATE_LIST_FILTER: "Filter Template List",
  TEMPLATE_EDIT: "Edit Template Entry",
  TEMPLATE_RESET: "Reset Template List",
  TEMPLATE_DELETE: "Delete Template Entry",
  TEMPLATE_VIEW: "View Template Data",

  EVENT_ADD: "Add Event",
  EVENT_DELETE: "Delete Event Entry",
  EVENT_VIEW: "View Event Data",
  EVENT_EDIT: "Edit Event Entry",
  EVENT_LIST_FILTER: "Filter Event List",
  EVENT_RESET: "Reset Event List",
  EVENT_DELETE_ALL: "Delete All Events Entries",

  DAILY_ADD: "Daily Add Event",
};

export function trackEvent(eventName, properties) {
  // to track event and store it in mixpanel e.g. button click event
  // console.log("mix eventName: ", eventName, "properties: ", properties)
  mixpanel.track(eventName, properties);
}

export function trackNavigate(query, eventName, properties) {
  // to track navigation and store it in mixpanel
  // console.log("mix query: ", query, "eventName: ", eventName, "properties: ", properties)

  mixpanel.track_links(query, eventName, properties);
}

export function registerProperty(name, email, isAdmin) {
  mixpanel.register({
    Name: name,
    Email: email,
    isAdmin: isAdmin,
  });
}

export function setupIdentity(id, name, email, isAdmin) {
  mixpanel.identify(id);
  if (name && email)
    mixpanel.people.set({
      name: name,
      email: email,
      $email: email,
      isAdmin: isAdmin,
    });
}
