import { toast } from "react-toastify";
import { store, fetch } from "../../src/store/httpUtil";

export const Login = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("user/authenticate", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Login Error", error);
        reject(error);
      });
  });
};

//Token Issue
export const ApplicantsAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicants/add", requestData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log("ApplicantsAdd Error", error);
        reject(error);
      });
  });
};

// Token Issue
export const ImportCSV = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicants/importcsv", requestData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log("ImportCSV Error", error);
        reject(error);
      });
  });
};

export const ApplicantFilter = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicants/filter", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ApplicantFilter Error", error);
        reject(error);
      });
  });
};

export const ApplicantNotesList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicant/notes/fetchrecord", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ApplicantNotesList Error", error);
        reject(error);
      });
  });
};
export const openResume = (id) => {
  return new Promise(async (resolve, reject) => {
    fetch(`user/retrieve/file/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("openResume", error);
        reject(error);
      });
  });
};
export const DeleteApplicant = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicant/deleterecord", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteApplicant Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteApplicants = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicants/deletemany", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteApplicants Error", error);
        reject(error);
      });
  });
};

// Token Issue
export const MultipleApplicantStatus = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicants/statusmany", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleApplicantStatus Error", error);
        reject(error);
      });
  });
};

export const ApplicantsList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicants/pagination", requestData)
      .then((res) => {
        console.log("success::", res.data);
        resolve(res);
      })
      .catch((error) => {
        console.log("ApplicantsList Error", error);
        reject(error);
      });
  });
};

export const UserList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("user/list", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("UserList Error", error);
        reject(error);
      });
  });
};

export const EditUser = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("user/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditUser Error", error);
        reject(error);
      });
  });
};

export const DeleteUser = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("user/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteUser Error", error);
        reject(error);
      });
  });
};

//Due to auth0 testing pending
export const UserChangePassword = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("user/changePassword", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("UserChangePassword Error", error);
        reject(error);
      });
  });
};

export const EmployeeList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("employee/active")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeList Error", error);
        reject(error);
      });
  });
};

export const EmailMarketingNameList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("email/marketing")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmailMarketingNameList Error", error);
        reject(error);
      });
  });
};

export const CountryList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("api/v1/timezone/list")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Country List Error", error);
        reject(error);
      });
  });
};

export const VersionList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("api/v1/email/template/active")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("version List Error", error);
        reject(error);
      });
  });
};

// User Add
export const UserAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("user/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("UserAdd Error", error);
        reject(error);
      });
  });
};

export const AttendanceAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("attendance/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("AttendanceAdd Error", error);
        reject(error);
      });
  });
};

export const AttendanceList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("attendance/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("AttendanceList Error", error);
        reject(error);
      });
  });
};

export const DeleteAttendance = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("attendance/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteAttendance Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteAttendance = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("attendance/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteAttendance Error", error);
        reject(error);
      });
  });
};

export const ClientAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("client/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ClientAdd Error", error);
        reject(error);
      });
  });
};

export const ClientList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("client/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ClientList Error", error);
        reject(error);
      });
  });
};

export const EditClient = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("client/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditClient Error", error);
        reject(error);
      });
  });
};

export const DeleteClient = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("client/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteClient Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteClient = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("client/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteClient Error", error);
        reject(error);
      });
  });
};

export const TrackingToolAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tracking/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TrackingToolAdd Error", error);
        reject(error);
      });
  });
};

export const SalaryAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("SalaryAdd Error", error);
        reject(error);
      });
  });
};

export const ExpensesAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/expense/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ExpensesAdd Error", error);
        reject(error);
      });
  });
};

export const TrackingToolList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tracking/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TrackingToolList Error", error);
        reject(error);
      });
  });
};

export const SalaryList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("SalaryList Error", error);
        reject(error);
      });
  });
};
export const SalaryChartList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/monthwise/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("SalaryList Error", error);
        reject(error);
      });
  });
};

export const EmailMarketingList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("email/marketing/list", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmailMarketingList Error", error);
        reject(error);
      });
  });
};

export const EmailCountList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/email/marketing/count", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmailCountList Error", error);
        reject(error);
      });
  });
};

export const EmailMarketingNotesList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("marketing/notes/list", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmailMarketingNotesList Error", error);
        reject(error);
      });
  });
};

export const WhatsAppMarketingList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("whatsapp/marketing/list", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("WhatsAppMarketingList Error", error);
        reject(error);
      });
  });
};

export const ExpensesList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("SalaryList Error", error);
        reject(error);
      });
  });
};
export const ExpensesListChart = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/total-expense/monthwise/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("SalaryList Error", error);
        reject(error);
      });
  });
};
export const ExpensesMiscListChart = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/misc-expense/monthwise/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("SalaryList Error", error);
        reject(error);
      });
  });
};

export const EditTrackingTool = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tracking/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditTrackingTool Error", error);
        reject(error);
      });
  });
};

export const EditSalary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditSalary Error", error);
        reject(error);
      });
  });
};

export const EditEmailMarketing = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("email/marketing/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditEmailMarketing Error", error);
        reject(error);
      });
  });
};

export const WhatsAppEmailMarketing = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("whatsapp/marketing/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("WhatsAppEmailMarketing Error", error);
        reject(error);
      });
  });
};

export const EditExpenses = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/expense/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditExpenses Error", error);
        reject(error);
      });
  });
};

export const EditAttendence = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("attendance/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditAttendence Error", error);
        reject(error);
      });
  });
};

export const DeleteTrackingTool = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tracking/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteTrackingTool Error", error);
        reject(error);
      });
  });
};

export const DeleteSalary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteSalary Error", error);
        reject(error);
      });
  });
};

export const DeleteEmailMarketing = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("email/marketing/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteEmailMarketing Error", error);
        reject(error);
      });
  });
};

export const ResendMultiEmailMarketing = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/email/select/resend", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Multi Resend Email Marketing Error", error);
        reject(error);
      });
  });
};

export const UpdateMultiEmailMarketing = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("/api/v1/email/marketing/multiple/update", requestData)
      .then((res) => {
        console.log("API Response:", res.data);
        resolve(res);
      })
      .catch((error) => {
        console.log("Multi Resend Email Marketing Error", error);
        reject(error);
      });
  });
};

export const DeleteWhatsAppMarketing = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("whatsapp/marketing/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteWhatsAppMarketing Error", error);
        reject(error);
      });
  });
};

export const ResendEmailMarketing = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/email/send", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteSalary Error", error);
        reject(error);
      });
  });
};

export const DeleteExpenses = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteExpenses Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteTrackingTool = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tracking/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteTrackingTool Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteSalary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteSalary Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteExpenses = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteExpenses Error", error);
        reject(error);
      });
  });
};

export const LeaveAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("leaves/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("LeaveAdd Error", error);
        reject(error);
      });
  });
};

export const LeaveList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("leaves/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("LeaveList Error", error);
        reject(error);
      });
  });
};

//Edit Not Working
export const EditLeave = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("leaves/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditLeave Error", error);
        reject(error);
      });
  });
};

export const DeleteLeave = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("leaves/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteLeave Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteLeave = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("leaves/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteLeave Error", error);
        reject(error);
      });
  });
};

//API Not Working
export const HistoryList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("history/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("HistoryList Error", error);
        reject(error);
      });
  });
};

//Working
export const ContactList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/contact/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ContactList Error", error);
        reject(error);
      });
  });
};

//Working
export const JobList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("api/v1/job")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("JobList Error", error);
        reject(error);
      });
  });
};

export const ContactFilter = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/contact/filter", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ContactFilter Error", error);
        reject(error);
      });
  });
};

//Token Issue
export const ApplicantStatus = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/applicant/status", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ApplicantStatus Error", error);
        reject(error);
      });
  });
};

//Working
export const ContactNotesList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/contact/notes/fetchrecord", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ContactNotesList Error", error);
        reject(error);
      });
  });
};

export const ContactStatus = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/contact/status", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ContactStatus Error", error);
        reject(error);
      });
  });
};

//Working
export const DeleteContact = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/contact/deleterecord", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteContact Error", error);
        reject(error);
      });
  });
};

//Working
export const MultipleDeleteContact = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/contact/deletemany", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteContact Error", error);
        reject(error);
      });
  });
};

//Token Issue
export const MultipleContactStatus = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/contact/statusmany", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleContactStatus Error", error);
        reject(error);
      });
  });
};

//Working
export const QuoteList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/quote/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("QuoteList Error", error);
        reject(error);
      });
  });
};

export const QuoteFilter = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/quote/filter", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("QuoteFilter Error", error);
        reject(error);
      });
  });
};

//Token Issue
export const QuoteStatus = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/quote/status", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("QuoteStatus Error", error);
        reject(error);
      });
  });
};

//Working
export const QuoteNotesList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/quote/notes/fetchrecord", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("QuoteNotesList Error", error);
        reject(error);
      });
  });
};

//Working
export const DeleteQuote = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/quote/deleterecord", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteQuote Error", error);
        reject(error);
      });
  });
};

//Working
export const MultipleDeleteQuote = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/quote/deletemany", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteQuote Error", error);
        reject(error);
      });
  });
};

//Token Issue
export const MultipleQuoteStatus = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/quote/statusmany", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleQuoteStatus Error", error);
        reject(error);
      });
  });
};

export const EmployeeAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeAdd Error", error);
        reject(error);
      });
  });
};

export const EmployeeAll = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeAll Error", error);
        reject(error);
      });
  });
};

export const EditEmployee = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditEmployee Error", error);
        reject(error);
      });
  });
};

export const DeleteEmployee = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteEmplyee Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteEmployee = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteEmployee Error", error);
        reject(error);
      });
  });
};

export const EmployeeHistoryAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/employee/history/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeHistoryAdd Error", error);
        reject(error);
      });
  });
};

export const EmployeeHistoryList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/employee/history/list", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeHistoryList Error", error);
        reject(error);
      });
  });
};

export const EditEmployeeHistory = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/employee/history/update", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditEmployeeHistory Error", error);
        reject(error);
      });
  });
};

export const DeleteEmployeeHistory = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/employee/history/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteEmployeeHistory Error", error);
        reject(error);
      });
  });
};


export const TrackingList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("tracking/list")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TrackingList Error", error);
        reject(error);
      });
  });
};

export const ProdutivityClientList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("client/active")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ProdutivityClientList Error", error);
        reject(error);
      });
  });
};

export const ProductivityAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/productivity/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ProductivityAdd Error", error);
        reject(error);
      });
  });
};

export const ProductivityList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/productivity/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ProductivityList Error", error);
        reject(error);
      });
  });
};

export const EditProductivity = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/productivity/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditProductivity Error", error);
        reject(error);
      });
  });
};
export const dayWiseProductivityCount = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store(
      "api/v1/employee/chart/daywise/productivity/status/hours/count",
      requestData
    )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("day  Productivity Count Error: ", error);
        reject(error);
      });
  });
};

export const productivityStatusCount = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/employee/chart/productivity/status/hours/count", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Productivity Status Count Error: ", error);
        reject(error);
      });
  });
};

export const addnotes = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("productivity/notes/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("addnotes Error", error);
        reject(error);
      });
  });
};

export const viewnote = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("productivity/notes/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("viewnote Error", error);
        reject(error);
      });
  });
};

export const DeleteProductivity = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/productivity/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteProductivity Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteProductivity = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/productivity/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteProductivity Error", error);
        reject(error);
      });
  });
};

// Working
export const EmployeeSummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("productivity/employee-summary/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeSummary Error", error);
        reject(error);
      });
  });
};

export const EmployeeSummaryCount = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/summary/count", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeSummaryCount Error", error);
        reject(error);
      });
  });
};

export const ClientSummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("client/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ClientSummary Error", error);
        reject(error);
      });
  });
};

export const ClientSummaryCount = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("client/summary/count", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ClientSummaryCount Error", error);
        reject(error);
      });
  });
};

export const TrackingToolSummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tracking/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TrackingToolSummary Error", error);
        reject(error);
      });
  });
};

export const ExpensesSummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/salary-expense/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ExpensesSummary Error", error);
        reject(error);
      });
  });
};
export const annualSalarySummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/annual-salary-expense/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ExpensesSummary Error", error);
        reject(error);
      });
  });
};

export const ExpensesMonthSummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("employee/total-expense/monthwise/summary", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ExpensesMonthSummary Error", error);
        reject(error);
      });
  });
};

export const TrackingToolSummaryCount = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tracking/summary/count", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TrackingToolSummaryCount Error", error);
        reject(error);
      });
  });
};

export const JobAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("jobs/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("JobAdd Error", error);
        reject(error);
      });
  });
};

export const TaskAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tasks/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TaskAdd Error", error);
        reject(error);
      });
  });
};

export const EmailMarketingAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("email/marketing/add/bulk", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmailMarketingAdd Error", error);
        toast.dismiss();
        toast.error(error.message);
        reject(error);
      });
  });
};

export const EmailMarketingVersion = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/email/template/active", requestData)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        console.log("EmailMarketingVersion Error", error);
        toast.dismiss();
        toast.error(error.message);
        reject(error);
      });
  });
};

export const EmailMarketingAddNote = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("marketing/notes/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmailMarketingAddNotes Error", error);
        reject(error);
      });
  });
};
export const WhatsAppMarketingAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("whatsapp/marketing/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("WhatsAppMarketingAdd Error", error);
        reject(error);
      });
  });
};

export const AllJobList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("jobs/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("AllJobList Error", error);
        reject(error);
      });
  });
};

// Testing Pending due to map not working at frontend
export const EditJob = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("jobs/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditJob Error", error);
        reject(error);
      });
  });
};

// API  Working But we update only status
export const DeleteJob = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("jobs/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteJob Error", error);
        reject(error);
      });
  });
};

// API  Working But we update only status
export const MultipleDeleteJob = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("jobs/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteJob Error", error);
        reject(error);
      });
  });
};

export const TrackingDetailsAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TrackingDetailsAdd Error", error);
        reject(error);
      });
  });
};

export const EditTrackingDetails = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time_and_attendance/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditTrackingDetails Error", error);
        reject(error);
      });
  });
};

export const WeekTotal = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/weeklycount", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("WeekTotal Error", error);
        reject(error);
      });
  });
};

export const TodayTotal = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/todaycount", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TodayTotal Error", error);
        reject(error);
      });
  });
};

export const TaskSummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/taskdetails", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("TaskSummary Error", error);
        reject(error);
      });
  });
};

export const CompletedTaskSummary = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/completetaskdetails", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("CompletedTaskSummary Error", error);
        reject(error);
      });
  });
};

export const EmployeeTask = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tasks/employee", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EmployeeTask Error", error);
        reject(error);
      });
  });
};

export const UpdateTaskStatus = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tasks/changestatus", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("UpdateTaskStatus Error", error);
        reject(error);
      });
  });
};

export const UpdateTaskToDefault = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("tasks/changetodefault", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("UpdateTaskToDefault Error", error);
        reject(error);
      });
  });
};

export const ReminderAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("remainder/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ReminderAdd Error", error);
        reject(error);
      });
  });
};

export const ReminderList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("remainder/pagination", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("ReminderList Error", error);
        reject(error);
      });
  });
};

export const EditReminder = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("remainder/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("EditReminder Error", error);
        reject(error);
      });
  });
};

export const DeleteReminder = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("remainder/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("DeleteReminder Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteReminder = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("remainder/delete/all", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteReminder Error", error);
        reject(error);
      });
  });
};

export const TemplateList = () => {
  return new Promise(async (resolve, reject) => {
    fetch("api/v1/email/marketing/template/list")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Template List Error", error);
        reject(error);
      });
  });
};

export const screenshotDelete = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("screenshot delete", error);
        reject(error);
      });
  });
};

export const TrackingDetails = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/monthly/timesheet", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("tracking details Error", error);
        reject(error);
      });
  });
};

export const TrackingHourDetails = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/monthly/count", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("tracking hour details Error", error);
        reject(error);
      });
  });
};

export const EventAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/activity/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Event Add Error", error);
        reject(error);
      });
  });
};

export const EventList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/activity/list", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Event List Error", error);
        reject(error);
      });
  });
};

export const EditEvent = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/activity/edit", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Edit Event Error", error);
        reject(error);
      });
  });
};

export const MultipleDeleteEvent = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/activity/multiple/delete", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("MultipleDeleteEvent Error", error);
        reject(error);
      });
  });
};


export const syncTimeTracking = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/sync/tracking", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Occure error when sync time", error);
        reject(error);
      });
  });
};

export const DailyAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("time/attendance/manual/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Daily Add Error", error);
        reject(error);
      });
  });
};

export const TemplateAdd = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/email/template/add", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("Template Add Error", error);
        reject(error);
      });
  });
};


export const EmployeeChartList = (requestData) => {
  return new Promise(async (resolve, reject) => {
    store("api/v1/email/marketing/count/chart", requestData)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log("An error occure employee chart list Error", error);
        reject(error);
      });
  });
};