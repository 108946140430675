import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../../config";
import { toast } from "react-toastify";

function Edit({ formik, isDisable, templateType , onSubmit }) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              Edit Template
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <input type="hidden" name="id" value={formik.values.id} />
              <div className="row mb-4">
                <label
                  htmlFor="template"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Template-Type
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    name="templateName"
                    options={templateType}
                    getOptionLabel={(option) => option}
                    value={formik.values.templateName}
                    size="small"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("templateName", newValue);
                    }}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="version"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Version
                </label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="templateVersion"
                    id="templateVersion"
                    name="templateVersion"
                    onChange={formik.handleChange}
                    value={formik.values.templateVersion}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="version"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Source Email
                </label>
                <div className="col-sm-9">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Source Email"
                    id="source"
                    name="source"
                    onChange={formik.handleChange}
                    value={formik.values.source || ""}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="templateSubject"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Subject
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="templateSubject"
                    id="templateSubject"
                    name="templateSubject"
                    onChange={formik.handleChange}
                    value={formik.values.templateSubject}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="note "
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Note
                </label>
                <div className="col-sm-9">
                  <textarea
                    className="form-control"
                    placeholder="Note "
                    id="note"
                    rows="2"
                    name="note"
                    onChange={formik.handleChange}
                    value={formik.values.note}
                  ></textarea>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="status"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Status
                </label>
                <div className="col-sm-9">
                  <Autocomplete
                    name="status"
                    options={Config.USER_STATUS}
                    getOptionLabel={(option) => option.name}
                    value={
                      Config.USER_STATUS.find(
                        (item) => item.name === formik.values.status
                      ) || null
                    }
                    size="small"
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("status", newValue?.value);
                    }}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <label
                  htmlFor="uploads"
                  className="col-sm-3 col-form-label form-label"
                  style={{ fontSize: 17 }}
                >
                  Uploads
                </label>
                <div className="col-sm-9">
                  <input
                    type="file"
                    className="form-control"
                    id="uploads"
                    accept=".html,.ejs,.txt"
                    name="uploads"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      const allowedExtensions = /(\.html|\.ejs|\.txt)$/i;
                      if (!allowedExtensions.exec(file.name)) {
                        toast.error(
                          "Only .html, .ejs, and .txt files are allowed"
                        );
                        event.target.value = "";
                        return;
                      }
                      formik.setFieldValue("file", file);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  disabled={isDisable === true}
                  type="submit"
                  data-bs-dismiss='modal'
                  className="btn btn-warning"
                  onClick={onSubmit}
                >
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

Edit.propTypes = {
  formik: PropTypes.object.isRequired,
  isDisable: PropTypes.bool.isRequired,
  templateType: PropTypes.array.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Edit;
