import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import Config from "../../config";
import { CLOSE, SAVECHANGES, EMPLOYEE_HISTORY, EMPLOYEE_TYPE, PERIOD, CURRENT_SALARY, INCREMENT, FROM_DATE, TO_DATE, ROLE } from "../../constant/Label";

function History({ errMsg, addHistory, handleHistoryChange, isDisable, inputHistoryValue }) {
  return (
    <div
      className="modal fade"
      id="historyPage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {EMPLOYEE_HISTORY}
            </h5>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* Status Modal */}
            <div className="modal-content">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <label className="row-heading">{EMPLOYEE_TYPE} :</label>
                    </td>
                    <td>
                      {" "}
                      <Autocomplete
                        name="empType"
                        options={Config.EMPLOYEE_TIME || []}
                        getOptionLabel={(option) => option.name || ""}
                        value={
                          Config.EMPLOYEE_TIME.find((item) => item.value === inputHistoryValue?.empType) || null
                        }
                        onChange={(event, newValue) => handleHistoryChange(null, newValue)}
                        size="small"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            autoComplete="off"
                            placeholder="Select Employee Type"
                          />
                        )}
                      />

                    </td>
                  </tr>
                  <br />
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      <label className="row-heading">{PERIOD} :</label>
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control is-valid"
                        placeholder="Enter period"
                        id="validationValidInput1"
                        name="period"
                        onChange={handleHistoryChange}
                        value={inputHistoryValue?.period}
                      />
                      {errMsg.period !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.period}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      <label className="row-heading">{CURRENT_SALARY} :</label>
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control is-valid"
                        placeholder="Enter current salary"
                        id="validationValidInput1"
                        rows="4"
                        name="currentSalary"
                        onChange={handleHistoryChange}
                        value={inputHistoryValue?.currentSalary}
                      />
                      {errMsg.currentSalary !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.currentSalary}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      <label className="row-heading">{INCREMENT} :</label>
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control is-valid"
                        placeholder="Enter increment amount"
                        name="increment"
                        onChange={handleHistoryChange}
                        value={inputHistoryValue?.increment || ""}
                      />
                      {errMsg.increment !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.increment}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      <label className="row-heading">{FROM_DATE} :</label>
                    </td>
                    <td>
                      <input
                        type="date"
                        className="form-control"
                        name="fromDate"
                        value={inputHistoryValue?.fromDate || ""}
                        onChange={(event) => handleHistoryChange(event)}
                      />

                      {errMsg.fromDate !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.fromDate}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      <label className="row-heading">{TO_DATE} :</label>
                    </td>
                    <td>
                      <input
                        type="date"
                        className="form-control"
                        name="toDate"
                        value={inputHistoryValue?.toDate || ""}
                        onChange={(event) => handleHistoryChange(event)}
                      />

                      {errMsg.toDate !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.toDate}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <br />
                  <tr>
                    {" "}
                    <td>
                      {" "}
                      <label className="row-heading">{ROLE} :</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control is-valid"
                        placeholder="Enter role of employee"
                        name="role"
                        onChange={handleHistoryChange}
                        value={inputHistoryValue?.role || ""}
                      />
                      {errMsg.role !== "" ? (
                        <label
                          className=" form-label"
                          style={{ fontSize: 15, color: "red" }}
                        >
                          {errMsg.role}
                        </label>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss={inputHistoryValue?.message === "" ? "" : "modal"}
              onClick={(event) => {
                addHistory(event);
              }}
            >
              {SAVECHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
