import React from "react";
import moment from "moment";
import Config from "../../config";
import { useHasPermission } from "../../components/Common/Permission";
import Checkbox from "@mui/material/Checkbox";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  Loader,
  applicants,
  selectedinfo,
  getClientStatus,
  handleViewReportClick,
  handleDeleteClick,
  handleSalaryPdfDownload,
  adminData,
}) {

  const canDelete = useHasPermission(
    adminData.role,
    "salary",
    Config.ACTION.delete
  );
  const canEdit = useHasPermission(
    adminData.role,
    "salary",
    Config.ACTION.edit
  );
  const canView = useHasPermission(
    adminData.role,
    "salary",
    Config.ACTION.detail
  );
  const canDownload = useHasPermission(
    adminData.role,
    "salary",
    Config.ACTION.download
  );
  const hasPermission = canDelete || canEdit || canView || canDownload;

  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>

          <th>Employee Name</th>
          <th>Paid Amount</th>
          <th>Date</th>
          <th>Month</th>
          <th>Year</th>

          <th>Status</th>
          {hasPermission && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            </td>
          </tr>
        ) : (
          <>
            {" "}
            {applicants.length === 0 && (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>
                  {" "}
                  <h3>No record found</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={index}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>
                  <td className="table-column-ps-1">
                    <h5 className="text-inherit mb-0">
                      {app?.empid?.employeeName ?? '-'}
                    </h5>
                  </td>
                  <td>{app.netAmount}</td>{" "}
                  <td>{moment(app.date).format("DD-MM-YYYY")}</td>{" "}
                  <td>{app.month}</td> <td>{app.year}</td>{" "}
                  <td>{getClientStatus(app.status, "salary")}</td>
                  {hasPermission &&
                  <td>
                    {canEdit &&
                    <button
                      title="Edit salary"
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>}
                    &nbsp;
                    {canDelete &&
                    <button
                      title="Delete salary"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) => handleDeleteClick(event, app._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>}
                    &nbsp;
                    {canView && 
                    <button
                      title="View salary"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>}
                    &nbsp;
                    {canDownload &&<button
                      title="Download salary report"
                      className="icon icon-success icon-circle"
                      onClick={(event) => handleSalaryPdfDownload(app._id)}
                    >
                      <i className="bi-download"></i>
                    </button>}{" "}
                  </td>}
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;
