import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";
import { DateTimePicker } from "../../Datepicker";
import { CLOSE, FILTER_LABEL, SAVECHANGES } from "../../constant/Label";
import { addDaily } from "../../constant/Daily";

function CustomModal({
  employeeList,
  employee,
  setEmployee,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  isDisable,
  handleSubmit,
  getclient,
  client,
  setClient,
  gettask,
  task,
  setTask,
  handleCloseAlert
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLongAdd"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {addDaily.TITLE}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseAlert}
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h4>{addDaily.EMPLOYEE}</h4>
                      <Autocomplete
                        options={employeeList}
                        getOptionLabel={(option) => option.employeeName}
                        value={employee || null}
                        onChange={(event, newValue) => setEmployee(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={employee === null ? "select employee" : ""}
                          />
                        )}
                        size="small"
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{addDaily.PROJECT}</h4>
                      <Autocomplete
                        options={[
                          { value: "", label: FILTER_LABEL.SELECT_PROJECT },
                          ...getclient.map((item) => ({
                            value: item._id,
                            label: item.clientName,
                          })),
                        ]}
                        getOptionLabel={(option) => option.label}
                        value={
                          client === ""
                            ? { value: "", label: FILTER_LABEL.SELECT_PROJECT }
                            : getclient
                                .filter((item) => item._id === client)
                                .map((item) => ({
                                  value: item._id,
                                  label: item.clientName,
                                }))[0]
                        }
                        onChange={(event, newValue) => {
                          setClient(newValue ? newValue.value : "");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        size="small"
                      />
                    </td>
                  </tr>
                  <br></br>
                  <tr>
                    <td>
                      <h4>{addDaily.TASK}</h4>
                      <Autocomplete
                        options={[
                          { value: "", label: FILTER_LABEL.SELECT_TASK },
                          ...gettask
                            .filter((item) => item.empid._id === employee?._id)
                            .map((item) => ({
                              value: item._id,
                              label: item.summary,
                            })),
                        ]}
                        getOptionLabel={(option) => option.label}
                        value={
                          task === "" || task === null
                            ? { value: "", label: FILTER_LABEL.SELECT_TASK }
                            : gettask
                                .filter((item) => item._id === task)
                                .map((item) => ({
                                  value: item._id,
                                  label: item.summary,
                                }))[0]
                        }
                        onChange={(event, newValue) => {
                          setTask(newValue ? newValue.value : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                          />
                        )}
                        size="small"
                      />
                    </td>
                  </tr>
                  <br></br>
                  <tr>
                    <td>
                      <h4>{addDaily.FROM_DATE}</h4>
                      <DateTimePicker
                        value={fromDate}
                        onChange={(newDate) => setFromDate(newDate)}
                        label="From Date"
                      />
                    </td>
                  </tr>

                  <br></br>
                  <tr>
                    <td>
                      <h4>{addDaily.TO_DATE}</h4>
                      <DateTimePicker
                        value={toDate}
                        onChange={(newDate) => setToDate(newDate)}
                        label="To Date"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
              onClick={handleCloseAlert}
            >
              {CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleSubmit}
            >
              {SAVECHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
CustomModal.propTypes = {
  employeeList: PropTypes.array.isRequired,
  employee: PropTypes.object,
  setEmployee: PropTypes.func.isRequired,
  fromDate: PropTypes.object.isRequired,
  setFromDate: PropTypes.func.isRequired,
  toDate: PropTypes.object.isRequired,
  setToDate: PropTypes.func.isRequired,
  isDisable: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getclient: PropTypes.array.isRequired,
  client: PropTypes.string.isRequired,
  setClient: PropTypes.func.isRequired,
  gettask: PropTypes.array.isRequired,
  task: PropTypes.string.isRequired,
  setTask: PropTypes.func.isRequired,
  handleCloseAlert: PropTypes.func.isRequired,
};

export default CustomModal;
