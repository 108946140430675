import React, { useState, useMemo, useEffect, useRef } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import * as AxiosActions from "../../Actions/axiosActions";
import {
  EMAIL_CSV_IMPORT,
  DOWNLOAD_SAMPLE_CSV,
  FILES,
  IMPORT,
  IS_CSV,
  IS_SELECT_CSV,
  TEMPLATE,
  UPLOAD,
  UPLOAD_MESSAGE_CSV,
  CSV_SAVED,
  IS_SELECT_TEMPLATE,
  SAMPLE_EMAIL_CSV_HEADERS,
  SAMPLE_EMAIL_CSV_DATA,
  FREQUENCY,
  IS_FREQUENCY,
  SELECTTEMPLATE,
  SELECT_FREQUENCY,
  SELECTSTATUS,
  STATUS,
  IS_STATUS,
  SCHEDULE_DATE_TIME,
  END_DATE_TIME,
  IS_SCHEDULE_DATE_TIME,
  VERSION,
  SELECT_VERSION,
  IS_VERSION,
  IS_COUNTRY,
  SELECT_COUNTRY,
  COUNTRY,
} from "../../constant/Label";
import { FormikError } from "../../components/Common";
import {
  acceptStyle,
  baseStyle,
  customStyle,
  focusedStyle,
  rejectStyle,
} from "../../components/Common/Style";
import {
  FileDownloadIcons,
  shapeBottomIcons,
} from "../../components/Common/Icons";
import Config from "../../config";
import { emailmarketing } from "../../constant/EmailMarketing";
import BackgroundImage from "../../components/Common/CardImage";
import moment from "moment";

/**
 * @desc Functional component representing the CSV Import screen.
 *
 * @component
 * @param {Object} props - Component props
 */

function Add() {
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [getdata, setgetdata] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const scheduleInputRef = useRef(null);
  const endInputRef = useRef(null);

  /**
   * @desc Function to generate CSV content for download.
   * @returns {string} Encoded URI of CSV content.
   */
  const generateCSVContent = () => {
    const headers = SAMPLE_EMAIL_CSV_HEADERS;
    const sampleData = [SAMPLE_EMAIL_CSV_DATA];

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\n";
    sampleData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    return encodeURI(csvContent);
  };

  /**
   * @desc Function to download the sample CSV file.
   */
  const downloadCSV = () => {
    const csvContent = generateCSVContent();
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      multiple: false,
      accept: { "text/csv": [".csv"] },
      onDrop: (acceptedFiles, fileRejections) => {
        if (
          acceptedFiles &&
          acceptedFiles[0] &&
          acceptedFiles[0].type === "text/csv"
        ) {
          const file = acceptedFiles[0];
          if (file) {
            Papa.parse(file, {
              complete: (result) => {
                if (result.data) {
                  const trimmedData = result.data.map((row) =>
                    row.map((cell) => cell.trim())
                  );

                  setCsvData(trimmedData);
                }
              },
              header: false,
            });
            formik.setFieldValue("csvFile", file); // Set the selected CSV file in formik values
          }
        } else {
          setCsvData([]);
          formik.setFieldValue("csvFile", null);
          formik.setFieldError("csvFile", IS_SELECT_CSV);
        }
      },
    });

  // version list api
  const getVersionList = () => {
    AxiosActions.VersionList({})
      .then((response) => {
        if (response.status === 200) {
          setVersionList(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get version list", error);
      });
  };

  const getCountryList = () => {
    AxiosActions.CountryList()
    .then((response) => {
      if (response.status == 200) {
        setCountryList(response.data.data);
      }
    })
    .catch((error) => {
      console.log("Error in get country list", error);
    });
  };
  
  useEffect(() => {
    getVersionList();
    getCountryList();
  }, []);

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      flexDirection: undefined,
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    const getTemplateList = async () => {
      try {
        const response = await AxiosActions.TemplateList({});

        if (response.status === 200 && Array.isArray(response.data.data)) {
          setgetdata(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching template list:", error);
      }
    };

    getTemplateList();
  }, []);

  const formik = useFormik({
    initialValues: {
      temp_status: "",
      frequency: "",
      csvFile: null,
      status: "",
      version: "",
      country: "",
      schedule: "",
      endtime: "",
    },
    validate: (values) => {
      const errors = {};
      if (formik.submitCount >= 0 && values.temp_status === "" && values.temp_status === SELECTTEMPLATE) {
        errors.temp_status = IS_SELECT_TEMPLATE;
      }
      if (formik.submitCount >= 0 && values.version === "" && values.version === SELECT_VERSION) {
        errors.version = IS_VERSION;
      }
      if (formik.submitCount >= 0 && values.country === "" && values.version === SELECT_COUNTRY) {
        errors.country = IS_COUNTRY;
      }
      if (formik.submitCount >= 0 && values.frequency === "" && values.frequency === SELECT_FREQUENCY) {
        errors.frequency = IS_FREQUENCY;
      }
      if (formik.submitCount >= 0 && values.status === "" && values.status === SELECTSTATUS) {
        errors.status = IS_STATUS;
      }
      if (formik.submitCount >= 0 && values.csvFile === null) {
        errors.csvFile = IS_CSV;
      }
      if (formik.submitCount >= 0 && values.schedule === "") {
        errors.schedule = IS_SCHEDULE_DATE_TIME;
      }
      if (formik.submitCount >= 0 && values.endtime === "") {
        errors.endtime = IS_SCHEDULE_DATE_TIME;
      }
      return errors;
    },
    /**
     * @desc Function to handle form submission.
     * @param {Object} values - Form values.
     */
    onSubmit: async (values) => {
      formik.resetForm();
      setIsLoading(true);
      const formData = new FormData();
      if (values.csvFile) {
        formData.append("csvFile", values.csvFile);
      }
      const params = new URLSearchParams({
        scheduledDateAndTime: moment(values.schedule).toISOString(),
        endDate: moment(values.endtime).toISOString(),
        templateVersion: values.version,
        frequency: values.frequency,
        country: values.country,
      });

      try {
        if (values.csvFile) {
          // Create a Blob from the file
          const fileBlob = new Blob([values.csvFile], { type: "text/csv" });

          // Convert Blob to ArrayBuffer
          const arrayBuffer = await fileBlob.arrayBuffer();

          // Send the binary data using axios
          const response = await axios.post(
            `${Config.APIURL}api/v1/email/marketing/readcsv/${values.temp_status}?${params}`,
            arrayBuffer,
            {
              headers: {
                "Content-Type": "application/octet-stream",
              },
            }
          );

          if (response?.data?.code === 200) {
            const addedCount = response?.data?.data?.addedCount || 0;
            const skippedCount = response?.data?.data?.skippedCount || 0;

            toast.dismiss();
            toast.success(
              `${CSV_SAVED}: \naddedUsers: ${addedCount} \nskippedUsers: ${skippedCount}`
            );

            setCsvData([]);
          }
        } else {
          console.error("No file selected");
          setCsvData([]);
        }
      } catch (error) {
        console.error("Upload failed: ", error);
        if (error) {
          toast.dismiss();
          toast.error("Something went wrong");
        }
        setCsvData([]);
      } finally {
        setCsvData([]);
        setIsLoading(false);
      }
    },
  });
  return (
    <main id="content" role="main" className="main">
      <BackgroundImage />
      <div
        className="content container-fluid"
        style={{ justifyItems: "center", maxWidth: "60rem" }}
      >
        <div className="page-header">
          <div className="row align-items-end">
            <div className="col-sm mb-2 mb-sm-0"></div>
          </div>
        </div>

        <div className="card">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ padding: "1.3rem 1.3rem" }}
          >
            <div>
              <h1 className="card-title h1">{EMAIL_CSV_IMPORT}</h1>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <button
                className="d-flex align-items-center justify-content-between flex-wrap download-csv"
                onClick={() => downloadCSV()}
              >
                {FileDownloadIcons}{" "}
                <span className="ms-2">{DOWNLOAD_SAMPLE_CSV}</span>
              </button>
            </div>
          </div>

          <div className="card-body pt-0">
            <form
              className="shadow-md rounded px-8 pt-6 pb-8 mb-5"
              onSubmit={formik.handleSubmit}
              style={customStyle?.addUpdateCard}
            >
              <div className="d-flex justify-content-end">
                <button
                  disabled={isLoading}
                  className="btn btn-warning"
                  type="submit"
                >
                  {UPLOAD}
                </button>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="col-form-label" htmlFor="temp_status">
                    {TEMPLATE}
                  </label>
                  <select
                    className="form-select form-control"
                    name="temp_status"
                    id="temp_status"
                    onChange={formik.handleChange}
                    value={formik.values.temp_status || ""}
                  >
                    <option value="">{SELECTTEMPLATE}</option>
                    {getdata.length > 0 &&
                      getdata.map((item, index) => (
                        <option key={index} value={item.templateName || item}>
                          {item.templateName || item}
                        </option>
                      ))}
                  </select>
                  {formik.touched.temp_status && formik.errors.temp_status && (
                    <FormikError label={formik.errors.temp_status} />
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label
                    htmlFor="versionLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {VERSION}
                  </label>
                  <select
                    className="form-select"
                    autoComplete="off"
                    name="version"
                    onChange={formik.handleChange}
                    value={formik.values.version}
                  >
                    <option value={""} selected>
                      {SELECT_VERSION}
                    </option>
                    {versionList
                      ?.filter(
                        (item) =>
                          item.templateName === formik.values.temp_status
                      )
                      .map((item) => (
                        <option value={item.templateVersion} key={item.id}>
                          {item.templateVersion}
                        </option>
                      ))}
                  </select>
                  {formik.touched.version && formik.errors.version && (
                    <FormikError label={formik.errors.version} />
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label
                    htmlFor="versionLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {COUNTRY}
                  </label>
                  <select
                    className="form-select"
                    autoComplete="off"
                    name="country"
                    onChange={(event)=>{
                      console.log('event.target.value: ', event)
                      formik.setFieldValue(
                        "country", event.target.value 
                      )
                    }
                    }
                    value={formik.values.country}
                  >
                    <option value={""} selected>
                      {SELECT_COUNTRY}
                    </option>
                    {countryList &&
                        countryList?.map((item, index) => (
                          <option value={item.timeZone} key={index}>
                            {" "}
                            {item.name}
                          </option>
                        ))}
                  </select>
                  {formik.touched.country && formik.errors.country && (
                    <FormikError label={formik.errors.country} />
                  )}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="col-form-label" htmlFor="frequency">
                    {FREQUENCY}
                  </label>
                  <select
                    className="form-select form-control"
                    name="frequency"
                    id="frequency"
                    onChange={formik.handleChange}
                    value={formik.values.frequency}
                  >
                    <option value="">{SELECT_FREQUENCY}</option>
                    {Config.FREQUENCY_STATUS.map((item, index) => (
                      <option value={item.value} key={index}>
                        {" "}
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.frequency && formik.errors.frequency && (
                    <FormikError label={formik.errors.frequency} />
                  )}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="col-form-label" htmlFor="status">
                    {STATUS}
                  </label>
                  <select
                    className="form-select form-control"
                    name="status"
                    id="status"
                    onChange={formik.handleChange}
                    value={formik.values.status}
                  >
                    <option value="">{SELECTSTATUS}</option>
                    {Config.EMAIL_STATUS.map((item, index) => (
                      <option value={item.value} key={index}>
                        {" "}
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.status && formik.errors.status && (
                    <FormikError label={formik.errors.status} />
                  )}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label
                    className="col-form-label"
                    htmlFor="scheduleLaterLabel"
                  >
                    {SCHEDULE_DATE_TIME}
                  </label>
                  <div className="col-sm-12">
                    <input
                      ref={scheduleInputRef}
                      className="form-control valid cursor-pointer"
                      name="schedule"
                      id="schedule"
                      type="datetime-local"
                      placeholder={""}
                      onChange={formik.handleChange}
                      value={formik.values.schedule}
                      onClick={() =>
                        scheduleInputRef.current?.showPicker()
                      }
                      />
                    {formik.touched.schedule && formik.errors.schedule && (
                      <FormikError label={formik.errors.schedule} />
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="col-form-label" htmlFor="EndDateTimeLabel">
                    {END_DATE_TIME}
                  </label>
                  <input
                    ref={endInputRef}
                    className="form-control valid cursor-pointer"
                    name="endtime"
                    id="endtime"
                    type="datetime-local"
                    placeholder={""}
                    onChange={formik.handleChange}
                    value={formik.values.endtime}
                    onClick={() =>
                      endInputRef.current?.showPicker()
                    }
                    />
                  {formik.touched.endtime && formik.errors.endtime && (
                    <FormikError label={formik.errors.endtime} />
                  )}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label className="col-form-label">{IMPORT}</label>
                  <div className="form-border border p-4">
                    <label className="col-form-label pt-0" htmlFor="link">
                      {FILES}
                    </label>

                    <section>
                      <div
                        {...getRootProps({ style })}
                        className="cursor-pointer"
                      >
                        <input {...getInputProps()} />
                        <p className="m-0">{UPLOAD_MESSAGE_CSV}</p>
                      </div>
                      {formik.touched.csvFile && formik.errors.csvFile && (
                        <FormikError label={formik.errors.csvFile} />
                      )}
                    </section>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                {csvData?.length > 0 && (
                  <div className="">
                    <h2 className="text-2xl font-bold">
                      {emailmarketing.CSV_IMPORT_TABLE}
                    </h2>
                    <table className="table-auto w-full mt-4">
                      <thead>
                        <tr>
                          {csvData[0]?.map((header, index) => (
                            <th key={index} className="px-4 py-2">
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {csvData?.slice(1).map(
                          (row, rowIndex) =>
                            row?.some((cell) => cell?.trim() !== "") && (
                              <tr key={rowIndex}>
                                {row?.map((cell, cellIndex) => (
                                  <td
                                    key={cellIndex}
                                    className="border px-4 py-2"
                                  >
                                    {cell}
                                  </td>
                                ))}
                              </tr>
                            )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <div></div>
        <br></br>
        <br></br>
      </div>
      <div className="footer">
        <div className="row justify-content-between align-items-center">
          <div className="col"></div>
          <div className="col-auto">
            <div className="d-flex justify-content-end"></div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default HeaderNavbarHOC(Add);
