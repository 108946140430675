import React from "react";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import Datepicker from "../../Datepicker";
import Config from "../../config";
import { leaves } from "../../constant/Leaves";

function Edit({
  fullReport,
  setleave_type,
  leave_type,
  setfromdate,
  fromdate,
  settodate,
  todate,
  setreason,
  reason,
  setstatus,
  status,
  adminData,
  setapproverdate,
  approverdate,
  isDisable,
  changeStatus,
  updateId,
}) {
  return (
    <div
      className="modal fade"
      id="exampleModalLong"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {leaves.EDIT_LEAVES}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="modal-content">
              <table>
                <tbody>
                  {fullReport?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td>
                            <h4>{leaves.EMPLOYEE_NAME}</h4>{" "}
                            {item?.empid?.employeeName}
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{leaves.LEAVE_TYPE}</h4>{" "}
                            <Autocomplete
                              options={Config.LEAVE_TYPE}
                              getOptionLabel={(option) =>
                                leave_type === leaves.LEAVE_TYPE
                                  ? option.name
                                  : `${option.name} (${option.value})`
                              }
                              value={
                                Config.LEAVE_TYPE.find(
                                  (option) => option.value === leave_type
                                ) || {
                                  name: leaves.LEAVE_TYPE,
                                  value: "",
                                }
                              }
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(event, newValue) => {
                                setleave_type(
                                  newValue === null
                                    ? leaves.LEAVE_TYPE
                                    : newValue.value
                                );
                              }}
                            />
                          </td>
                        </tr>

                        <br></br>
                        <tr>
                          <td>
                            <h4>{leaves.FROM_DATE}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                setfromdate(newDate);
                              }}
                              value={moment(fromdate).format("YYYY-MM-DD")}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{leaves.TO_DATE}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                settodate(newDate);
                              }}
                              value={moment(todate).format("YYYY-MM-DD")}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{leaves.REASON}</h4>{" "}
                            <textarea
                              className="form-control"
                              placeholder="Leave reason"
                              id="validationValidTextarea1"
                              rows="2"
                              name="reason"
                              onChange={(event) =>
                                setreason(event.target.value)
                              }
                              value={reason}
                            ></textarea>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{leaves.STATUS}</h4>{" "}
                            <Autocomplete
                              options={Config.LEAVE_STATUS.filter(
                                (item) => item.value
                              )}
                              getOptionLabel={(option) => option.name}
                              value={
                                Config.LEAVE_STATUS.find(
                                  (option) => option.value === status
                                ) || {
                                  name: leaves.SELECT_LEAVE_STATUS,
                                }
                              }
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(event, newValue) => {
                                setstatus(
                                  newValue === null
                                    ? leaves.SELECT_LEAVE_STATUS
                                    : newValue.value
                                );
                              }}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{leaves.APPROVER}</h4>{" "}
                            <Autocomplete
                              options={
                                adminData.adminData ? [adminData.adminData] : []
                              }
                              getOptionLabel={(option) => option.email}
                              value={
                                status === "Select Approver"
                                  ? { email: "Select Approver" }
                                  : { email: status }
                              }
                              size="small"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(event, newValue) => {
                                setstatus(
                                  newValue === null
                                    ? "Select Approver"
                                    : newValue.email
                                );
                              }}
                            />
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            <h4>{leaves.APPROVER_DATE}</h4>{" "}
                            <Datepicker
                              onChange={(newDate) => {
                                setapproverdate(newDate);
                              }}
                              value={moment(approverdate).format("YYYY-MM-DD")}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              data-bs-dismiss="modal"
            >
              {leaves.CLOSE}
            </button>
            <button
              disabled={isDisable === true}
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                changeStatus(updateId);
              }}
            >
              {leaves.SAVE_CHANGES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit;
