import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import Config from "../../config";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import Axios from "axios";
import Table from "./Table";
import Edit from "./Edit";
import View from "./View";
import Note from "./Note";
import { Pagination, AlertDelete, Filter, BarChart } from "../../components/Common";
import {
  ADD,
  DATAUPDATESUCCESSFULLY,
  FAILED,
  PENDING,
  RESEND,
  RESEND_EMAIL_FAILED,
  RESENDEMAILSUCCESSFULLY,
  SEND,
  SUCCESS,
} from "../../constant/Label";
import ResendEmail from "./ResendEmail";
import Add from "./AddNew";
import Useuser from "../../Contexct";
import { EMAIL_BARCHART } from "../../constant/EmailMarketing";

function EmailList() {
  const { adminData } = Useuser();
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setResendEmailOpenAlert(false);
  };
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [resendEmailOpenAlert, setResendEmailOpenAlert] = useState(false);
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [filterDate, setFilterDate] = useState("all");
  const [applicants, setApplicants] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [fullReport, setFullReport] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterCountry, setFilterCountry] = useState("");
  const [filterVersion, setFilterVersion] = useState("all");
  const [filterFrequency, setFilterFrequency] = useState("all");
  const [count, setCount] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [resendEmail, setResendEmail] = useState("");
  const [getdata, setGetdata] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [versionList, setVersionList] = useState([]);

  const [categories, setCategories] = useState([]);
  const [seriesChartData, setSeriesChartData] = useState([]);
  const [startDateChart, setStartDateChart] = useState("");
  const [filterDateChart, setFilterDateChart] = useState("all");
  const [endDateChart, setEndDateChart] = useState("");
  const [chartData, setChartData] = useState([]);

  const [templateFormValue, setTemplateFormValue] = useState({
    templateName: "",
    templateVersion: "",
  });

  const [dateValue, setDateValue] = useState("all");
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [countData, setCountData] = useState({
    send: 0,
    resend: 0,
    pending: 0,
    Failed: 0
  });

  const summaryData = [
    { key: "send", label: SEND },
    { key: "resend", label: RESEND },
    { key: "pending", label: PENDING },
    { key: "Failed", label: FAILED },
  ];

  const currentDate = new Date();
  const currentMonth = Config.SALARY_MONTH[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear().toString();
  const [errMsg, setErrMsg] = useState({
    email: "",
    emailType: "",
    message: "",
  });
  const [selectedinfo, setSelectedInfo] = useState({
    selectedID: [],
  });
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    emailType: "",
    version: "",
    country: "",
    status: "",
    positions: "",
    companyName: "",
    isDeleted: "",
    message: "",
    frequency: "",
    schedule: "",
    endtime: "",
    scheduledDateAndTime: "",
    endDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const getCountryList = () => {
    AxiosActions.CountryList()
      .then((response) => {
        if (response.status === 200) {
          setCountryList(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get country list", error);
      });
  };

  const getVersionList = () => {
    AxiosActions.VersionList({})
      .then((response) => {
        if (response.status === 200) {
          setVersionList(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error in get version list", error); // Log any error
      });
  };

  useEffect(() => {
    getVersionList();
  }, [inputValue])

  // FOR SELECT ALL

  const isAllSelected =
    applicants?.length > 0 &&
    selectedinfo.selectedID.length === applicants?.length;
  const token = localStorage.getItem("access_token");

  // RENDER DATA

  const renderdata = async () => {
    try {
      setLoading(true);
      AxiosActions.EmailMarketingList({
        page: controller.page,
        limit: controller.rowsPerPage,
        year: Number(filterYear) ? Number(filterYear) : filterYear,
        type: filterDate,
        month: filterMonth,
        status: filterStatus,
        frequency: filterFrequency,
        country: filterCountry,
        templateVersion: filterVersion,
        startDate: startDate,
        endDate: endDate,
        name: filterName,
        email: filterEmail,
        scheduledDateAndTime: startDate,
      })
        .then((data) => {
          if (data?.status === 200) {
            if (data?.data?.data?.resultData) {
              setApplicants(data?.data?.data?.resultData);
              setCount(data?.data?.data?.count);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  useEffect(() => {
    getCountryList();
    if (isFiltered === true) {
      if (!controller.preventRefresh) filterRecord();
    } else {
      renderdata();
    }
  }, [controller, inputValue]);


  // New Pagination

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });

    setSelectedInfo({ selectedID: [] });
  };

  // Filter record

  const filterRecord = async () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_LIST_FILTER, { data: "" });
    setIsFiltered(true);
    if (!isFiltered) {
      let data = {
        page: 0,
        rowsPerPage: 10,
        preventRefresh: false,
      };
      setController(data);
    }
    renderdata();
    setSelectedInfo({ selectedID: [] });
  };

  // Update status
  const changeStatus = (statusid, title) => {
    let errObject = {
      email: "",
      emailType: "",
    };
    let err = 0;
    if (inputValue?.email === "") {
      err++;
      errObject.email = "Enter Email";
    }
    if (inputValue?.emailType === "") {
      err++;
      errObject.emailType = "Select Email Type";
    }
    if (inputValue?.frequency === "") {
      err++;
      errObject.frequency = "Select Frequency";
    }
    if (err !== 0) {
      setErrMsg(errObject);
    } else {
      setErrMsg({
        email: "",
        emailType: "",
      });

      setIsDisable(true);
      trackEvent(EVENT_NAME.EMAIL_MARKETING_EDIT, { data: statusid });
      AxiosActions.EditEmailMarketing({
        _id: statusid,
        name: inputValue?.name,
        email: inputValue?.email,
        emailType: inputValue?.emailType,
        country: inputValue?.country,
        status: inputValue?.status,
        positions: inputValue?.positions,
        companyName: inputValue?.companyName,
        templateVersion: inputValue?.templateVersion,
        frequency: inputValue?.frequency,
        schedule: inputValue?.schedule,
        endtime: inputValue?.endtime,
        scheduledDateAndTime: moment(inputValue?.scheduledDateAndTime).utc(),
        endDate: moment(inputValue?.endDate).utc(),
        month: currentMonth,
        year: Number(currentYear),
      })
        .then((data) => {
          if (data.status === 200) {
            setOpenAlert(true);
            renderdata();
            setIsDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisable(false);
          toast.error(err.message);
        });

      setSelectedInfo({ selectedID: [] });
    }
  };

  //Reset Filter

  const reset = () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_RESET, { data: "" });
    setFilterStatus("all");
    setFilterFrequency("all")
    setFilterCountry("");
    setFilterVersion("all");
    setController({
      ...controller,
      page: 0,
      rowsPerPage: 10,
      preventRefresh: false,
    });
    setSelectedInfo({ selectedID: [] });
    setIsFiltered(false);
    setFilterDate("all");
    setStartDate("");
    setEndDate("");
    setFilterName("");
    setFilterEmail("");
    setFilterMonth("all");
    setFilterYear("all");
  };

  const filterRecordChart = async () => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_CHART_FILTER, { data: "" });

    getEmailChartStatus();
  };

  const resetChart = () => {
    setFilterDateChart('all');
    setStartDateChart("");
    setEndDateChart('');
  }

  const handleDeleteClick = (event, id) => {
    setDeleteId(id);
    event.preventDefault();
  };

  // Delete Record

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMAIL_MARKETING_DELETE, { data: id });
    AxiosActions.DeleteEmailMarketing({
      _ids: id,
    })
      .then((data) => {
        if (data.status === 200) {
          renderdata();
          setIsDisable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
      });

    event.preventDefault();
  };

  // Adds notes to the email marketing reference. This is called when the user enters a note
  const addNotes = (event) => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_NOTE, {});
    if (inputValue?.message === "") {
      setErrMsg({
        message: "Enter Email Notes",
      });
    } else {
      setErrMsg({
        message: "",
      });

      setIsDisable(true);
      AxiosActions.EmailMarketingAddNote({
        marketingReference: fullReport[0]._id,
        template: "", //fullReport[0].emailType
        message: inputValue.message,
        noteType: inputValue.status,
      })
        .then((data) => {
          if (data.status === 200) {
            renderdata();
            setIsDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsDisable(false);
        });
    }
    event.preventDefault();
  };

  //View Full Report

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_VIEW, { data: id });
    const filterRecord = applicants?.filter((item) => {
      if (id === item._id) {
        const data = {
          name: item?.name,
          email: item?.email,
          emailType: item?.emailType,
          status: item?.status,
          schedule: item?.schedule,
          endtime: item?.endtime,
          endDate: item?.endDate,
          scheduledDateAndTime: item?.scheduledDateAndTime,
          templateVersion: item?.templateVersion,
          country: item?.country,
          frequency: item?.frequency,
          positions: item?.positions,
          companyName: item?.companyName,
          isDeleted: item?.isDeleted,
          message: "",
          country: item?.country,
          version: item?.templateVersion,
        };
        setInputValue(data);
        return true;
      }
    });

    setFullReport(filterRecord);
    setUpdateId(id);
  };
  const handleResendEmail = (event, app) => {
    setResendEmail(app);
    event.preventDefault();
  };

  // Resend Email
  const handleResendEmailClick = (app) => {
    trackEvent(EVENT_NAME.EMAIL_MARKETING_RESEND_EMAIL, { data: app._id });
    AxiosActions.ResendEmailMarketing({
      name: app.name,
      email: app.email,
      emailType: app.emailType,
      _id: app._id,
    })
      .then((data) => {
        if (data.status === 200) {
          renderdata();
          setResendEmailOpenAlert(true);
        } else {
          renderdata();
          toast.error(RESEND_EMAIL_FAILED);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  //Select Multiple

  const handleCheckBoxChange = (e) => {
    const value = e.target.value;
    const { checked } = e.target;
    const list = [];
    const { selectedID } = selectedinfo;

    if (value === "all") {
      if (value === "all" && checked) {
        applicants?.map((item) => {
          list.push(item._id);
        });
        setSelectedInfo({
          selectedID: list,
        });
      } else {
        setSelectedInfo({
          selectedID: list.filter((e) => e !== value),
        });
      }
      return;
    } else if (checked) {
      setSelectedInfo({
        selectedID: [...selectedID, value],
      });
    } else {
      setSelectedInfo({
        selectedID: selectedID.filter((e) => e !== value),
      });
    }
  };

  //Delete Multiple

  const handleDeleteMultipleClick = (event) => {
    event.preventDefault();
  };
  const deleteAllRecord = (event) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.EMAIL_MARKETING_DELETE_ALL, { data: "" });
    AxiosActions.DeleteEmailMarketing({
      _ids: selectedinfo.selectedID,
    })
      .then((data) => {
        if (data?.data?.code === 200) {
          renderdata();
          setIsDisable(false);
        }
      })
      .catch((err) => {
        alert("Please Delete Record Again");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
    event.preventDefault();
  };

  /**
   * @desc Handles the multi-resend of email marketing campaigns.
   * It prevents the default form submission, tracks the event,
   * and sends a request to resend emails using the selected IDs.
   * On success, it updates the rendered data; on error, it alerts
   * the user and resets the button state. Finally, it clears
   * the selected IDs.
   * @return {void}
   */
  const handleMultiResend = (event) => {
    event.preventDefault();

    setIsDisable(true);
    AxiosActions.ResendMultiEmailMarketing({
      ids: selectedinfo.selectedID,
      templateType: templateFormValue.templateName,
      templateVersion: templateFormValue.templateVersion,
    })
      .then((data) => {
        if (data?.data?.code === 200) {
          const successCount = data?.data?.data?.successCount || 0;
          const failureCount = data?.data?.data?.failureCount || 0;

          renderdata();
          setIsDisable(false);
          toast.dismiss(); // Dismiss any existing toasts
          toast.success(
            <>
              Success: {successCount} <br />
              Failure: {failureCount}
            </>,
            {
              autoClose: 5000, // Auto close after 5 seconds
            }
          );
        } else {
          setIsDisable(false);
        }
      })
      .catch((err) => {
        alert("Multi Resend Email Marketing Error");
        setIsDisable(false);
      });

    setSelectedInfo({ selectedID: [] });
  };

  // const handleMultiUpdate = async (event) => {
  //   event.preventDefault();
  //   setIsDisable(true);

  //   try {
  //     // Ensure all required fields are filled
  //     if (!templateFormValue.templateName || !templateFormValue.scheduledDateAndTime || !templateFormValue.endDate) {
  //       toast.error("Please fill all fields before updating.", { autoClose: 5000 });
  //       setIsDisable(false);
  //       return;
  //     }

  //     const requestData = {
  //       _ids: selectedinfo.selectedID,
  //       template: templateFormValue.templateName,
  //       scheduledDateAndTime: new Date(templateFormValue.scheduledDateAndTime).toISOString(),
  //       endDate: new Date(templateFormValue.endDate).toISOString(),
  //     };

  //     const response = await AxiosActions.UpdateMultiEmailMarketing(requestData);

  //     if (response?.data?.code === 200) {
  //       toast.success("Emails updated successfully!", { autoClose: 5000 });
  //       renderdata(); // Refresh data
  //     } else {
  //       toast.error("Failed to update emails.", { autoClose: 5000 });
  //     }
  //   } catch (error) {
  //     console.error("Multi Update Email Marketing Error:", error);
  //     toast.error("An error occurred while updating emails.", { autoClose: 5000 });
  //   } finally {
  //     setIsDisable(false);
  //     setSelectedInfo({ selectedID: [] }); // Clear selected IDs
  //   }
  // };


  const handleMultiUpdate = (event) => {
    event.preventDefault();
    setIsDisable(true);

    const requestData = {
      _ids: selectedinfo.selectedID, // Ensure this is an array of strings
      template: templateFormValue.templateName,
      templateVersion: parseFloat(templateFormValue.templateVersion), // Ensure version is a number
      scheduledDateAndTime: new Date(templateFormValue.scheduledDateAndTime).toISOString(),
      endDate: new Date(templateFormValue.endDate).toISOString(),
    };

    // Validate before sending
    if (!requestData._ids?.length || !requestData.template || !requestData.templateVersion || !requestData.scheduledDateAndTime || !requestData.endDate) {
      console.error("Missing required fields:", requestData);
      alert("Please fill in all fields before updating.");
      setIsDisable(false);
      return;
    }

    console.log("Sending API Request:", requestData);

    AxiosActions.UpdateMultiEmailMarketing(requestData)
      .then((data) => {
        console.log("API Response:", data?.data);
        if (data?.data?.code === 200) {
          toast.success("Successfully updated multiple records!");
        } else {
          toast.error("Update failed. Please try again.");
        }
      })
      .catch((err) => {
        console.error("Update Error:", err.response?.data || err.message);
        toast.error("Error updating records.");
      })
      .finally(() => setIsDisable(false));
  };


  // get email type
  useEffect(() => {
    const getTemplateList = async () => {
      try {
        const response = await AxiosActions.TemplateList({});

        if (response.status === 200 && Array.isArray(response.data.data)) {
          setGetdata(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching template list:", error);
      }
    };

    getTemplateList();
  }, []);

  // Fetches email chart data based on selected date range and filter
  const getEmailChartStatus = useCallback(async () => {
    try {
      const response = await AxiosActions.EmployeeChartList({
        startDate: startDateChart,
        endDate: endDateChart, type: filterDateChart
      });
      if (response?.data?.code === 200) {
        setChartData(response?.data?.data);
      }

    } catch (error) {
      console.error("Error fetching template list:", error);
    }
  }, [endDateChart, filterDateChart, startDateChart]);

  // Calls function to fetch email chart data on dependency change
  useEffect(() => {
    getEmailChartStatus();
  }, [getEmailChartStatus]);

  // Transforms raw chart data into categories and series format
  useEffect(() => {
    const { categories, series } = transformData(chartData);
    setCategories(categories);
    setSeriesChartData(series);
  }, [chartData]);

  // Processes data to initialize status counts for each date
  const transformData = (data) => {
    let result = {};

    data?.forEach(({ date, statuses }) => {
      if (!result[date]) {
        result[date] = { Failed: 0, Sent: 0, Resent: 0, pending: 0 };
      }

      // Accumulates status counts per date
      statuses?.forEach(({ status, count }) => {
        if (result[date][status] !== undefined) {
          result[date][status] += count;
        }
      });
    });

    // Extracts dates as categories and structures series data
    const categories = Object.keys(result)?.sort();
    const series = Config?.EMAIL_STATUS_TYPE?.map((status) => ({
      name: status,
      data: categories?.map((date) => result[date][status]),
    }));

    return { categories, series };
  };

  // Update date value and fetch email count based on selected filter type
  const dateUpdate = (value) => {
    setDateValue(value);
    fetchEmailCount(value, startDateValue, endDateValue);
  };

  // Update start date and fetch email count
  const startDateUpdate = (value) => {
    setStartDateValue(value);
    fetchEmailCount(dateValue, value, endDateValue);
  };

  // Update end date and fetch email count
  const endDateUpdate = (value) => {
    setEndDateValue(value);
    fetchEmailCount(dateValue, startDateValue, value);
  };

  const fetchEmailCount = (type, startDate, endDate) => {
    let requestData = { type };

    if (type === "customize") {
      requestData = { ...requestData, startDate, endDate };
    }

    Axios.post(`${Config.APIURL}api/v1/email/marketing/count`, requestData)
      .then((response) => {
        const { data } = response.data;

        const statusCount = {
          send: 0,
          resend: 0,
          pending: 0,
          Failed: 0,
        };

        // Process the API response to map counts for each status
        data.forEach(item => {
          if (item._id === "Sent") {
            statusCount.send = item.count;
          } else if (item._id === "Resent") {
            statusCount.resend = item.count;
          } else if (item._id === "pending") {
            statusCount.pending = item.count;
          } else if (item._id === "Failed") {
            statusCount.Failed = item.count;
          }
        });

        setCountData(statusCount);
      })
      .catch((error) => {
        console.error("Error fetching email count:", error);
      });
  };

  useEffect(() => {
    fetchEmailCount(dateValue, startDateValue, endDateValue);
  }, [dateValue, startDateValue, endDateValue]);

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title={SUCCESS}
        ok={false}
      />
      {resendEmailOpenAlert && (
        <AlertBox
          open={resendEmailOpenAlert}
          onClose={handleCloseAlert}
          message={RESENDEMAILSUCCESSFULLY}
          title={SUCCESS}
          ok={false}
        />
      )}
      {token === null ? (
        true((window.location.href = "/"))
      ) : (
        <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <Filter
              date={true}
              dateValue={filterDateChart}
              dateUpdate={setFilterDateChart}
              startDateValue={startDateChart}
              startDateUpdate={setStartDateChart}
              endDateValue={endDateChart}
              endDateUpdate={setEndDateChart}
              filterRecord={filterRecordChart}
              resetRecord={resetChart}
            />
            <div className="card card-bordered h-100 mb-3">
              <div className="card-body">
                <BarChart
                  categories={categories}
                  series={seriesChartData}
                  title={EMAIL_BARCHART.TITLE}
                  yAxisTitle={EMAIL_BARCHART.Y_AXIS_LABEL}
                  xAxisTitle={EMAIL_BARCHART.X_AXIS_LABEL}
                  barWidth={60}
                />
              </div>
            </div>
            <div className="card mb-3 mb-lg-5">
              <div className="card-header">
                <div className="container-fluid d-flex justify-content-end align-items-center mb-3">
                  <Filter
                    date={true}
                    dateValue={dateValue}
                    dateUpdate={dateUpdate}
                    startDateValue={startDateValue}
                    startDateUpdate={startDateUpdate}
                    endDateValue={endDateValue}
                    endDateUpdate={endDateUpdate}
                    isDisable={true}
                  />
                  <div className="card-header">
                    <button
                      type="button"
                      className="icon icon-success ps-5 pe-5"
                      title={ADD}
                      data-bs-toggle="modal"
                      data-bs-target="#addExampleModal"
                      onClick={(event) => handleViewReportClick(event)}
                    >
                      {ADD}
                      <i className="bi bi-plus"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  {summaryData.map((item, index) => (
                    <div key={index} className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
                      <a
                        className="card card-hover-shadow h-100 d-flex align-items-center justify-content-center text-center"
                        href=""
                      >
                        <div className="card-body d-flex flex-column align-items-center justify-content-center">
                          <h2 className="card-title text-inherit">{countData[item.key]}</h2>
                          <h4 className="px-6 py-2 mt-3 attendance-summary-label">
                            {item.label}
                          </h4>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>;
                <Filter
                  year={true}
                  yearValue={filterYear}
                  yearUpdate={setFilterYear}
                  month={true}
                  monthValue={filterMonth}
                  monthUpdate={setFilterMonth}
                  status={true}
                  country={true}
                  statusValue={filterStatus}
                  countryValue={filterCountry}
                  versionValue={filterVersion}
                  statusUpdate={setFilterStatus}
                  frequencyValue={filterFrequency}
                  frequencyUpdate={setFilterFrequency}
                  countryUpdate={setFilterCountry}
                  versionUpdate={setFilterVersion}
                  frequencyList={Config.FREQUENCY_STATUS}
                  statusList={Config?.EMAIL_STATUS}
                  countryList={countryList}
                  versionList={versionList}
                  date={true}
                  dateValue={filterDate}
                  dateUpdate={setFilterDate}
                  startDateValue={startDate}
                  startDateUpdate={setStartDate}
                  endDateValue={endDate}
                  endDateUpdate={setEndDate}
                  searchName={true}
                  searchNameValue={filterName}
                  searchNameUpdate={setFilterName}
                  searchEmail={true}
                  searchEmailValue={filterEmail}
                  searchEmailUpdate={setFilterEmail}
                  multipleSelect={selectedinfo?.selectedID?.length}
                  multipleDelete={true}
                  multipleResend={true}
                  multipleUpdate={true}
                  handleMultiUpdate={handleMultiUpdate}
                  isDisable={isDisable}
                  handleDelete={handleDeleteMultipleClick}
                  handleMultiResend={handleMultiResend}
                  TemplateList={getdata}
                  setTemplateFormValue={setTemplateFormValue}
                  templateFormValue={templateFormValue}
                  deleteAllRecord={deleteAllRecord}
                  filterRecord={filterRecord}
                  resetRecord={reset}
                />
              </div>
              <div className="table-responsive datatable-custom">
                <Table
                  handleCheckBoxChange={handleCheckBoxChange}
                  isAllSelected={isAllSelected}
                  loading={loading}
                  applicants={applicants}
                  selectedinfo={selectedinfo}
                  handleViewReportClick={handleViewReportClick}
                  handleDeleteClick={handleDeleteClick}
                  handleResendEmail={handleResendEmail}
                  countryList={countryList}
                  versionList={versionList}
                  adminData={adminData}
                />
              </div>
              <Add
                countryList={countryList}
                versionList={versionList}
                // templateVersions={templateVersions}
                setCountryList={setCountryList}
                setVersionList={setVersionList}
                renderdata={() => {
                  renderdata();
                }}
                TemplateList={getdata}
              />
              <Edit
                TemplateList={getdata}
                handleChange={handleChange}
                inputValue={inputValue}
                isDisable={isDisable}
                changeStatus={changeStatus}
                updateId={updateId}
                errMsg={errMsg}
                setInputValue={setInputValue}
                versionList={versionList}
                countryList={countryList}
              />
              <ResendEmail
                isDisable={isDisable}
                handleResendEmailClick={handleResendEmailClick}
                resendEmail={resendEmail}
              />

              <AlertDelete
                isDisable={isDisable}
                deleteRecord={deleteRecord}
                deleteId={deleteId}
              />

              <View fullReport={fullReport} />
              <Note
                fullReport={fullReport}
                handleChange={handleChange}
                inputValue={inputValue}
                setInputValue={setInputValue}
                isDisable={isDisable}
                addNotes={addNotes}
                errMsg={errMsg}
              />

              <div className="card-footer">
                <Pagination
                  handlePageChange={handlePageChange}
                  controller={controller}
                  count={count}
                  handleChangeRowsPerPage1={handleChangeRowsPerPage1}
                />
              </div>
            </div>
          </div>
          <div className="footer"></div>
        </main>
      )}
    </>
  );
}

export default HeaderNavbarHOC(EmailList);
