import React from "react";
import PropTypes from "prop-types";
import Config from "../../../config";
import { useHasPermission } from "../../../components/Common/Permission";

function TemplateTable({
  loading,
  templates,
  adminData,
  handleDeleteClick,
  handleViewReportClick,
  getTemplateUrl
}) {
  console.log('templates: ', templates)
  const canDelete = useHasPermission(
    adminData.adminData.role,
    "email",
    Config.ACTION.delete_template
  );
  const canEdit = useHasPermission(
    adminData.adminData.role,
    "email",
    Config.ACTION.edit_template
  );
  const canView = useHasPermission(
    adminData.adminData.role,
    "email",
    Config.ACTION.view_template
  );
  const hasPermission = canDelete || canEdit || canView;

  return (
    <table className="table table-borderless table-thead-bordered text-nowrap table-align-middle card-table">
      <thead className="thead-light">
        <tr>
          <th>Template Type</th>
          <th>template Subject</th>
          <th>Version</th>
          <th>Source Email</th>
          <th>Note</th>
          {hasPermission && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <status className="spinner-border text-dark m-5">
                  <span className="sr-only"></span>
                </status>
              </div>
            </td>
          </tr>
        ) : templates.length > 0 ? (
          templates.map((template) => (
            <tr key={template._id}>
              <td>{template.templateName}</td>
              <td>{template.templateSubject}</td>
              <td>{template.templateVersion}</td>
              <td>{template.sourceEmail || "-"}</td>
              <td>{template.Note}</td>
              {hasPermission && (
                <td>
                  {canEdit && (
                    <button
                      title="Edit Template"
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, template._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>
                  )}
                  &nbsp;
                  {canDelete && (
                    <button
                      title="Delete Template"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) =>handleDeleteClick(event, template._id)
                      }
                    >
                      <i className="bi-trash"></i>
                    </button>
                  )}
                  &nbsp;
                  {canView && (
                    <button
                      title="View Template"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, template._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>
                  )}
                  &nbsp;
                  <button
                      title="View Template Email"
                      type="button"
                      className="icon icon-dark icon-circle" 
                      onClick={() => {
                        getTemplateUrl(template.fileName)
                      }}

                    >
                      <i className="bi-envelope-open-fill"></i>
                    </button>
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              No templates found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

TemplateTable.propTypes = {
  handleCheckBoxChange: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  templates: PropTypes.array.isRequired,
  selectedinfo: PropTypes.object.isRequired,
  adminData: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  handleViewReportClick: PropTypes.func.isRequired,
};

export default TemplateTable;
