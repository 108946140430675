import React from "react";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { client } from "../../constant/Client";
import Config from "../../config";
import { useHasPermission } from "../../components/Common/Permission";
import PropTypes from "prop-types";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  loading,
  applicants,
  selectedinfo,
  getClientStatus,
  adminData,
  handleViewReportClick,
  handleDeleteClick,
}) {

  const canDelete = useHasPermission(
    adminData.role,
    "client",
    Config.ACTION.delete
  );
  const canEdit = useHasPermission(
    adminData.role,
    "client",
    Config.ACTION.edit
  );
  const canView = useHasPermission(
    adminData.role,
    "client",
    Config.ACTION.detail
  );
  const hasPermission = canDelete || canEdit || canView;

  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>
          <th>{client.DATE}</th>
          <th>{client.CLIENT_NAME}</th>

          <th>{client.STATUS}</th>
          {hasPermission && <th>{client.ACTION}</th>}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={5} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <status className="spinner-border text-dark m-5">
                  <span className="sr-only"></span>
                </status>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {applicants.length === 0 && (
              <tr>
                <td colSpan={5} style={{ textAlign: "center" }}>
                  <h3>{client.NO_RECORD_FOUND}</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              let date = moment(app.date).format("DD-MM-YYYY");

              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={app._id}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>
                  <td style={{ width: "5%" }}>{date}</td>
                  <td className="table-column-ps-1">
                    <h5 className="text-inherit mb-0">{app.clientName}</h5>
                  </td>

                  <td>{getClientStatus(app.status, "client")}</td>
                  {hasPermission && <td>
                    {canEdit &&
                    <button
                      title="Edit Client"
                      type="button"
                      className="icon icon-danger icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalLong"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-pen"></i>
                    </button>}
                    &nbsp;
                    {canDelete &&
                    <button
                      title="Delete Client"
                      type="button"
                      className="icon icon-info icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#delete"
                      onClick={(event) => handleDeleteClick(event, app._id)}
                    >
                      <i className="bi-trash"></i>
                    </button>}
                    &nbsp;
                    {canView && 
                    <button
                      title="View Client"
                      type="button"
                      className="icon icon-dark icon-circle"
                      data-bs-toggle="modal"
                      data-bs-target="#viewpage"
                      onClick={(event) => handleViewReportClick(event, app._id)}
                    >
                      <i className="bi-eye"></i>
                    </button>}
                  </td>}
                  <></>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  handleCheckBoxChange: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  selectedinfo: PropTypes.object.isRequired,
  getClientStatus: PropTypes.func.isRequired,
  handleViewReportClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  adminData: PropTypes.object.isRequired,
};