import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import Axios from "axios";
import Config from "../../../config";
import HeaderNavbarHOC from "../../../HOC/HeaderNavbar";
import AlertBox from "../../../Alert";
import * as AxiosActions from "../../../Actions/axiosActions";
import { trackEvent, EVENT_NAME } from "../../../constant/MixpanelConstant";
import { AuthContext } from "../../../Login/Protected";
import { HeaderTitle, Pagination, Filter } from "../../../components/Common";
import TemplateTable from "./table";
import Edit from "./edit";
import View from "./view";
import { DATAUPDATESUCCESSFULLY } from "../../../constant/Label";
import Delete from "../../../components/Common/AlertBox/Delete";
import { toast } from "react-toastify";

function TemplateList() {
  const adminData = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [templateType, setTemplateType] = useState("");
  const [getdata, setGetdata] = useState([]);
  const [templateUrl, setTemplateUrl] = useState(false);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 10,
    preventRefresh: false,
  });

  const formik = useFormik({
    initialValues: {
      id:"",
      templateName: "",
      templateVersion: "",
      source: "",
      note : "",
      status: "",
      file: null,
      templateSubject: "",
    },
    onSubmit: async (values) => {
      try {
        setIsDisable(true);
        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("templateName", values.templateName);
        formData.append("templateVersion", values.templateVersion);
        formData.append("templateSubject", values.templateSubject);
        formData.append("Note", values.note);
        formData.append("Active", values.status);
        formData.append("sourceEmail", values.source);
        if (values.file) {
          formData.append("file", values.file);
        }
        await Axios.post(Config.LOCALURL + "api/v1/email/template/edit", formData , {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setOpenAlert(true);
        renderdata();
      } catch (error) {
        console.error("Error updating template:", error);
      } finally {
        setIsDisable(false);
      }
    },
  });
  
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const renderdata = async () => {
    try {
      setLoading(true);
      await Axios.post(Config.LOCALURL + "api/v1/email/template/list", {
        page: controller.page,
        limit: controller.rowsPerPage,
        templateName: templateType,
      })
        .then((data) => {
          if (data?.data?.code === 200) {
            setRenderState(data?.data?.data?.emailTemplates, data?.data?.data?.countDocuments);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log("Catch Err in Pagination ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
  };

  const deleteRecord = (event, id) => {
    setIsDisable(true);
    trackEvent(EVENT_NAME.TEMPLATE_DELETE, { data: id });
    Axios.post(Config.LOCALURL + "api/v1/email/template/delete", {
      id: id,
      adminId: adminData.adminData._id,
    })
      .then((data) => {
        renderdata();
        setIsDisable(false);
        toast.success("Template deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        setIsDisable(false);
        alert("Please Delete Record Again.");
      });
 
      event.preventDefault();
    };

    const handleDeleteClick = (event, id) => {
      formik.setFieldValue(
        "id",
        id
      )
      
      event.preventDefault();
    };

  const setRenderState = (templates, noOfRecords) => {
    setTemplates(templates);
    setCount(noOfRecords);
  };

  useEffect(() => {
    renderdata();
  }, [controller, templateType]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
      preventRefresh: false,
    });
  };

  const handleChangeRowsPerPage1 = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      preventRefresh: false,
    });
  };

  const handleViewReportClick = (event, id) => {
    trackEvent(EVENT_NAME.TEMPLATE_VIEW, { data: id });
    const selected = templates.find((template) => template._id === id);
    if (selected) {
      formik.setValues({
        id: selected._id,
        templateName: selected.templateName,
        templateVersion: selected.templateVersion,
        source: selected.sourceEmail,
        note : selected.Note ,
        status: selected.isActive ? "Active" : "Inactive",
        file: selected.fileName || null,
        templateSubject: selected.templateSubject || "",
      });
      setSelectedTemplate(selected);
    }
  };

  const filterRecord = () => {
    trackEvent(EVENT_NAME.TEMPLATE_LIST_FILTER);
    setController({
      ...controller,
      page: 0,
      preventRefresh: true,
    });
    renderdata();
  };

  const resetFilter = () => {
    trackEvent(EVENT_NAME.TEMPLATE_RESET);
    setTemplateType("");
    setController({
      ...controller,
      page: 0,
      preventRefresh: true,
    });
    renderdata();
  };

  useEffect(() => {
    const getTemplateList = async () => {
      try {
        const response = await AxiosActions.TemplateList({});

        if (response.status === 200 && Array.isArray(response.data.data)) {
          setGetdata(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching template list:", error);
      }
    };

    getTemplateList();
  }, []);


    const getTemplateUrl = async (fileName) => {
      try {
      await Axios.post(Config.LOCALURL + "api/v1/email/template/url", {
        fileName : formik.values.file || fileName,
      })
        .then((data) => {
          if (data?.data?.code === 200) {
            setTemplateUrl(data?.data?.data);
            if(fileName){
              window.open(
                data?.data?.data,
                "_blank"
              )
            }
          }
        })
        .catch((error) => {
          console.log("Catch Err in getting url ", error);
        });
    } catch (err) {
      console.log("Catch err in render data", err);
    }
    };
    
     useEffect(() => {
      if(formik.values.file){
        getTemplateUrl();
      }
    }, [formik.values.file]);

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAUPDATESUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <div className="content container-fluid">
          <HeaderTitle title="Templates" />
          <div className="card mb-3 mb-lg-5">
            <div className="card-header">
              <Filter
                isDisable={isDisable}
                templateType={true}
                templateTypeValue={templateType}
                templateTypeUpdate={setTemplateType}
                templateTypeList={getdata}
                filterRecord={filterRecord}
                resetRecord={resetFilter}
              />
            </div>
            <div className="table-responsive datatable-custom">
              <TemplateTable
                loading={loading}
                templates={templates}
                adminData={adminData}
                handleViewReportClick={handleViewReportClick}
                handleDeleteClick={handleDeleteClick}
                getTemplateUrl={getTemplateUrl}
              />
            </div>
            <Delete 
            deleteRecord={deleteRecord}
            deleteId={formik.values.id}
            isDisable={isDisable}
            />
            <div className="card-footer">
              <Pagination
                handlePageChange={handlePageChange}
                controller={controller}
                count={count}
                handleChangeRowsPerPage1={handleChangeRowsPerPage1}
              />
            </div>
          </div>
        </div>
        <Edit
          formik={formik}
          isDisable={isDisable}
          templateType={getdata}
          onSubmit={formik.handleSubmit}
        />
        <View selectedTemplate={selectedTemplate} templateUrl={templateUrl} />
        <div className="footer"></div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(TemplateList);