import React, { useState } from "react";
import Header from "../Home/Header";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";

const HeaderNavbarHOC = (WrappedComponent) => {
  const WithLogger = (props) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const isElectronAPIAvailable =
    typeof window !== "undefined" && window.electronAPI !== undefined;

    return isElectronAPIAvailable ? (
      <>
        <Header isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} isElectronAPIAvailable={isElectronAPIAvailable} />
        <WrappedComponent {...props} />
      </>
    ) : (
      <body className="has-navbar-vertical-aside navbar-vertical-aside-show-xl   footer-offset">
        <Header isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />
        <Navbar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible} />
        <WrappedComponent {...props} />
        <Footer></Footer>
      </body>
    )
  };

  return WithLogger;
};

export default HeaderNavbarHOC;
