import React from "react";
import { Typography, TextField, IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";

function ModalContent({
  selectedDate,
  selectedDateDetails,
  inOutTime,
  attendanceStatus,
  description,
  convertMillisecondsToTime,
  onClose,
}) {
  const showAllFields =
    selectedDateDetails &&
    ["HCL", "HSL", "HEL", "HLP", null].includes(attendanceStatus);

  return (
    <Box
      className="w-50"
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "1px solid black",
        outline: "none",
        overflow: "auto",
        overflowX: "hidden",
        padding: "20px",
        boxShadow: 24,
        p: 4,
      }}
    >
      <div style={{ textAlign: "center", padding: 10, position: "relative" }}>
        <IconButton
          onClick={onClose}
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            color: "#000",
          }}
        >
          <Close />
        </IconButton>

        <Typography variant="h6" className="pb-3">
          Details for {selectedDate}
        </Typography>
        {selectedDateDetails &&
        attendanceStatus !== "Week Holiday" ? (
          <div className="d-flex flex-column">
            {showAllFields ? (
              <>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    htmlFor="dateLabel"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Date :
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      value={selectedDate}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      id="dateLabel"
                    />
                  </div>
                </div>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    htmlFor="inOutTimesLabel"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    In/Out Times :
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      value={inOutTime || "In/Out time is not available"}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      id="inOutTimesLabel"
                      multiline
                    />
                  </div>
                </div>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    htmlFor="totalHoursLabel"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Total Hours :
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      value={`${selectedDateDetails.totalHours.toFixed(2)} hour`}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      id="totalHoursLabel"
                    />
                  </div>
                </div>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    htmlFor="totalHoursLabel"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Tracking Hours :
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      value={
                        selectedDateDetails.timeTracking
                         ? convertMillisecondsToTime(selectedDateDetails.timeTracking)
                         : "Tracking hours is not available"
                      }
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      id="totalHoursLabel"
                    />
                  </div>
                </div>
                {attendanceStatus && (
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      htmlFor="attendanceStatusLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Attendance Status :
                    </label>
                    <div className="col-sm-9">
                      <TextField
                        fullWidth
                        value={attendanceStatus}
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        size="small"
                        variant="outlined"
                        id="attendanceStatusLabel"
                      />
                    </div>
                  </div>
                )}
                {description && (
                  <div className="row mb-3 d-flex align-items-center">
                    <label
                      htmlFor="descriptionLabel"
                      className="col-sm-3 col-form-label form-label"
                      style={{ fontSize: 17 }}
                    >
                      Description:
                    </label>
                    <div className="col-sm-9">
                      <TextField
                        fullWidth
                        value={description}
                        InputProps={{
                          readOnly: true,
                        }}
                        margin="normal"
                        size="small"
                        variant="outlined"
                        id="descriptionLabel"
                        multiline
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    htmlFor="attendanceStatusLabel"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Attendance Status :
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      value={attendanceStatus}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      id="attendanceStatusLabel"
                    />
                  </div>
                </div>
                <div className="row mb-3 d-flex align-items-center">
                  <label
                    htmlFor="descriptionLabel"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    Description:
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      value={description}
                      InputProps={{
                        readOnly: true,
                      }}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      id="descriptionLabel"
                      multiline
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <Typography style={{ padding: 10, fontSize: 25, color: "green" }}>
            {attendanceStatus}
          </Typography>
        )}
      </div>
    </Box>
  );
}

export default ModalContent;

ModalContent.propTypes = {
  selectedDate: PropTypes.string,
  selectedDateDetails: PropTypes.object,
  inOutTime: PropTypes.string,
  attendanceStatus: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func,
  convertMillisecondsToTime: PropTypes.func,
};
