import React from "react";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { attendance } from "../../constant/Attendance";
import PropTypes from "prop-types";
import Config from "../../config";
import { useHasPermission } from "../../components/Common/Permission";

function Table({
  handleCheckBoxChange,
  isAllSelected,
  adminData,
  loading,
  applicants,
  selectedinfo,
  handleViewReportClick,
  handleDeleteClick,
})
{
  const canDelete = useHasPermission(adminData.adminData.role, "attendance", Config.ACTION.delete);
  const canEdit = useHasPermission(adminData.adminData.role, "attendance", Config.ACTION.edit);
  const hasPermission = canDelete || canEdit;

  return (
    <table
      id="datatable"
      className="table table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
    >
      <thead className="thead-light">
        <tr>
          <th scope="col" className="table-column-pe-0">
            <Checkbox
              size="medium"
              value="all"
              onChange={handleCheckBoxChange}
              checked={isAllSelected}
            ></Checkbox>
          </th>

          <th>{attendance.NAME}</th>
          <th>{attendance.DATE}</th>
          <th>{attendance.TIME}</th>
          <th>{attendance.TYPE}</th>

          {adminData.adminData.isAdmin && hasPermission ? <th>{attendance.ACTION}</th> : ""}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={7} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <status className="spinner-border text-dark m-5">
                  <span className="sr-only"></span>
                </status>
              </div>
            </td>
          </tr>
        ) : (
          <>
            {applicants.length === 0 && (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  <h3>{attendance.NO_RECORD_FOUND}</h3>
                </td>
              </tr>
            )}
            {applicants.map((app, index) => {
              return (
                <tr key={"app" + index}>
                  <td className="table-column-pe-0">
                    <Checkbox
                      key={app._id}
                      size="medium"
                      value={app._id}
                      onChange={handleCheckBoxChange}
                      checked={selectedinfo.selectedID.includes(app._id)}
                    ></Checkbox>
                  </td>

                  <td className="table-column-ps-1">
                    <h5 className="text-inherit mb-0">
                      {app.empid?.employeeName}
                    </h5>
                  </td>

                  <td>
                    {moment.utc(app.date).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {moment.utc(app.date).format("h:mm A")}
                  </td>
                  <td>{app.attendance_type}</td>
                  {adminData.adminData.isAdmin && hasPermission ? (
                    <td>
                      {adminData.adminData.isAdmin && hasPermission ? (
                      <td>
                     {canEdit && (
                        <button
                          title="Edit attendance"
                          type="button"
                          className="icon icon-danger icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalLong"
                          onClick={(event) =>
                            handleViewReportClick(event, app._id)
                          }
                        >
                          <i className="bi-pen"></i>
                        </button>
                     )}
                        &nbsp;
                        {canDelete && (
                        <button
                          title="Delete attendance"
                          type="button"
                          className="icon icon-info icon-circle"
                          data-bs-toggle="modal"
                          data-bs-target="#delete"
                          onClick={(event) => handleDeleteClick(event, app._id)}
                        >
                          <i className="bi-trash"></i>
                        </button>
                        )}
                      </td>
                    ) : (
                      ""
                    )}
                    </td>
                  ) : (
                    ""
                  )}

                  <></>
                </tr>
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  handleCheckBoxChange: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  adminData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  applicants: PropTypes.array.isRequired,
  selectedinfo: PropTypes.object.isRequired,
  handleViewReportClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};