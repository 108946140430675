export const TRACKING = {
  NO_RECORD_FOUND: "No record found",
  MY_TASK: "My Task",
  TO_DO: "To-Do",
  COMPLETED: "Completed",
  SELECT_CLIENT: "Select Client",
  SELECT_TASK: "Select Task",
  STOP_TRACKING: "Stop Tracking",
  START_TRACKING: "Start Tracking",
  TODAY_TOTAL: "Today total",
  WEEKLY_TOTAL: "Weekly total",
  LAST_UPDATE: "Last update at",
  SYNC: "Sync",
};

export const TRACKING_ACTION = {
  SELECT_CLIENT: "Please select client",
  SELECT_TASK: "Please select task",
  NO_SCREENSHOT_CAPTURED: "No screenshot captured",
  ELECTRON_API_UNAVAILABLE: "Electron API is not available",
  CLIENT_AND_TASK_REQUIRED: "Please select client and task",
  TRACKING_ERROR: "Tracking error occurred",
  UNEXPECTED_ERROR: "An unexpected error occurred",
  SYNC_ERROR: "Sync Failed",
};
