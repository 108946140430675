import React, { useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import HeaderNavbarHOC from "../../HOC/HeaderNavbar";
import * as AxiosActions from "../../Actions/axiosActions";
import AlertBox from "../../Alert";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import { EventDateTimePicker } from "../../Datepicker";
import config from "../../config/index";
import BackgroundImage from "../../components/Common/CardImage";
import { DATAADDEDSUCCESSFULLY, SAVECHANGES } from "../../constant/Label";
import { event } from "../../constant/Event";

function EventAdd() {
  const [openAlert, setOpenAlert] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setIsDisable(false);
    formik.resetForm();
    formik.setFieldValue("eventType", "");
  };

  const formik = useFormik({
    initialValues: {
      eventName: "",
      description: "",
      scheduleDate: "",
      endDate: "",
      eventType: null,
    },
    validate: (values) => {
      const errors = {};
      if (!values.eventName) {
        errors.eventName = "Please enter valid event name";
      }
      if (!values.description) {
        errors.description = "Please enter valid description";
      }
      if (!values.scheduleDate) {
        errors.scheduleDate = "Please enter valid schedule date";
      }
      if (!values.endDate) {
        errors.endDate = "Please select valid end date";
      }
      if (!values.eventType) {
        errors.eventType = "Please select a valid event type";
      }
      return errors;
    },
    onSubmit: (values) => {
      setOpenAlert(true);
      setIsDisable(true);
      trackEvent(EVENT_NAME.EVENT_ADD, { data: "" });

      AxiosActions.EventAdd({
        activityName: values.eventName,
        description: values.description,
        endDate: values.endDate,
        dateAndTime: values.scheduleDate,
        activityType: values.eventType,
      })
        .then((response) => {
          if (response.status === 200) {
            setIsDisable(false);
            setOpenAlert(true);
            formik.resetForm();
          }
        })
        .catch((error) => {
          setIsDisable(false);
          console.log("event add error", error);
        });
    },
  });

  return (
    <>
      <AlertBox
        open={openAlert}
        onClose={handleCloseAlert}
        message={DATAADDEDSUCCESSFULLY}
        title="Success"
        ok={false}
      />
      <main id="content" role="main" className="main">
        <BackgroundImage />
        <div
          className="content container-fluid"
          style={{ justifyItems: "center", maxWidth: "60rem" }}
        >
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-sm mb-2 mb-sm-0"></div>
            </div>
          </div>

          <div className="card w-70">
            <div className="card-header">
              <h1 className="card-title h1">{event.EVENT_ADD}</h1>
            </div>

            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="row mb-4">
                  <label
                    htmlFor="eventName"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    {event.EVENT_NAME} :
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      id="eventName"
                      name="eventName"
                      label="Event Name"
                      value={formik.values.eventName}
                      onChange={formik.handleChange}
                      size="small"
                      variant="outlined"
                      error={
                        formik.touched.eventName &&
                        Boolean(formik.errors.eventName)
                      }
                      helperText={
                        formik.touched.eventName && formik.errors.eventName
                      }
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="scheduleDate"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    {event.EVENT_SCHEDULE_DATE} :
                  </label>
                  <div className="col-sm-9">
                    <EventDateTimePicker
                      onChange={(newDate) => {
                        formik.setFieldValue("scheduleDate", newDate);
                      }}
                      value={formik.values.scheduleDate}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="endDate"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    {event.EVENT_END_DATE} :
                  </label>
                  <div className="col-sm-9">
                    <EventDateTimePicker
                      onChange={(newDate) => {
                        formik.setFieldValue("endDate", newDate);
                      }}
                      value={formik.values.endDate}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="description"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    {event.EVENT_DESCRIPTION} :
                  </label>
                  <div className="col-sm-9">
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      label="Description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      size="small"
                      variant="outlined"
                      multiline
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description &&
                        formik.errors.description
                      }
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <label
                    htmlFor="eventType"
                    className="col-sm-3 col-form-label form-label"
                    style={{ fontSize: 17 }}
                  >
                    {event.EVENT_TYPE} :
                  </label>
                  <div className="col-sm-9">
                    <Autocomplete
                      options={config.EVENTTYPE}
                      getOptionLabel={(option) => option.name}
                      value={
                        config.EVENTTYPE.find(
                          (option) => option.value === formik.values.eventType
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue(
                            "eventType",
                            newValue.value || " "
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          label="Select Event Type"
                          error={
                            formik.touched.eventType &&
                            Boolean(formik.errors.eventType)
                          }
                          helperText={
                            formik.touched.eventType &&
                            formik.errors.eventType
                          }
                        />
                      )}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    disabled={isDisable}
                    type="submit"
                    className="btn btn-warning"
                  >
                    {SAVECHANGES}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div></div>
          <br></br>
          <br></br>
        </div>
        <div className="footer">
          <div className="row justify-content-between align-items-center">
            <div className="col"></div>
            <div className="col-auto">
              <div className="d-flex justify-content-end"></div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default HeaderNavbarHOC(EventAdd);
