import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Typography } from "@mui/material";

function Index({ activityValue }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={activityValue ?? 0} style={{ color: '#333399' }} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {activityValue ? `${Math.round(activityValue)}%` : "0%"}
        </Typography>
      </Box>
    </Box>
  );
}

Index.propTypes = {
  activityValue: PropTypes.number,
};

export default Index;
