import React from "react";
import { contact } from "../../constant/Contact";

function Report({ fullReport }) {
  return (
    <div
      className="modal fade"
      id="viewpage"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title" id="exampleModalLongTitle">
              {contact.REPORT}
            </h1>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {fullReport.map((item, index) => {
              return (
                <>
                  <h3 style={{ color: "red" }}>{contact.BASIC_INFO}</h3>
                  <div>
                    <table className="table table-align-middle">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>{contact.NAME}</h4>
                            {item.name ? item.name : "-"}
                          </td>
                          <td>
                            <h4>{contact.EMAIL}</h4>
                            {item.email ? item.email : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>{contact.PHONE}</h4>
                            {item.phone ? item.phone : "-"}
                          </td>
                          <td>
                            <h4>{contact.INTEREST}</h4>
                            {item.position || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <h4>Message</h4>
                            <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                              {item.message}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
          </div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </div>
  );
}

export default Report;
