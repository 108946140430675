export const emailmarketing = {
    RESEND_EMAIL: 'Resend Email',
    CSV_IMPORT_TABLE : 'CSV Import Table',
    POSITIONS : 'Positions',

    //ALERT MSG
    ALERT_MSG: '“Are you sure, you want to resend the email?”',

    //ACTION
    YES : 'Yes',
    NO  : 'No',
}

export const EMAIL_BARCHART = {
    TITLE: 'Emails overview',
    X_AXIS_LABEL: 'Date',
    Y_AXIS_LABEL: 'Count',
};