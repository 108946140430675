import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Config from "../../config";
import { trackEvent, EVENT_NAME } from "../../constant/MixpanelConstant";
import * as AxiosActions from "../../Actions/axiosActions";
import { FormikError } from "../../components/Common";
import {
  ADDEMAIL,
  COMPANYNAME,
  COUNTRY,
  EMAIL,
  EMAILTYPE,
  END_DATE_TIME,
  FREQUENCY,
  IS_ATLEAST_FIELD,
  IS_COUNTRY,
  IS_INVALID_EMAIL,
  IS_MOBILE_DIGIT,
  IS_SCHEDULE_DATE_TIME,
  IS_VERSION,
  NAME,
  PHONE,
  POSITIONS,
  SCHEDULE_DATE_TIME,
  SCHEDULE_LATER,
  SELECT_COUNTRY,
  SELECT_FREQUENCY,
  SELECT_VERSION,
  SELECTEMAILTYPE,
  SELECTSTATUS,
  SEND_NOW,
  STATUS,
  validEmailRegex,
  VERSION,
} from "../../constant/Label";
function Add({ countryList,TemplateList, versionList, setVersionList, setCountryList, renderdata }) {
  const [submitType, setSubmitType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const scheduleInputRef = useRef(null);
  const endInputRef = useRef(null);

  const currentDate = new Date();
  const currentMonth = Config.SALARY_MONTH[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear().toString();

  // Function to format date to ISO 8601 format with milliseconds
  const formatDateToISO = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      emailType: "",
      phone: "",
      status: Config.EMAIL_STATUS[3].value,
      positions: "",
      companyName: "",
      country: "",
      templateVersion: "",
      endDate: "",
      scheduledDateAndTime: "",
      frequency: Config.FREQUENCY_STATUS[2].value,
    },
    validate: (values) => {
      const errors = {};
      if (
        formik.submitCount >= 0 &&
        values.email === "" &&
        values.phone === ""
      ) {
        errors.email = IS_ATLEAST_FIELD;
        errors.phone = IS_ATLEAST_FIELD;
      } else if (values.email && !validEmailRegex.test(values.email)) {
        errors.email = IS_INVALID_EMAIL;
      } else if (values?.phone && values?.phone?.length !== 10) {
        errors.phone = IS_MOBILE_DIGIT;
      }
      if (
        formik.submitCount >= 0 &&
        values.country === "" &&
        submitType === SCHEDULE_LATER
      ) {
        errors.country = IS_COUNTRY;
      }
      if (
        formik.submitCount >= 0 &&
        values.templateVersion === "" &&
        submitType === SCHEDULE_LATER
      ) {
        errors.templateVersion = IS_VERSION;
      }
      if (
        formik.submitCount >= 0 &&
        values.scheduledDateAndTime === "" && values.endDate === "" &&
        submitType === SCHEDULE_LATER
      ) {
        errors.scheduledDateAndTime = IS_SCHEDULE_DATE_TIME;
      }
      return errors;
    },
    /**
     * @desc Function to handle form submission.
     * @param {Object} values - Form values.
     */
    onSubmit: async (values) => {
      trackEvent(EVENT_NAME.EMAIL_ADD, { data: "" });
      setIsLoading(true);
      try {
        const data = {
          name: values?.name || "",
          email: values?.email || "",
          emailType: values.emailType || "all",
          phone: values?.phone || "",
          status: values?.status || "all",
          positions: values?.positions || "",
          companyName: values?.companyName || "",
          month: currentMonth || "all",
          year: Number(currentYear) || "all",
          country: values.country || "",
          templateVersion: values.templateVersion || "all",
          frequency: values.frequency || "all",
          scheduledDateAndTime:
            submitType === SEND_NOW
              ? formatDateToISO(new Date().toISOString())
              : values.scheduledDateAndTime + ":00",
          sendNow: submitType === SEND_NOW ? 1 : 0,
          endDate:
            submitType === SEND_NOW
              ? formatDateToISO(new Date().toISOString())
              : values.endDate + ":00",
          sendNow: submitType === SEND_NOW ? 1 : 0,
        };
        AxiosActions.EmailMarketingAdd(data)
          .then((response) => {
            if (response.data.code === 200) {
              setIsLoading(false);
              formik.resetForm();
              renderdata();
              setCountryList([]);
              setVersionList([]);

              const modalElement = document.querySelector(".modal");
              if (modalElement) {
                modalElement.classList.remove("show");
                modalElement.setAttribute("aria-hidden", "true");
                modalElement.style.display = "none";
                const modalBackdrop = document.querySelector(".modal-backdrop");
                if (modalBackdrop) {
                  modalBackdrop.remove();
                }
              }
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log("Email marketing error", error);
          });
      } catch (error) {
        console.error("Email marketing error:", error);
        setIsLoading(false);
        toast.dismiss();
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <div
      className="modal fade"
      id="addExampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="exampleModalLongTitle">
              {ADDEMAIL}
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                formik.resetForm();
              }}
            ></button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body">
              <div className="modal-content">
                <div className="row mb-2">
                  <label
                    htmlFor="nameLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {NAME}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="nameLabel"
                      placeholder="Name"
                      aria-label="Name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />{" "}
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="emailLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {EMAIL}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="emailLabel"
                      placeholder="Email"
                      aria-label="Email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />{" "}
                    {formik.touched.email && formik.errors.email && (
                      <FormikError label={formik.errors.email} />
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="emailTypeLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {EMAILTYPE}
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="form-select"
                      autoComplete="off"
                      name="emailType"
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue("templateVersion", "");
                      }}
                      value={formik.values.emailType}
                    >
                      <option value={SELECTEMAILTYPE} selected>
                        {SELECTEMAILTYPE}
                      </option>

                      {TemplateList.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="versionLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {VERSION}
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="form-select"
                      autoComplete="off"
                      name="templateVersion"
                      onChange={formik.handleChange}
                      value={formik.values.templateVersion}
                    >
                      <option value={""} selected>
                        {SELECT_VERSION}
                      </option>
                      {versionList
                        ?.filter((item) => item.templateName === formik.values.emailType)
                        .map((item, index) => (
                          <option value={item.templateVersion} key={index}>
                            {item.templateVersion}
                          </option>
                        ))}
                    </select>
                    {formik.touched.templateVersion && formik.errors.templateVersion && (
                      <FormikError label={formik.errors.templateVersion} />
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="statusLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {PHONE}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      id="phone"
                      placeholder={PHONE}
                      aria-label="Name"
                      onChange={(e) => {
                        const numericValue = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 10);
                        formik.handleChange({
                          target: {
                            name: "phone",
                            value: numericValue,
                          },
                        });
                      }}
                      value={formik.values.phone}
                    />{" "}
                    {formik.touched.phone && formik.errors.phone && (
                      <FormikError label={formik.errors.phone} />
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="statusLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {STATUS}
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="form-select"
                      autoComplete="off"
                      name="status"
                      onChange={formik.handleChange}
                      value={formik.values.status}
                    >
                      <option value="Select status" selected>
                        {SELECTSTATUS}
                      </option>

                      {Config.EMAIL_STATUS.map((item, index) => (
                        <option value={item.value} key={index}>
                          {" "}
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="positionsLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {POSITIONS}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="positions"
                      id="positionsLabel"
                      placeholder="Positions"
                      aria-label="Positions"
                      onChange={formik.handleChange}
                      value={formik.values.positions}
                    />{" "}
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="companyNameLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {COMPANYNAME}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control"
                      name="companyName"
                      id="companyNameLabel"
                      placeholder="Company Name"
                      aria-label="companyName"
                      onChange={formik.handleChange}
                      value={formik.values.companyName}
                    />{" "}
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="countryLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {COUNTRY}
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="form-select"
                      autoComplete="off"
                      name="country"
                      onChange={formik.handleChange}
                      value={formik.values.country}
                    >
                      <option value={""} selected>
                        {SELECT_COUNTRY}
                      </option>
                      {countryList &&
                        countryList?.map((item, index) => (
                          <option value={item.timeZone} key={index}>
                            {" "}
                            {item.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.country && formik.errors.country && (
                      <FormikError label={formik.errors.country} />
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <label
                    htmlFor="frequencyLabel"
                    className="col-form-label form-label pb-1"
                    style={{ fontSize: 17 }}
                  >
                    {FREQUENCY}
                  </label>
                  <div className="col-sm-12">
                    <select
                      className="form-select"
                      autoComplete="off"
                      name="frequency"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value === "ONE_TIME") {
                          formik.setFieldValue("scheduledDateAndTime", "");
                          formik.setFieldValue("endDate", "");
                        }
                      }}
                      value={formik.values.frequency}
                    >
                      <option value={""} selected>
                        {SELECT_FREQUENCY}
                      </option>
                      {Config.FREQUENCY_STATUS.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.frequency && formik.errors.frequency && (
                      <FormikError label={formik.errors.frequency} />
                    )}
                  </div>
                </div>
                {formik.values.frequency !== "ONE_TIME" && (
                  <div className="row mb-2">
                    <label
                      htmlFor="scheduleLaterLabel"
                      className="col-form-label form-label pb-1"
                      style={{ fontSize: 17 }}
                    >
                      {SCHEDULE_DATE_TIME}
                    </label>
                    <div className="col-sm-12">
                      <input
                        ref={scheduleInputRef}
                        className="form-control valid cursor-pointer"
                        name="schedule"
                        id="schedule"
                        type="datetime-local"
                        placeholder={""}
                        onChange={(e) => {
                          formik.setFieldValue("scheduledDateAndTime", e.target.value);
                        }}
                        value={formik.values.scheduledDateAndTime}
                        onClick={() =>
                          scheduleInputRef.current &&
                          scheduleInputRef.current.showPicker()
                        }
                      />
                      {formik.touched.scheduledDateAndTime && formik.errors.scheduledDateAndTime && (
                        <FormikError label={formik.errors.scheduledDateAndTime} />
                      )}
                    </div>
                  </div>
                )}
                {formik.values.frequency !== "ONE_TIME" && (
                  <div className="row mb-2">
                    <label
                      htmlFor="EndLaterLabel"
                      className="col-form-label form-label pb-1"
                      style={{ fontSize: 17 }}
                    >
                      {END_DATE_TIME}
                    </label>
                    <div className="col-sm-12">
                      <input
                        ref={endInputRef}
                        className="form-control valid cursor-pointer"
                        name="endDate"
                        id="endDate"
                        type="datetime-local"
                        placeholder={""}
                        onChange={formik.handleChange}
                        value={formik.values.endDate}
                        onClick={() =>
                          endInputRef.current &&
                          endInputRef.current.showPicker()
                        }
                      />
                      {formik.touched.endDate && formik.errors.endDate && (
                        <FormikError label={formik.errors.endDate} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="col-lg-12 d-flex justify-content-end flex-wrap align-items-center">
                <div className="form-group mt-2">
                  <button
                    type="submit"
                    className="btn btn-info me-3"
                    label={SEND_NOW}
                    disabled={submitType === SEND_NOW ? isLoading : false}
                    onClick={() => {
                      setSubmitType(SEND_NOW);
                    }}
                  >
                    {SEND_NOW}
                  </button>
                </div>
                {formik.values.frequency !== "ONE_TIME" && (
                  <div className="form-group mt-2">
                    <button
                      type="submit"
                      className="btn btn-warning"
                      label={SCHEDULE_LATER}
                      disabled={submitType === SCHEDULE_LATER ? isLoading : false}
                      onClick={() => {
                        setSubmitType(SCHEDULE_LATER);
                      }}
                    >
                      {SCHEDULE_LATER}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Add;
